import { isNil } from 'lodash';
import filter from 'lodash/filter';
import flatMap from 'lodash/flatMap';
import groupBy from 'lodash/groupBy';
import isEmpty from 'lodash/isEmpty';
import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';
import orderBy from 'lodash/orderBy';
import some from 'lodash/some';
import uniq from 'lodash/uniq';

import { selectFilters } from '@/ducks/filters/selectors';
import { getFiltersOptions } from '@/ducks/filtersOptions';
import { isMultipleVoyageFilterActive, selectPackages } from '@/ducks/pages/chooseVoyage/selectorDetails';
import { getAmountPerItem, getAmountPerVoyage } from '@/helpers/data/mappers/Summary';
import { checkForValueType, getVoyageId } from '@/helpers/util';

import groupPackagesWithSameName from './groupPackagesWithSameNames';
import { sortSailings } from './utility';

export const packageFilterSteps = {
  PACKAGES: 'packages',
  REGIONS: 'regions',
};

const getRoot = (state) => state.chooseVoyageNew;

const getMainPackages = (state) => state.chooseVoyageNew.mainPackages;

export const getExternalId = (state) => state?.accounts?.profile?.data?.externalRefId || '';

export const getPackagesForFilteredSailings = (filteredSailings, packages) => {
  const groupedSailings = [];
  groupedSailings.push(mapValues(groupBy(uniq(filteredSailings, 'id'), 'packageCode'), (clist) => clist));
  return packages.reduce((acc, pckg) => {
    if (pckg.packageCode in groupedSailings[0]) {
      acc.push({ ...pckg, sailingList: groupedSailings[0][pckg.packageCode] });
    }
    return acc;
  }, []);
};

const filterPackages = (selectedPackages, packages) =>
  selectedPackages.filter((pckg) => packages.includes(pckg.packageCode));

const filterPorts = (packages, ports) =>
  packages.filter((pckg) =>
    pckg?.sailingList?.some((sailing) =>
      sailing?.ports?.some((currentPort) => ports.some((port) => port === currentPort.code)),
    ),
  );

const filterDurations = (packages, durations) => {
  if (!packages.length || !durations.length) {
    return packages;
  }
  return packages.filter((s) =>
    durations.some((duration) => {
      if (duration.max && duration.min) {
        return s.duration >= duration.min && s.duration <= duration.max;
      }
      return s.duration >= duration.min;
    }),
  );
};

const filterWeekend = (packages, weekend) => {
  const sailings = getSailingsFromPackages(packages);
  if (!checkForValueType(sailings)) {
    return packages;
  }
  let updatedSailings = [];
  if (weekend) {
    updatedSailings = filter(sailings, 'weekend');
  } else {
    updatedSailings = sailings;
  }
  return getPackagesForFilteredSailings(updatedSailings, packages);
};

export const filterPriceRange = (state, packages) => {
  const filters = selectFilters(state);
  const { cabins, maxPrice, minPrice, priceType, sailors } = filters;
  const minPriceNum = parseInt(minPrice, 10);
  const maxPriceNum = parseInt(maxPrice, 10);
  if (minPriceNum === null && maxPriceNum === null) {
    return packages;
  }
  const sailings = getSailingsFromPackages(packages);
  const filteredSailings = sailings.filter((voyage) => {
    let price = getAmountPerItem(voyage.startingPrice, { cabins, priceType, sailors });
    if (priceType === 'SailorPerNight') {
      price = getAmountPerVoyage(voyage.startingPrice, voyage.duration, { sailors });
    }

    if (Number.isNaN(minPriceNum) && Number.isNaN(maxPriceNum)) {
      return true;
    }
    if (Number.isNaN(minPriceNum)) {
      return price <= maxPriceNum;
    }
    if (Number.isNaN(maxPriceNum)) {
      return price >= minPriceNum;
    }
    return price >= minPriceNum && price <= maxPriceNum;
  });
  let sailingList = [];
  if (priceType === 'SailorPerNight') {
    sailingList = orderBy(
      filteredSailings,
      (sail) => getAmountPerVoyage(sail.startingPrice, sail.duration, { sailors }),
      'asc',
    );
  } else {
    sailingList = orderBy(
      filteredSailings,
      (itinerary) => getAmountPerItem(itinerary.startingPrice, { cabins, priceType, sailors }),
      'asc',
    );
  }
  return getPackagesForFilteredSailings(sailingList, packages);
};

export const getSailingsFromPackages = (packages) => {
  if (!checkForValueType(packages)) {
    return [];
  }
  const sailings = [];
  packages.forEach((pckg) => {
    pckg.sailingList.forEach((sailing) => {
      sailings.push(sailing);
    });
  });
  return sailings;
};

export const filterShips = (packages, shipList) => {
  if (isEmpty(shipList) || shipList === undefined) {
    return packages;
  }
  const sailings = getSailingsFromPackages(packages);
  if (!checkForValueType(sailings)) {
    return packages;
  }

  const filteredSailings = sailings.filter((s) => shipList.some((ship) => s.ship.code === ship?.id));
  return getPackagesForFilteredSailings(filteredSailings, packages);
};

export const filterDeparturePorts = (packages, homePorts) => {
  if (isEmpty(homePorts) || homePorts === undefined) {
    return packages;
  }
  const finalpackages = packages?.filter((s) => homePorts?.some((port) => s?.departurePortCode === port?.code));
  return finalpackages;
};

const filterNodes = (packages, nodes) => {
  if (!nodes.length) {
    return packages;
  }
  const sailings = getSailingsFromPackages(packages);
  if (!checkForValueType(sailings)) {
    return packages;
  }
  const filteredSailings = sailings.filter((sailing) =>
    some(sailing.classificationCodes, (code) => nodes.includes(code)),
  );
  return getPackagesForFilteredSailings(filteredSailings, packages);
};

const filterAccessible = (packages, accessible) => {
  const sailings = getSailingsFromPackages(packages);
  if (!checkForValueType(sailings)) {
    return packages;
  }
  let updatedSailings = [];
  if (accessible) {
    updatedSailings = filter(sailings, 'isAccessible');
  } else {
    updatedSailings = sailings;
  }
  return getPackagesForFilteredSailings(updatedSailings, packages);
};

export const filterByPromoVoyages = (packages) => {
  if (isEmpty(packages)) {
    return packages;
  }
  const voyages = getVoyageId();

  if (isNil(voyages)) {
    return packages;
  }

  const filteredPackages = packages
    .reduce((acc, pack) => {
      const sailings = pack.sailingList.filter((sailing) => voyages.some((voyageId) => voyageId === sailing?.id));
      acc.push({
        ...pack,
        sailingList: sailings,
      });
      return acc;
    }, [])
    .filter((pckg) => !isEmpty(pckg.sailingList));

  return filteredPackages;
};

export const getFilteredPackages = (state, filterType) => {
  const filters = selectFilters(state);
  const { fromDate, priceType, sailors, sortType } = filters;
  const isMultipleVoyageFilter = isMultipleVoyageFilterActive(state);
  let result = selectPackages(state) || {};
  if (filters?.packages?.length > 0 || filters?.ports?.length > 0) {
    result = uniq([...filterPackages(result, filters?.packages), ...filterPorts(result, filters?.ports)]);
  }
  if (filterType === packageFilterSteps.REGIONS) {
    return result;
  }

  result = filterNodes(result, filters?.nodes);
  if (filterType === packageFilterSteps.PACKAGES) {
    return result;
  }
  result = filterDurations(result, filters?.durations);
  result = filterWeekend(result, filters?.weekend);
  result = filterAccessible(result, filters?.accessible);
  result = filterPriceRange(state, result);
  result = filterShips(result, filters?.shipList);
  result = filterDeparturePorts(result, filters?.homePorts);
  result = groupPackagesWithSameName(result);
  if (isMultipleVoyageFilter) {
    result = filterByPromoVoyages(result);
  }
  return sortSailings(result, sortType, fromDate, priceType, sailors);
};

export const getFilteredPackagesDownToRegions = (state) => getFilteredPackages(state, packageFilterSteps.REGIONS);

export const getFilteredPackagesDownToPackages = (state) => getFilteredPackages(state, packageFilterSteps.PACKAGES);

// Get sailings search, only the UI-relevant data
export const getPackageSearch = (state) => {
  const root = getRoot(state);
  return {
    error: root.error,
    loading: root.loading,
    packages: root.mainPackages && root.mainPackages.packages,
  };
};

// eslint-disable-next-line consistent-return
export const getPresentDurationsForPackages = (state) => {
  const packages = getFilteredPackagesDownToPackages(state);
  const { durationOptions } = getFiltersOptions(state);
  if (durationOptions === undefined) {
    return [];
  }
  try {
    return durationOptions?.map((duration) => ({
      ...duration,
      isDisabled: !packages.some((s) => {
        if (duration.max && duration.min) {
          return s.duration >= duration.min && s.duration <= duration.max;
        }
        return s.duration >= duration.min;
      }),
    }));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('error while reading duration options key::: ', error);
    return [];
  }
};

export const isWeekendAvailableForPackages = (state) => {
  const packages = getFilteredPackages(state);
  const sailings = getSailingsFromPackages(packages);

  const newSailings = sailings.some((obj) => obj.weekend);
  return getPackagesForFilteredSailings(newSailings, packages);
};

// Get object of shape <package-id> -> { sailing price, duration, package info }
export const getPresentPackages = (state) => {
  const { packages } = getMainPackages(state);
  return keyBy(packages, 'packageCode');
};

// Get object of shape <port-id> => { port info }
export const getPresentPorts = (state) => {
  const { sailings } = getMainPackages(state);
  const allPorts = uniq(
    flatMap(sailings, (sail) => sail.ports),
    'code',
  );
  // Add id equal to 'code', return byKey object
  return keyBy(
    allPorts.map((port) => ({ ...port, id: port.code })),
    'code',
  );
};

export const getSelectedPackageNames = (packageCodes, packages) => {
  const { selectedPackagesNames } = packageCodes.reduce(
    (acc, packgId) => {
      const packageName = !isEmpty(packages) && packages.find((packageCode) => packageCode.id === packgId);
      if (packageName && !acc.selectedPackagesNames.includes(packageName.name)) {
        return { selectedPackagesNames: [...acc.selectedPackagesNames, packageName.name] };
      }
      return acc;
    },
    { selectedPackagesNames: [] },
  );

  return selectedPackagesNames;
};

export const getSelectedRegionLabel = (itineraryFilters) => {
  const regionsSelected = [...itineraryFilters]
    .sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    })
    .filter((data) => {
      if (!checkForValueType(data)) {
        return false;
      }
      const { appliedPackages, appliedPorts } = data;
      return appliedPackages.length > 0 || appliedPorts.length > 0;
    });
  if (regionsSelected.length === 4) {
    return 'default';
  }
  if (regionsSelected.length > 0) {
    const label = regionsSelected.slice(0, 3).map((data) => data?.name);
    return label.join(' & ').replace(/,(?!.*,)/g, ' &');
  }
  return 'default';
};

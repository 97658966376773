export const SET_FILTER_LABEL = 'SET_FILTER_LABEL';
export const RESET_FILTER_LABEL = 'RESET_FILTER_LABEL';

export const defaultState = {
  accessible: '',
  cabinType: '',
  duration: '',
  port: '',
  price: '',
  ship: '',
  travelParty: '',
  weekend: '',
};

export default function appliedfilterLabelsReducer(state = defaultState, action) {
  switch (action.type) {
    case SET_FILTER_LABEL:
      return { ...state, ...action.payload };
    case RESET_FILTER_LABEL:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

/**
 * TODO: Add any cookies applied and used by app to this object
 * Cookie classifications will likely be added later
 */
const applicationCookies = {
  pastSearches: {
    name: 'pastSearches',
  },
};

export default applicationCookies;

import isEmpty from 'lodash/isEmpty';
import mapValues from 'lodash/mapValues';

import defaultMessagesFile from '@/constants/defaultUIResources.json';
import parseCmsTemplate from '@/ducks/common/resources/helpers/parseCmsTemplate';
import { selectResources } from '@/ducks/common/selectors';
import createMemoSelector from '@/helpers/createMemoSelector';

import type {
  ResourcesSlice,
  TUIResourceId,
  TUIResourceImage,
  TUIResourceNode,
  TUIResourceStorable,
  TUIResourceValueMap,
} from './types';

import safeJoin from './helpers/safeJoin';
import { ErrorPolicy } from './types';

const defaultMessages = defaultMessagesFile satisfies Record<string, { alt?: string; src: string } | string>;

export const combineUIResourcePool = (
  resources: ResourcesSlice,
  resourceIds: Record<string, TUIResourceId>,
): Record<string, TUIResourceStorable> =>
  mapValues(resourceIds, (id: TUIResourceId): TUIResourceStorable => resources?.[id] ?? defaultMessages[id]);

export const combineUIResource = <R extends TUIResourceImage | TUIResourceNode | string = string>(
  resources: ResourcesSlice,
  resourceId: TUIResourceId,
  values?: TUIResourceValueMap,
): R => {
  const defaultResource = defaultMessages[resourceId];
  const resource: TUIResourceStorable = resources?.[resourceId] ?? defaultResource;
  if (resource && typeof resource === 'string' && values && !isEmpty(values)) {
    let parts;
    try {
      parts = parseCmsTemplate(resource, values, ErrorPolicy.THROW);
    } catch (err) {
      parts =
        defaultResource && typeof defaultResource === 'string'
          ? parseCmsTemplate(defaultResource, values, ErrorPolicy.LEAVE_AS_IS)
          : [''];
    }
    return safeJoin(parts, resourceId) as R;
  }
  return resource as R;
};

// When using single value caching, need to memoize a more particular selector, not a general one. Therefore:

export const makeUIResourcePoolSelector = (resourceIds: Record<string, TUIResourceId>) =>
  createMemoSelector(
    selectResources,
    (resources): Record<string, TUIResourceStorable> => combineUIResourcePool(resources, resourceIds),
  );

export const makeUIResourceSelector = <R extends TUIResourceImage | TUIResourceNode | string = string>(
  resourceId: TUIResourceId,
  values?: TUIResourceValueMap,
) => createMemoSelector(selectResources, (resources) => combineUIResource<R>(resources, resourceId, values));

/**
 * Query string parser and builder for filters
 */
import { isAfter, isBefore, isEqual } from 'date-fns';
import find from 'lodash/find';
import flatMap from 'lodash/flatMap';
import get from 'lodash/get';
import identity from 'lodash/identity';
import invert from 'lodash/invert';
import invoke from 'lodash/invoke';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import mapValues from 'lodash/mapValues';
import omit from 'lodash/omit';
import pickBy from 'lodash/pickBy';
import some from 'lodash/some';
import uniq from 'lodash/uniq';

import { selectAccessKeyPromoCode } from '@/ducks/accessKeys';
import { selectLookup } from '@/ducks/common/selectors';
import {
  getFilterAgency,
  getFilterAgencyCurrencyCode,
  getFilterAgencyIsAvailable,
  getFilterAgent,
} from '@/ducks/fm/getters';
import { getSearchParam, getSearchParams } from '@/ducks/routes/history';
import { format, parse } from '@/helpers/util/dateUtil';
import { ensureArray, getMnvvReservation } from '@/helpers/util/misc';
import { getSessionStorageValue } from '@/helpers/util/storage';

import { selectFilters } from '../selectors';

// Names mapping from internal filters name => query string name
const namesMapping = {
  accessible: 'isCabinAccessible',
  agencyId: 'agencyId',
  agentId: 'agentId',
  bookingChannel: 'bookingChannel',
  cabinType: 'metaType',
  cabins: 'cabins',
  classificationGroups: 'selectedClassificationGroup',
  currencyCode: 'currencyCode',
  durations: 'duration',
  fromDate: 'fromDate',
  homePorts: 'selectedHomePorts',
  maxPrice: 'maxPrice',
  minPrice: 'minPrice',
  // We use 'nodes' as it is shorter and easier to perceive
  nodes: 'selectedClassification', // classification nodes
  packages: 'selectedPackages',
  // there is an inconsistency between query params and API names:
  // 'classification group' and 'classification' in query params corresponds to
  // 'classification' and 'classification node' in API response respectively
  ports: 'selectedPorts',
  priceType: 'priceType',
  promotionalVoyages: 'voyageId',
  sailors: 'sailors',
  selectedRegionsIds: 'selectedRegionsIds',
  shipList: 'selectedShipList',
  sortType: 'sortType',
  toDate: 'toDate',
  weekend: 'weekend',
};

// Parsing / validation of filters fields.
// Used for parsing query string and for validating applyFilters parameters
// @param options: filtersOptions object with boundaries and allowed values for filters.
export const getParsers = (options) => ({
  accessible(val) {
    return this.bool(val);
  },
  agencyId(agencyId) {
    return !agencyId ? undefined : agencyId.toUpperCase();
  },
  agentId(agentId) {
    return !agentId ? undefined : agentId.toUpperCase();
  },

  bool(val) {
    if (typeof val === 'boolean') {
      return val;
    }
    return ['false', 'true'].includes(val) ? val === 'true' : undefined;
  },

  cabinType(cabinType) {
    if (cabinType === null) {
      return null;
    }
    return cabinType && some(options.cabinTypes, (t) => t.code === cabinType) ? cabinType : undefined;
  },

  cabins(cabins) {
    const count = Number.parseInt(cabins, 10);
    return count && count >= options.minCabinCount && count <= options.maxCabinCount ? count : undefined;
  },

  checkMNVVSailorValue(value) {
    const sailors = parseInt(value, 10);
    const { isMNVV } = getMnvvReservation();
    if (sailors === 1 && isMNVV) {
      return options.defaultFilters.sailors;
    }
    return sailors;
  },

  checkPriceType(priceType) {
    if (['perSailor', 'perCabin', 'SailorPerNight'].indexOf(priceType) > -1) {
      return priceType;
    }
    return options.defaultFilters.priceType;
  },

  // adds all nodes from particular classification to filters.
  classifications(qClassifications, qNodes) {
    const nodes = this.nodes(qNodes) || [];
    const classifications = ensureArray(qClassifications) || [];
    const classificationNodes = flatMap(classifications, (id) => {
      const classification = find(options.classifications, { id });
      if (!classification) {
        return [];
      }
      return map(classification.nodes, 'id');
    });
    return uniq([...nodes, ...classificationNodes]);
  },

  currencyCode(currency) {
    if (currency) {
      const selectedCurrency = options?.allowedCurrencies?.filter(
        (currencyItem) => currencyItem.toLowerCase() === getSearchParam('currencyCode')?.toLowerCase(),
      );
      return selectedCurrency && selectedCurrency.length > 0 ? selectedCurrency[0] : undefined;
    }
    return undefined;
  },

  date(date, options) {
    const d = parse(date);
    const minDate = parse(options.minDate);
    const maxDate = parse(options.maxDate);
    if (!date || isNaN(d)) {
      return undefined;
    }
    if (isBefore(d, minDate)) {
      return new Date(minDate).getFullYear() <= '1970' ? getSessionStorageValue('defaultStartDate') : minDate;
    }
    if (isAfter(d, maxDate)) {
      return new Date(maxDate).getFullYear() <= '1970' ? getSessionStorageValue('defaultEndDate') : maxDate;
    }
    return d;
  },

  dates(fromDate, toDate, maxDate, actualMaxDate) {
    const urlParams = new URLSearchParams(window.location.search);
    const isMNVV = !!(urlParams.get('reservationNumber') && urlParams.get('channelID'));
    const defaultFromDate = parse(getSessionStorageValue('defaultStartDate') || options?.defaultStartDate);
    const defaultToDate = parse(getSessionStorageValue('defaultEndDate') || options?.defaultEndDate);
    const from = this.date(fromDate, options) || defaultFromDate;
    const to = this.date(toDate, options) || defaultToDate;

    let mnvvmaxDate = maxDate;

    if (isMNVV && !isEmpty(actualMaxDate) && !isEqual(parse(maxDate), parse(actualMaxDate))) {
      mnvvmaxDate = actualMaxDate;
    }
    mnvvmaxDate = this.date(mnvvmaxDate, options);

    if (from && to && (isBefore(from, to) || isEqual(from, to))) {
      if (isMNVV && mnvvmaxDate instanceof Date && !Number.isNaN(mnvvmaxDate.getTime())) {
        /* if (mnvvmaxDate && to && (isBefore(to, mnvvmaxDate) || isEqual(to, mnvvmaxDate))) {
          return { fromDate: format(from), maxDate: format(mnvvmaxDate), toDate: format(to) };
        } */
        const endDate = isBefore(to, mnvvmaxDate) ? to : mnvvmaxDate;
        return { fromDate: format(from), maxDate: format(mnvvmaxDate), toDate: format(endDate) };
      }
      return { fromDate: format(from), toDate: format(to) };
    }
    if (isMNVV && mnvvmaxDate instanceof Date && !Number.isNaN(mnvvmaxDate.getTime())) {
      if (mnvvmaxDate && to && (isBefore(to, mnvvmaxDate) || isEqual(to, mnvvmaxDate))) {
        return { fromDate: format(defaultFromDate), maxDate: format(mnvvmaxDate), toDate: format(to) };
      }
      return { fromDate: format(defaultFromDate), maxDate: format(mnvvmaxDate), toDate: format(mnvvmaxDate) };
    }

    return { fromDate: format(defaultFromDate), toDate: format(defaultToDate) };
  },

  durations(durations) {
    if (durations) {
      const durationObjects = options?.durationOptions;
      return ensureArray(durations).reduce((acc, durationDays) => {
        const durationObject = durationObjects?.find((duration) => duration.min === Number.parseInt(durationDays, 10));
        if (durationObject) {
          acc.push(durationObject);
        }
        return acc;
      }, []);
    }
    return ensureArray(durations);
  },

  getHomePorts(selectedHomePorts, homePortsMeta) {
    const homePortData = homePortsMeta || options.homePorts;
    const homePortsCode = this.packages(selectedHomePorts) || [];
    const homePorts = flatMap(homePortsCode, (code) => find(homePortData, { code }));
    return homePorts;
  },

  getSelectedRegions(cmsSelctdRegions, qRegions, qPackages, qPorts) {
    const packages = this.packages(qPackages) || [];
    const regions = ensureArray(qRegions) || [];
    const cmsRegionSelection = ensureArray(cmsSelctdRegions) || [];
    const ports = this.ports(qPorts) || [];
    const obj = {
      name: '',
      packages: [],
      ports: [],
      regionId: '',
    };

    const selectedRegions = flatMap(options.regions, (region) => {
      if (cmsRegionSelection.length > 0) {
        // ::::::::>>> filter selection from super button, CMS query param
        // adds all packages from particular region.

        // eslint-disable-next-line consistent-return
        const selectedRegion = cmsRegionSelection.map((cmsRegion) => {
          if (cmsRegion === region.id || region?.id.includes(cmsRegion)) {
            return {
              ...obj,
              name: region.id,
              packages: map(region.packages, 'id'),
              regionId: region.id,
            };
          }
          return undefined;
        });
        const selectedRegionFiler = selectedRegion.filter((data) => data !== undefined)[0];
        if (selectedRegionFiler) {
          return selectedRegionFiler;
        }
      } else if (cmsRegionSelection.length === 0 && regions.length > 0) {
        // ::::::::>>> custom selection by the user in destination filter POP UP
        // adds specific region selected packages or ports

        // eslint-disable-next-line consistent-return
        const regionPackages = flatMap(regions, (id) => {
          const findRegion = find(options.regions, { id });
          if (findRegion && findRegion.id === region.id) {
            const selectedPackages = [];
            const selectedPorts = [];
            findRegion.packages.forEach((data) => {
              if (packages.includes(data.id)) {
                selectedPackages.push(data.id);
              }
            });
            findRegion.ports.forEach((data) => {
              const portId = data.id || data.code;
              if (ports.includes(portId)) {
                selectedPorts.push(portId);
              }
            });

            return {
              ...obj,
              name: region.id,
              packages: selectedPackages,
              ports: selectedPorts,
              regionId: region.id,
            };
          }
        });
        const filteredRegion = regionPackages.filter((data) => data !== undefined);
        if (filteredRegion && filteredRegion.length > 0) {
          return filteredRegion[0];
        }
      } else if (cmsRegionSelection.length === 0 && regions.length === 0 && (packages.length > 0 || ports.length > 0)) {
        // on packages and ports selection coming from the url.
        // adds packages or ports to the available region.

        const selectedPackages = [];
        const selectedPorts = [];
        region.packages.forEach((data) => {
          if (packages.includes(data.id)) {
            selectedPackages.push(data.id);
          }
        });
        region.ports.forEach((data) => {
          const portId = data.id || data.code;
          if (ports.includes(portId)) {
            selectedPorts.push(portId);
          }
        });
        return {
          ...obj,
          name: region.id,
          packages: [...selectedPackages],
          ports: [...selectedPorts],
          regionId: region.id,
        };
      }
      return {
        ...obj,
        name: region.id,
        regionId: region.id,
      };
    });

    return selectedRegions;
  },

  // Special parser for read-only "selectedRegion" CMS query param
  getShipList(selectedShipList, ships) {
    const metaShipList = ships || options.shipList;
    const shipListId = this.packages(selectedShipList) || [];
    const shipList = flatMap(shipListId, (id) => find(metaShipList, { id }));
    return shipList;
  },

  // Retaining the filters

  nodes(val) {
    return ensureArray(val);
  },

  // Special parser for read-only "selectedClassificationGroup" CMS query param
  packages(val) {
    return ensureArray(val);
  },

  ports(val) {
    return ensureArray(val);
  },

  promotionalVoyages(voyageId) {
    if (voyageId === null) {
      return null;
    }
    return ensureArray(voyageId);
  },

  // adds all packages from particular region to filters.
  regions(qRegions, qPackages) {
    const packages = this.packages(qPackages) || [];
    const regions = ensureArray(qRegions) || [];
    const regionPackages = flatMap(regions, (id) => {
      const region = options?.regions?.find((rgn) => rgn.id === id || rgn?.id.includes(id));
      if (!region) {
        return [];
      }
      return map(region.packages, 'id');
    });
    return uniq([...packages, ...regionPackages]);
  },
  sailors(sailors) {
    const count = Number.parseInt(sailors, 10);
    return count && count >= options.minCabinOccupancy && count <= options.maxCabinCount * options.maxCabinOccupancy
      ? count
      : undefined;
  },
  selectedRegionsIds(val) {
    return ensureArray(val);
  },
  weekend(val) {
    return this.bool(val);
  },
});

// Parse and validate url query string; return only existing and valid values
export const parseQuery = (options, mnvvData, lookupMetaData) => {
  const { currenciesFromLookup, homePorts, ships } = lookupMetaData;
  const parsed = getSearchParams();
  const parsers = getParsers(options, currenciesFromLookup);
  const actualMaxDate = get(mnvvData, 'expirationDate.urlDate', '');
  const updatedNamesMaping = namesMapping;
  if (parsed.maxDate) {
    updatedNamesMaping.maxDate = 'maxDate';
  }

  // For each filters key call corresponding parser with query param
  const filters = {
    ...mapValues(updatedNamesMaping, (qKey, fKey) => invoke(parsers, fKey, parsed[qKey])),
    homePorts: parsers.getHomePorts(parsed.selectedHomePorts, homePorts),
    maxPrice: parsed.maxPrice,
    minPrice: parsed.minPrice,
    nodes: parsers.classifications(parsed.selectedClassificationGroup, parsed.selectedClassification),
    // Special cases for region packages, classification groups and dates
    packages: parsers.regions(parsed.selectedRegion, parsed.selectedPackages),
    priceType: parsers.checkPriceType(parsed.priceType),
    sailors: parsers.checkMNVVSailorValue(parsed.sailors),
    selectedRegions: parsers.getSelectedRegions(
      parsed.selectedRegion,
      parsed.selectedRegionsIds,
      parsed.selectedPackages,
      parsed.selectedPorts,
    ),
    shipList: parsers.getShipList(parsed.selectedShipList, ships),
    sortType: parsed.sortType,
    ...parsers.dates(parsed.fromDate, parsed.toDate, parsed.maxDate, actualMaxDate),
  };

  // Filter out wrong / absent values
  return pickBy(filters, identity);
};

export const getFiltersSearchQuery = (state, extraParams, paramsToSkip = {}) =>
  buildQuery(state, extraParams, paramsToSkip);

// Build url query string from filters data.
export const buildQuery = (state, extraParams, paramsToSkip = {}) => {
  const { currencySkip = false } = paramsToSkip;
  const parsed = getSearchParams();
  const filters = selectFilters(state);
  const accessKey = { accessKey: selectAccessKeyPromoCode(state) || null };
  const mappedFilters = mapValues(invert(namesMapping), (fKey) => {
    if (fKey === 'durations') {
      return filters[fKey].map((duration) => duration?.min);
    }
    if (fKey === 'shipList') {
      return filters[fKey].map((ship) => ship?.id);
    }
    if (fKey === 'homePorts') {
      return filters[fKey].map((hmePort) => hmePort?.code);
    }

    if (fKey === 'selectedRegionsIds') {
      return ensureArray(filters[fKey]).map((region) => region?.regionId || region);
    }
    if (fKey === 'currencyCode' && !currencySkip) {
      const defaultCurrencyCode = selectLookup(state).defaultCurrencyCode ?? 'USD';
      const selectedCurrency = (state?.filtersOptions?.allowedCurrencies || []).find(
        (currencyItem) => currencyItem.toLowerCase() === parsed?.currencyCode?.toLowerCase(),
      );

      return selectedCurrency || defaultCurrencyCode;
    }

    if (fKey === 'promotionalVoyages') {
      return isEmpty(filters[fKey]) ? parsed.voyageId : filters[fKey];
    }

    return filters[fKey];
  });

  return pickBy(
    {
      // remove read-only CMS query params
      ...omit(parsed, ['selectedRegion', 'selectedClassificationGroup']),
      ...mappedFilters,
      ...accessKey,
      ...extraParams,
    },
    identity,
  );
};

export const buildAgencyQuery = (params) => {
  if (getFilterAgencyIsAvailable()) {
    const { agencyId } = getFilterAgency();
    const { agentId } = getFilterAgent();
    const agentcurrency = getFilterAgencyCurrencyCode();
    return `${params}&agencyId=${agencyId}&agentId=${agentId}&currencyCode=${agentcurrency}`;
  }
  return params;
};

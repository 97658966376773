import { VWOFlag } from '@/types/vwo';

const SUITABLE_CABIN_TYPES = ['TZ', 'IZ'] as const;

export default SUITABLE_CABIN_TYPES;

// Please use VWOFlag enum instead VWO_FLAGS

// use this object to test VWO features locally
export const VWO_DEFAULTS: Record<VWOFlag, boolean> = {
  [VWOFlag.ALT_24HR_BREATHER]: true,
  [VWOFlag.ALT_CHOOSE_CABIN_SORT]: true,
  [VWOFlag.ALT_SAILOR_DETAILS_FORM_UX]: true,
  [VWOFlag.BLACK_FRIDAY]: false,
  [VWOFlag.BOOK_NOW]: false,
  [VWOFlag.MNVV_OFFER_REVISION]: false,
  [VWOFlag.MOBILE_OPTIN]: false,
  [VWOFlag.SIZZLE_REELS]: false,
  [VWOFlag.SUMMARY_V2]: true,
  [VWOFlag.TAXES_AND_FEES]: true,
  [VWOFlag.VPS_PAYMENT]: true,
  [VWOFlag.WHATS_INCLUDED]: true,
} as const;

export const BOOK_NOW_CALL_FAILURE_STATUS_CODE = '0111322';
export const BOOK_NOW_CALL_FAILURE_FOR_INVALID_EMAIL = '0113323';

export const SEAWEAR_ERROR_SAILOR_NOT_PERMITTED = '013323';

export const SESSION_SKIPPED_KEYS = ['consoleErrCount', 'qm_last_page', 'qm_last_period', 'up_o'];

export const BookingChannelTypes = {
  fmLink: 'FMLINK',
  openTravel: 'OPENTRAVEL',
};

import capitalize from 'lodash/capitalize';

import { selectFilters } from '@/ducks/filters/selectors';
import { getFilterAgencyIsAvailable } from '@/ducks/fm/getters';
import { getSearchParams } from '@/ducks/routes/history';
import { getMnvvReservation } from '@/helpers/util/misc';

export const selectWaypoints = ({ sailing }) => {
  const portIds = getUniqPortCodeList(sailing?.ports);
  const cityName = sailing?.ports?.[0]?.name || '';

  return { cityName, portIds };
};

export const getUniqPortCodeList = (ports) => {
  if (!ports?.length) {
    return null;
  }
  const flatPortList = ports.map((port) => port?.code).filter(Boolean);
  return [...new Set(flatPortList)];
};

export const getDurationLabel = (duration) => {
  if (duration.min && duration.max) {
    return duration.min === duration.max ? duration.min : `${duration.min}-${duration.max}`;
  }
  return `${duration.min}+`;
};

export const getAllFilters = (state) => {
  const fm = getFilterAgencyIsAvailable();

  const {
    accessible: accessibleRedux,
    cabinType: cabinTypeRedux,
    currencyCode: currencyCodeRedux,
    durations: durationRedux,
    fromDate: fromDateRedux,
    maxPrice: maxPriceRedux,
    minPrice: minPriceRedux,
    sailors: sailorsRedux,
    shipList: shipCodeRedux,
    sortType,
    toDate: toDateRedux,
    weekend,
  } = selectFilters(state) || {};

  const queryParams = getSearchParams({ parseBooleans: true, parseNumbers: true });
  const {
    cabins,
    currencyCode: currencyCodeQuery,
    duration: durationQuery,
    fromDate: fromDateQuery,
    isCabinAccessible: accessibleQuery,
    isUpsell,
    maxPrice: maxPriceQuery,
    metaType: cabinTypeQuery,
    minPrice: minPriceQuery,
    packageCode,
    priceType,
    sailors: sailorsQuery,
    shipCode: shipCodeQuery,
    toDate: toDateQuery,
    voyageId,
  } = queryParams || {};
  const { isMNVV } = getMnvvReservation();

  const isUpsellEnabled = Boolean(isUpsell);
  const isUpsellApplied = isUpsellEnabled && isUpsell === 'true';

  return {
    accessible: accessibleRedux ?? accessibleQuery,
    cabinType: capitalize(cabinTypeRedux ?? cabinTypeQuery),

    cabins,
    currencyCode: currencyCodeRedux ?? currencyCodeQuery,
    duration: durationRedux ?? durationQuery,
    fm,
    fromDate: fromDateQuery ?? fromDateRedux,
    isMNVV,
    isUpsellApplied,

    isUpsellEnabled,
    maxPrice: maxPriceRedux ?? maxPriceQuery,
    minPrice: minPriceRedux ?? minPriceQuery,
    packageCode,

    priceType,
    sailors: sailorsQuery ?? sailorsRedux,
    ship: shipCodeRedux ?? shipCodeQuery,
    sortType,

    toDate: toDateQuery ?? toDateRedux,

    voyageId,
    weekend,
  };
};

import { selectPackages } from '@/ducks/pages/chooseVoyage/selectorDetails';
import {
  getLabelForSailors,
  getLabelforCabinType,
  getLabelforDuration,
  getLabelforPorts,
  getLabelforPrice,
  getLabelforShips,
} from '@/helpers/util';

import { selectFilters } from '../filters/selectors';
import { getDefaultFilters, getFiltersOptions } from '../filtersOptions';
import { getPresentDurationsForPackages } from '../pages/chooseVoyage/getters';
import { resetAppliedFilterLabel, setAppliedFilterLabels } from './appliedfilterlabel';

export const setFilterLabel = (labels) => (dispatch) => {
  dispatch(setAppliedFilterLabels(labels));
};

export const resetFilterLabel = (label) => (dispatch) => {
  dispatch(resetAppliedFilterLabel(label));
};
export const setAppliedLabels = (formatMessage) => async (dispatch, getState) => {
  const state = getState();
  const filters = selectFilters(state);
  const { cabinTypes } = getFiltersOptions(state);
  const filterOptions = getPresentDurationsForPackages(state);
  const defaultFilters = getDefaultFilters(state);
  const packages = selectPackages(state);
  const { accessible, cabinType: selectedCabin, cabins, durations, homePorts, sailors, shipList, weekend } = filters;
  const labels = {
    accessible: accessible ? 'Accessible' : '',
    cabinType: getLabelforCabinType(cabinTypes, selectedCabin),
    duration: getLabelforDuration(durations, filterOptions, formatMessage),
    port: getLabelforPorts(homePorts, formatMessage),
    price: getLabelforPrice(filters, packages, formatMessage),
    ship: getLabelforShips(shipList, formatMessage),
    travelParty: sailors !== defaultFilters?.sailors ? getLabelForSailors(sailors, cabins, formatMessage) : '',
    weekend: weekend ? 'Weekend voyages' : '',
  };
  dispatch(setAppliedFilterLabels({ ...labels }));
};

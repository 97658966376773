import isEmpty from 'lodash/isEmpty';

import type { RootState } from '@/store';
import type { TOptional } from '@/types/common';

import { selectLookupStates } from '@/ducks/common/lookup/selectors';
import {
  selectConfigServiceFeaturePhoneCommunication,
  selectConfigServiceFeaturePhoneNumber,
} from '@/ducks/common/settings/selectors';
import { selectFiltersIsAgencyAvailable } from '@/ducks/filters';
import { selectProfile } from '@/ducks/profile/selectors';
import convertProfileDataToPrimarySailor, {
  type TProfileArg,
} from '@/ducks/travelParty/helpers/convertProfileDataToPrimarySailor';
import createMemoSelector from '@/helpers/createMemoSelector';

import type { TStoreTravelParty } from './slice';
import type { TFieldName, TSailorInfo } from './types';

import {
  ADDRESS_FIELDS,
  COMMON_REQUIRED_FIELDS,
  DEFAULT_PHONE_COUNTRY_CODE,
  FIELD_NAMES,
  PHONE_FIELDS,
} from './constants';
import getAddressRequiredFields from './helpers/getAddressRequiredFields';

export type TSailorInfoSlice = { [key in TFieldName]?: TSailorInfo[key] };
export type TSailorInfoWithInsurance = { insurance: TOptional<boolean> } & TSailorInfo;

export const isEquiv = (one: unknown, two: unknown): boolean => {
  if (one === two) {
    return true;
  }
  const nils = [undefined, null] as unknown[];
  const empties = [false, ''] as unknown[];
  if (nils.includes(one)) {
    return nils.includes(two) || empties.includes(two);
  }
  if (nils.includes(two)) {
    return empties.includes(one);
  }
  return false;
};

// Direct selectors:

export const selectTravelParty = (state: RootState) => state.travelParty as TStoreTravelParty;

export const selectPrimarySailor = (state: RootState) => selectTravelParty(state)?.primarySailor;

export const selectSailorSlotCount = (state: RootState) => selectTravelParty(state)?.sailorSlotCount;

export const selectSecondarySailors = (state: RootState) => selectTravelParty(state)?.secondarySailors;

export const selectRemovedSecondarySailors = (state: RootState) => selectTravelParty(state)?.removedSecondarySailors;

export const selectAdditionalSailorIsInspecting = (state: RootState) =>
  selectTravelParty(state)?.isSecondarySailorInspecting;

export const selectIsVoyageProtectionAdded = (state: RootState) => selectTravelParty(state)?.addonVoyageProtection;

export const selectPrimarySailorErrors = (state: RootState) => selectTravelParty(state)?.primarySailorErrors;

export const selectAdditionalInvalidSailors = (state: RootState) => selectTravelParty(state)?.invalidSecondarySailors;

export const selectHasInvalidAdditionalSailors = (state: RootState) => !!selectAdditionalInvalidSailors(state)?.length;

export const selectIsAdditionalSailorEditing = (state: RootState) =>
  !!selectTravelParty(state)?.secondarySailorsOnEdit?.length;

// Composite selectors:

export const selectIsPrimarySailorFormChanged = (state: RootState) => {
  const primarySailor = selectPrimarySailor(state);
  const defaultValues = selectPrimarySailorDefaultValues(state);
  const profile = selectProfile(state);
  const initialValues = !isEmpty(profile)
    ? convertProfileDataToPrimarySailor(profile as TProfileArg, {})
    : defaultValues;
  return (
    !!primarySailor &&
    Object.entries(primarySailor).some(([key, value]) => !isEquiv(value, initialValues[key as TFieldName]))
  );
};

// Memoized selectors (can have dependencies on direct and composite selectors:

export const selectCountriesWithProvinces = createMemoSelector(selectLookupStates, (provinces): string[] => {
  const countries: string[] = [];
  provinces?.forEach(({ countryCode }) => {
    if (!countries.includes(countryCode)) {
      countries.push(countryCode);
    }
  });
  return countries.sort();
});

export const selectPrimarySailorWithInsurance = createMemoSelector(
  selectPrimarySailor,
  selectIsVoyageProtectionAdded,
  // For the legacy code where "insurance" field is used directly
  (primarySailor, insurance): TSailorInfoWithInsurance => ({ ...primarySailor, insurance }),
);

export const makePrimarySailorsExcludedFieldsSelector = (
  isMobileOptInEnabled: TOptional<boolean>,
  isMNVV: TOptional<boolean>,
) =>
  createMemoSelector(
    selectConfigServiceFeaturePhoneCommunication,
    selectConfigServiceFeaturePhoneNumber,
    selectFiltersIsAgencyAvailable,
    selectIsVoyageProtectionAdded,
    (isPhoneCommunicationAllowed, isPhoneAllowed, isAgencyAvailable, isVoyageProtectionAdded) => {
      const canOptIn = isMNVV || !isAgencyAvailable;
      const canOptInSMS = canOptIn && isMobileOptInEnabled && isPhoneAllowed && isPhoneCommunicationAllowed;
      return [
        FIELD_NAMES.IS_SAME_AS_PRIMARY,
        ...(!canOptIn ? [FIELD_NAMES.IS_OPT_IN_FOR_EMAIL] : []),
        ...(!canOptInSMS ? [FIELD_NAMES.IS_OPT_IN_FOR_SMS] : []),
        ...(!isVoyageProtectionAdded ? ADDRESS_FIELDS : []),
      ];
    },
  );

export const selectPrimarySailorRequiredFields = createMemoSelector(
  selectCountriesWithProvinces,
  selectPrimarySailor,
  selectIsVoyageProtectionAdded,
  (countries, primarySailor, isVoyageProtected) => [
    ...COMMON_REQUIRED_FIELDS,
    FIELD_NAMES.OVER_18,
    ...PHONE_FIELDS,
    ...(isVoyageProtected ? getAddressRequiredFields(primarySailor?.[FIELD_NAMES.COUNTRY], countries) : []),
  ],
);

export const selectPrimarySailorDefaultValues = createMemoSelector(
  selectPrimarySailor,
  (primarySailor): TSailorInfoSlice => ({
    [FIELD_NAMES.CITIZENSHIP]: '',
    [FIELD_NAMES.COUNTRY]: primarySailor?.[FIELD_NAMES.COUNTRY] || '',
    [FIELD_NAMES.PHONE]: primarySailor?.[FIELD_NAMES.PHONE],
    [FIELD_NAMES.PHONE_COUNTRY_CODE]: DEFAULT_PHONE_COUNTRY_CODE,
    [FIELD_NAMES.STATE]: '',
  }),
);

export const selectSailorVoyageProtectionFields = createMemoSelector(
  selectCountriesWithProvinces,
  selectPrimarySailor,
  (countries, primarySailor) => getAddressRequiredFields(primarySailor?.[FIELD_NAMES.COUNTRY], countries),
);

export const selectAdditionalSailorErrors = createMemoSelector(
  selectAdditionalInvalidSailors,
  (invalidities): TOptional<TFieldName[]> => invalidities?.flatMap(({ errorFields }) => errorFields),
);

export const selectAdditionalSailorExcludedFieldsFullForm = createMemoSelector(
  selectIsVoyageProtectionAdded,
  (withVoyageProtection) => [
    FIELD_NAMES.IS_OPT_IN_FOR_EMAIL,
    FIELD_NAMES.IS_OPT_IN_FOR_SMS,
    FIELD_NAMES.OVER_18,
    ...(!withVoyageProtection ? [FIELD_NAMES.IS_SAME_AS_PRIMARY, ...ADDRESS_FIELDS] : []),
  ],
);

export const selectAdditionalSailorExcludedFieldsSameAddress = createMemoSelector(
  selectIsVoyageProtectionAdded,
  selectAdditionalSailorExcludedFieldsFullForm,
  (withVoyageProtection, excludedFields) => [...excludedFields, ...(withVoyageProtection ? ADDRESS_FIELDS : [])],
);

export const selectAdditionalSailorDefaultValues = createMemoSelector((): TSailorInfoSlice => {
  const { CITIZENSHIP, COUNTRY, PHONE_COUNTRY_CODE } = FIELD_NAMES;
  return {
    [CITIZENSHIP]: '',
    [COUNTRY]: '',
    [PHONE_COUNTRY_CODE]: DEFAULT_PHONE_COUNTRY_CODE,
  };
});

export const selectUsedEmails = createMemoSelector(
  selectPrimarySailor,
  selectSecondarySailors,
  (primarySailor, additionalSailors): Record<number, string> => {
    const { EMAIL } = FIELD_NAMES;
    return {
      0: primarySailor?.[EMAIL],
      ...Object.fromEntries(
        additionalSailors?.filter(Boolean).map(({ [EMAIL]: email, sailorNumber }) => [sailorNumber, email]) || [],
      ),
    };
  },
);

const buildWithDelimiter = (strings, delimiter = '|') => strings?.filter(Boolean).join(delimiter);
const buildCtaLabel = (string) => (string ? `cta-${string.trim()}` : '');
const buildLabel = (strings, { lowerCase = true } = {}) =>
  buildWithDelimiter(
    strings?.filter(Boolean)?.map((str) => (lowerCase ? str.toLowerCase() : str).trim().replace(/ /g, '-')),
  );

const buildPage = (prefix = '', page = '') => (prefix ? `${prefix}-${page}` : page ?? '');
const buildModule = (module = '') => (module ? `|${module}` : '');
const buildFlyouts = (flyouts = []) => (flyouts?.filter(Boolean)?.length ? `|${buildWithDelimiter(flyouts)}` : '');
const buildEventCategory = (page = '', module = '', flyout = '') => `${page}${module}${flyout}`;
const addRootPrefix = (prefix, page) => (prefix && page ? `${prefix || ''}-${page || ''}` : page || '');

export default {
  addRootPrefix,
  buildCtaLabel,
  buildEventCategory,
  buildFlyouts,
  buildLabel,
  buildModule,
  buildPage,
  buildWithDelimiter,
};

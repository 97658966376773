export const ENV_URLS = {
  CERT: 'https://cert.gcpshore.virginvoyages.com/',
  DEV: 'https://dev.gcpshore.virginvoyages.com/',
  INT: 'https://int.gcpshore.virginvoyages.com/',
  PROD: 'https://www.virginvoyages.com/',
  PROD_API: 'https://prod.virginvoyages.com/',
  STAGE: 'https://stage.gcp.virginvoyages.com/',
};

export const SDK_KEY_MAP = {
  [ENV_URLS.CERT]: '3b708efdaecdd6233a81c7192d30c184',
  [ENV_URLS.DEV]: 'e39168103a9aeaa4366ac90dc813e91a',
  [ENV_URLS.INT]: '7880544b45f3d39552bbf3cd94824392',
  [ENV_URLS.PROD]: '871d6ae18d8b9bcc84596c19d3375968',
  [ENV_URLS.PROD_API]: '871d6ae18d8b9bcc84596c19d3375968',
  [ENV_URLS.STAGE]: '170829ce424dd0f75885c6a70e64298a',
};

export const VWO_MAIN_ACCOUNT_ID = '638475';
export const VWO_USER_ID_STORAGE_KEY = '__vwoUserId';
export const VWO_SETTINGS_FILE_STORAGE_KEY = '__vwoSettingsFile';
export const VWO_FEATURE_FLAG_VALUES_RECEIVED = '__vwoFeatureFlagsReceived';

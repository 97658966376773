import { env } from '@/environment';

const PROD_ENV_PREFIXES = [
  'stage.gcp.virginvoyages.com',
  'prod.gcp.virginvoyages.com',
  'prod.virginvoyages.com',
  'virginvoyages.com',
];

export const isProdEnv = () => PROD_ENV_PREFIXES.includes(env.ORIGIN ?? '');
export const getIsProdBuild = () => env.NODE_ENV === 'production';

import { createSelector } from '@reduxjs/toolkit';

import type { TFilters } from '@/infra/types/common/filters';
import type { RootState } from '@/store';

import { selectResources } from '@/ducks/common/selectors';

const emptyArray: string[] = [];

export const selectFilters = (state: RootState) => state?.filters as TFilters;

export const selectAccessible = (state: RootState) => selectFilters(state)?.accessible;
export const selectCabins = (state: RootState) => selectFilters(state)?.cabins;
export const selectCabinType = (state: RootState) => selectFilters(state)?.cabinType;
export const selectCurrencyCode = (state: RootState) => selectFilters(state)?.currencyCode;
export const selectPriceType = (state: RootState) => selectFilters(state)?.priceType;
export const selectSailors = (state: RootState) => selectFilters(state)?.sailors;
export const selectDeparturePorts = (state: RootState) => selectFilters(state)?.homePorts ?? emptyArray;
export const selectDurations = (state: RootState) => selectFilters(state)?.durations;
export const selectMaxPrice = (state: RootState) => selectFilters(state)?.maxPrice;
export const selectMinPrice = (state: RootState) => selectFilters(state)?.minPrice;
export const selectIsPriceRangeEmpty = (state: RootState) => !selectMaxPrice(state) && !selectMinPrice(state);
export const selectShipList = (state: RootState) => selectFilters(state)?.shipList;
export const selectSortType = (state: RootState) => selectFilters(state)?.sortType;
export const selectChooseCabinSortType = (state: RootState) => selectFilters(state)?.chooseCabinSort;
export const selectToDate = (state: RootState) => selectFilters(state)?.toDate;
export const selectFromDate = (state: RootState) => selectFilters(state)?.fromDate;
export const selectPackages = (state: RootState) => selectFilters(state)?.packages;
export const selectPorts = (state: RootState) => selectFilters(state)?.ports;
export const selectRegions = (state: RootState) => selectFilters(state)?.selectedRegions;
export const selectWeekend = (state: RootState) => selectFilters(state)?.weekend;
export const selectNodes = (state: RootState) => selectFilters(state)?.nodes;

// not filters:
// TODO: Replace with cookies, it used only in travel party
export const selectFiltersAgencyId = (state: RootState) => selectFilters(state)?.agencyId;
export const selectFiltersAgentId = (state: RootState) => selectFilters(state)?.agentId;
export const selectFiltersIsAgencyAvailable = (state: RootState) =>
  !!selectFiltersAgencyId(state) || !!selectFiltersAgentId(state);

export const getMessage = (state: RootState) => {
  const { accessible, cabins, sailors } = selectFilters(state);
  const message = accessible
    ? [`${cabins} cabin`, `${sailors} people`, 'Accessible cabin']
    : [`${cabins} cabin`, `${sailors} people`];
  return message;
};

export const selectTitleWithAllRegions = createSelector([selectResources, selectFilters], (resources, filters) => {
  const { selectedRegions: allRegions, selectedRegionsIds } = filters;
  const allRegionsLabel = resources?.['Destination.filter.text'] || 'All regions';
  if (selectedRegionsIds?.length === 0 || allRegions?.length === selectedRegionsIds?.length) return allRegionsLabel;

  const regionNames = selectedRegionsIds.map((e: unknown) => {
    if (e && typeof e === 'object' && 'name' in e) {
      // TODO: check if it possible to have object here
      return e?.name;
    }
    return e;
  });
  return regionNames.join(', ');
});

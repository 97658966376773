import { getFilterAgencyCurrencyCode } from '@/ducks/fm/getters';
import { getSearchParam } from '@/ducks/routes/history';
import { getBookingSource } from '@/helpers/util/misc';

export default (parsedParameters, supportedCurrencies, flow) => {
  const { accessible, currencyCode, promoCode, sailors, voyageId } = parsedParameters;

  const agencyCurrencyCode = getFilterAgencyCurrencyCode();
  const searchQualifier = {
    accessible,
    cabins: [
      {
        guestCounts: [
          {
            ageCategory: 'Adult',
            count: getCabins(sailors),
          },
        ],
      },
    ],
    currencyCode: agencyCurrencyCode || currencyCode,
    promoCodes: promoCode ? [promoCode] : [],
  };

  let validationError;
  if (
    searchQualifier.currencyCode &&
    supportedCurrencies.length &&
    !supportedCurrencies.includes(searchQualifier.currencyCode)
  ) {
    validationError = new Error(
      `Unsupported currency used: ${searchQualifier.currencyCode}. Supported currencies: ${supportedCurrencies.join(
        ', ',
      )}`,
    );
    validationError.cause = `Invalid currency code was passed via ${
      agencyCurrencyCode ? 'cookie' : 'argument'
    }. Flow: ${flow}`;
  }
  const reqBody = {
    searchQualifier,
    voyageId: getUrlVoyageId(voyageId),
    ...getBookingSource(),
  };
  return [reqBody, { validationError }];
};

export const getUrlVoyageId = (voyageId) => {
  const urlVoyageId = getSearchParam('voyageId');
  if (urlVoyageId === null) {
    return voyageId;
  }
  return urlVoyageId;
};

const getCabins = (sailors) => {
  const urlSailorCount = sailors || getSearchParam('sailors');

  return parseInt(urlSailorCount, 10);
};

export const enum VWOFlag {
  ALT_24HR_BREATHER = 'alt24HrBreatherPageCampaign',
  ALT_CHOOSE_CABIN_SORT = 'altChooseCabinSortCampaign',
  ALT_SAILOR_DETAILS_FORM_UX = 'altSailorDetailsFormUXCampaign',
  BLACK_FRIDAY = 'blackFridayFeature',
  BOOK_NOW = 'bookingHoldLogicChangeCampaign',
  MNVV_OFFER_REVISION = 'MNVVOnboardOfferRevisionCampaign',
  MOBILE_OPTIN = 'mobileOptInFeatureCampaign',
  SIZZLE_REELS = 'sizzleReelsCampaign',
  SUMMARY_V2 = 'bookingSummaryV2Campaign',
  TAXES_AND_FEES = 'bookingTaxesAndFeesCampaign',
  VPS_PAYMENT = 'bookingVpsMigrationFeatureFlag',
  WHATS_INCLUDED = 'valuePropModuleFeatureFlag',
}

export const enum WHATS_INCLUDED_KEYS {
  CHOOSE_VOYAGE_PAGE = 'chooseVoyagePage',
  META_CATEGORY_PAGE = 'metaCategoryPage',
  PAYMENT_PAGE = 'paymentPage',
  SUB_META_CATEGORY_PAGE = 'subMetaCategoryPage',
  SUMMARY_PAGE = 'summaryPage',
}

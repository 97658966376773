import type { ReactElement } from 'react';

import defaultMessages from '@/constants/defaultUIResources.json';

export type TPrimitive = boolean | number | string;

export const enum ErrorPolicy {
  LEAVE_AS_IS,
  EMPTY,
  THROW,
}

export type TUIResourceId = keyof typeof defaultMessages;
export type TUIResourceImage = { alt: string; src: string };
export type TUIResourceNode = Array<ReactElement | string> | ReactElement;
export type TUIResourceValue = ReactElement | TPrimitive;
export type TUIResourceValueMap<TKey extends number | string | symbol = string> = Record<TKey, TUIResourceValue>;

export type TUIResourceStorable = TUIResourceImage | string;
export type ResourcesSlice = Record<TUIResourceId, TUIResourceStorable>;

import map from 'lodash/map';

import { getSailingsToDisplay } from '@/components/ChooseVoyage/PackageCardDetails/PackageCardTextContent/helpers';
import tagmanager from '@/tagmanager';
import { trackViewOnScroll } from '@/tagmanager/event/types/common';

import { CHOOSE_VOYAGE_ADD_TRACKED_SAILINGS } from '../reducer';
import { getTrackedSailings } from '../selectors';

export const trackVoyageView =
  ({ labels = undefined, list, packagesData = [], viewedIdsSelector = getTrackedSailings }) =>
  () =>
  (dispatch, getState) => {
    const [packageData] = packagesData;
    const { sailingsToDisplay } = getSailingsToDisplay(packageData);

    const onViewed = (visibleVoyages) => {
      if (!visibleVoyages?.length) {
        return;
      }

      tagmanager.observer.trackingEventsObserver.notify({
        data: {
          dispatch,
          getState,
          labels,
          list,
          sailings: visibleVoyages,
        },
        name: tagmanager.observer.TRACKING_EVENTS_FOR_OBSERVER.SAILING_PACK_APPEARED,
      });

      dispatch({
        payload: map(visibleVoyages, 'id'),
        type: CHOOSE_VOYAGE_ADD_TRACKED_SAILINGS,
      });
    };

    dispatch(
      trackViewOnScroll({
        onViewed,
        viewedIdsSelector,
      })(sailingsToDisplay),
    );
  };

export default trackVoyageView;

import { endOfMonth, isAfter } from 'date-fns';
import { isEmpty } from 'lodash';

import { FILTER_TYPES } from '@/ducks/filters/constants';
import { selectFilters, selectPackages, selectPorts } from '@/ducks/filters/selectors';
import { selectFilterByName } from '@/ducks/filters/values';
import { applyFilters } from '@/ducks/pages/chooseVoyage';
import { selectFilteredPackages } from '@/ducks/pages/chooseVoyage/filteredPackages/selectors';
import { getSailingForSailorPerNight, getSailings } from '@/helpers/data/mappers/Summary';
import { format, parse } from '@/helpers/util/dateUtil';

import { getClassificationById, getDefaultFilters, getFiltersOptions, getRegionById } from '../filtersOptions';
import { setFilters, setSlectionFilter, setTaxInclusive } from './filters';

/**
 * Most filters can be set with plain setFilters({ ... })
 * but some require additional work/checks/calculations
 */
const createSetter = (filterName) => (filterValue) => (dispatch) => {
  dispatch(setFilters({ [filterName]: filterValue }));
};

export const setSailors = createSetter('sailors');
export const setCabinType = createSetter('cabinType');
export const setAccessible = createSetter('accessible');
export const setSortType = createSetter('sortType');
export const setPriceType = createSetter('priceType');

export const setDurations = (durations, weekend) => (dispatch) => {
  dispatch(setFilters({ durations, weekend }));
};

export const setCurrencyCode =
  (currencyCode, shouldApplyFilters = true) =>
  (dispatch) => {
    setTaxInclusive(currencyCode);
    dispatch(setFilters({ currencyCode }, shouldApplyFilters));
  };

export const setFilterDates = (start, end) => (dispatch, getState) => {
  const state = getState();
  const filtersOptions = getFiltersOptions(state);
  const minDate = parse(filtersOptions.minDate);
  const maxDate = parse(filtersOptions.maxDate);

  const fromDate = parse(start || getDefaultFilters(state).fromDate);
  const toDate = end ? parse(end) : endOfMonth(fromDate);
  dispatch(
    setFilters({
      fromDate: format(isAfter(fromDate, minDate) ? fromDate : minDate),
      toDate: format(isAfter(toDate, maxDate) ? maxDate : toDate),
    }),
  );

  dispatch(
    applyFilters({
      fetch: true,
    }),
  );
};

// Go through all packages / ports / nodes and add/remove filters
// depending on presence in "selected" values array
const adjustSelectedItineraries = (prevFilters, itineraries, selected) => {
  const result = new Set([...prevFilters]);
  itineraries.forEach(({ code, id }) => {
    const uniqId = id || code;
    if (selected.includes(uniqId)) {
      result.add(uniqId);
    } else {
      result.delete(uniqId);
    }
  });
  return Array.from(result);
};

/**
 * Get new packages/ports filters based on current filters and selected ones for particular region
 */
export const setRegionItineraries =
  (regionId, { packages, ports }) =>
  (dispatch, getState) => {
    const state = getState();
    const region = getRegionById(state, regionId);
    if (!region) {
      return;
    }
    const filterPorts = selectPorts(state);
    const filterPackages = selectPackages(state);

    const uniqPorts = adjustSelectedItineraries(filterPorts, region.ports, ports);
    const uniqPackages = adjustSelectedItineraries(filterPackages, region.packages, packages);

    dispatch(
      setFilters({
        packages: uniqPackages,
        ports: uniqPorts,
      }),
    );
  };

// Apply classification nodes filter
export const setClassificationItineraries =
  (id, { packages: nodes }) =>
  async (dispatch, getState) => {
    const state = getState();
    const filters = selectFilters(state);
    const classification = getClassificationById(state, id);

    await dispatch(
      setFilters({
        nodes: adjustSelectedItineraries(filters.nodes, classification.nodes, nodes),
      }),
    );
  };

export const setPriceRange = (priceRange) => (dispatch) => {
  const { minPrice } = priceRange;
  const { maxPrice } = priceRange;
  dispatch(setFilters({ maxPrice, minPrice }));
};

export const setSelectedShips = (shipList) => (dispatch) => {
  dispatch(setFilters(shipList));
};

export const setDeparturePorts = (homePorts) => (dispatch) => {
  dispatch(setFilters(homePorts));
};

export const setSelectedRegionIds = (selectedRegionId) => (dispatch) => {
  dispatch(setSlectionFilter({ selectedRegions: selectedRegionId }));
};

export const setFilterPackges = (packages) => (dispatch) => {
  dispatch(
    setFilters({
      packages,
    }),
  );
};

export const setFilterPorts = (ports) => (dispatch) => {
  dispatch(
    setFilters({
      ports,
    }),
  );
};

export const setCabinSortType = (sortType) => (dispatch) => {
  dispatch(
    setFilters({
      chooseCabinSort: sortType || 'recommended',
    }),
  );
};

export const calcPriceRangeFromPackages = (updatedPriceType) => (dispatch, getState) => {
  const state = getState();
  const cabins = selectFilterByName(state, FILTER_TYPES.cabins);
  const sailors = selectFilterByName(state, FILTER_TYPES.sailors);
  const priceType = updatedPriceType || selectFilterByName(state, FILTER_TYPES.priceType);
  const filteredPackages = selectFilteredPackages(state);
  const packages = selectPackages(state);

  const actualPackages = isEmpty(filteredPackages) ? packages : filteredPackages;
  let filteredData = {};
  if (priceType === 'SailorPerNight') {
    filteredData = getSailingForSailorPerNight(actualPackages, { sailors });
  } else {
    filteredData = getSailings(actualPackages, { cabins, priceType, sailors });
  }
  const { toBeAppliedPriceRange } = filteredData;
  return toBeAppliedPriceRange;
};

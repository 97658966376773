import { getSearchParams } from './history';

const filterObjectFields = (object, fieldsToFilter) =>
  Object.fromEntries(Object.entries(object).filter((entry) => !fieldsToFilter.includes(entry[0])));

export const searchParamsModifierDefaultArgument = () => ({ apply: {}, customReWrite: {}, remove: [] });

export const calculateSearchParams = (
  searchParamsModifier = searchParamsModifierDefaultArgument(),
  additionalParamsToRemove = [],
) => ({
  ...(Object.keys(searchParamsModifier.customReWrite || {}).length
    ? { ...searchParamsModifier.customReWrite }
    : {
        ...filterObjectFields(getSearchParams(), [...(searchParamsModifier.remove || []), ...additionalParamsToRemove]),
        ...(searchParamsModifier.apply || {}),
      }),
});

export const CommonSearchParams = Object.freeze({
  showError: 'showError',
});

export const ChooseCabinSubMetaSearchParams = Object.freeze({
  cabinCategoryCode: 'cabinCategoryCode',
  cabinType: 'cabinType',
  category: 'category',
  isUpsell: 'isUpsell',
});

export const ChooseCabinMetaSearchParams = Object.freeze({
  itineraryDetails: 'itineraryDetails',
  packageCode: 'packageCode',
  shipCode: 'shipCode',
});

export const NEW_CHOOSE_VOYAGE_SEARCH_START = 'NEW_CHOOSE_VOYAGE_SEARCH_START';
export const NEW_CHOOSE_VOYAGE_SEARCH_SUCCESS = 'NEW_CHOOSE_VOYAGE_SEARCH_SUCCESS';
export const NEW_CHOOSE_VOYAGE_SEARCH_FAILURE = 'NEW_CHOOSE_VOYAGE_SEARCH_FAILURE';
export const NEW_CHOOSE_VOYAGE_SEARCH_API_SUCCESS = 'NEW_CHOOSE_VOYAGE_SEARCH_API_SUCCESS';
export const SET_MULTIPLE_VOYAGE_FILTER_FLAG = 'SET_MULTIPLE_VOYAGE_FILTER_FLAG';
export const NEW_GENERIC_CATEGORY_CODE_DATA = 'NEW_GENERIC_CATEGORY_CODE_DATA';
export const RESET_LOCK_IT_IN_RATE_PROMO_STATE = 'RESET_LOCK_IT_IN_RATE_PROMO_STATE';

export const SET_VOYAGE_INVALID = 'SET_VOYAGE_INVALID';
export const NEW_CHOOSE_VOYAGE_SET_LOADING = 'NEW_CHOOSE_VOYAGE_SET_LOADING';

import type { TAgencyDetails, TAgentDetails } from '@/infra/types/fmlink/agent';

import { BookingChannelTypes } from '@/constants/settings';
import { getCookie as getCookieClient } from '@/ducks/cookies';

import { AGENCY_COOKIE, AGENCY_CURRENCY_COOKIE, AGENT_COOKIE } from './constants';

export const getFilterAgent = () => getCookieClient(AGENT_COOKIE);
export const getFilterAgency = () => getCookieClient(AGENCY_COOKIE);
export const getFilterAgencyCurrencyCode = () => getCookieClient(AGENCY_CURRENCY_COOKIE);

export const getFilterAgentId = () => getFilterAgent()?.agentId || '';
export const getFilterAgencyId = () => getFilterAgency()?.agencyId || '';
export const getFilterAgentBookingChannel = () => getFilterAgent()?.bookingChannel || '';
export const getFilterAgentDefaultCurrency = () => getFilterAgent()?.currency || '';

//this method helps to determine whether it is FMFlow or Non FMFlow, if FMFlow return true;
export const getFilterAgencyIsAvailable = (getCookie: (name: string) => unknown = getCookieClient) => {
  const agencyDetails = getCookie(AGENCY_COOKIE) as TAgencyDetails;
  const agentDetail = getCookie(AGENT_COOKIE) as TAgentDetails;
  return (
    Boolean(agencyDetails?.agencyId || agentDetail?.agentId) &&
    agentDetail?.bookingChannel === BookingChannelTypes.fmLink
  );
};

export const getFilterAgencyDetails = () => {
  const agencyDetails = getFilterAgency();
  return agencyDetails;
};

export const getFMMultiCurrencies = () => {
  const multiCurrencies = getFilterAgency()?.multiCurrencies || [];
  return multiCurrencies;
};

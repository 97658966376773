import type { TSailorInfo } from '../types';

import { FIELD_NAMES } from '../constants';

export type TProfileArg = {
  addresses?: Array<{
    city?: string;
    countryCode?: string;
    lineOne?: string;
    lineTwo?: string;
    state?: string;
    zipCode?: string;
  }>;
  birthDate?: string;
  citizenshipCountryCode?: string;
  email?: string;
  firstName?: string;
  genderCode?: string;
  lastName?: string;
  phoneCountryCode?: string;
  phoneNumber?: string;
};

const convertProfileDataToPrimarySailor = (profile: TProfileArg, primarySailor: Partial<TSailorInfo>) =>
  ({
    [FIELD_NAMES.APT_NO]: profile.addresses?.[0]?.lineTwo || primarySailor.aptNo,
    [FIELD_NAMES.CITIZENSHIP]: profile.citizenshipCountryCode || primarySailor.citizenShip || '',
    [FIELD_NAMES.CITY]: profile.addresses?.[0]?.city || primarySailor.city,
    [FIELD_NAMES.COUNTRY]: profile.addresses?.[0]?.countryCode || primarySailor.country,
    [FIELD_NAMES.DOB]: profile.birthDate || primarySailor.dob || '',
    [FIELD_NAMES.EMAIL]: profile.email || primarySailor.email || '',
    [FIELD_NAMES.FIRST_NAME]: profile.firstName || primarySailor.firstname || '',
    [FIELD_NAMES.GENDER]: profile.genderCode || primarySailor.gender || '',
    [FIELD_NAMES.LAST_NAME]: profile.lastName || primarySailor.lastname || '',
    [FIELD_NAMES.PHONE]: profile.phoneNumber || primarySailor.contactnumber,
    [FIELD_NAMES.PHONE_COUNTRY_CODE]: profile.phoneCountryCode || primarySailor.phoneCountryCode,
    [FIELD_NAMES.STATE]: profile.addresses?.[0]?.state || primarySailor.stateCode,
    [FIELD_NAMES.STREET_ADDRESS]: profile.addresses?.[0]?.lineOne || primarySailor.streetAddress,
    [FIELD_NAMES.ZIP_CODE]: profile.addresses?.[0]?.zipCode || primarySailor.postalCode,
  }) as TSailorInfo;

export default convertProfileDataToPrimarySailor;

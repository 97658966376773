import every from 'lodash/every';
import map from 'lodash/map';

import { selectFilters } from '@/ducks/filters/selectors';
import { getClassifications, getRegions } from '@/ducks/filtersOptions';
import { selectSailings } from '@/ducks/pages/chooseVoyage/selectorDetails';

import { getPresentNodes, getPresentPackages, getPresentPorts } from './sailingSearch';

const getRoot = (state) => state.chooseVoyage;

export const getSailing = (state) => (id) => {
  const sailings = selectSailings(state);
  if (sailings && sailings.length) {
    return sailings.find((sailing) => sailing.id === id);
  }
  return null;
};

export const getIsPickyItemsApplied = (state) => {
  const filters = selectFilters(state);
  return Boolean(filters.cabinType || filters.weekend || (filters.durations && filters.durations.length));
};

export const getTrackedSailings = (state) => getRoot(state)?.trackedSailings;

/*
 * Amend packages / ports / nodes arrays helper
 * - add price and duration info from sailingSearch for packages and nodes
 * - add 'applied' flag based on current filters
 * - add 'present' flag based on presence in current search results
 * - sort by navigationOrder
 * @param itineraries[] - array of itineraries: packages / ports / classification nodes
 * @param filters[] - current filters, array of applied IDs
 * @param searchResults - object of shape itineraryId -> { additional info }
 */
const amendItineraries = (itineraries, filters, searchResults, isSelectedRegion, isFilterNew) =>
  map(itineraries, (itinerary) => ({
    ...itinerary,
    ...(searchResults[itinerary.id] || {}),
    applied: !isFilterNew
      ? filters.includes(itinerary.id)
      : isSelectedRegion && isSelectedRegion.includes(itinerary.id),
    present: !!searchResults[itinerary.id],
  })).sort((a, b) => a.navigationOrder - b.navigationOrder);

const amendPortsOFCall = (itineraries, filters, searchResults) => {
  const key = Object.keys(itineraries);
  let portsOfCall = {};

  key.map((country) => {
    const result = itineraries[country].map((port) => ({
      ...port,
      ...(searchResults[port.code] || {}),
      applied: filters.includes(port.code),
      present: !!searchResults[port.code],
    }));
    portsOfCall = {
      ...portsOfCall,
      [country]: result,
    };
    return null;
  });

  return portsOfCall;
};

// Amend region object with convenience UI flags and structures
const amendRegion = (state, region) => {
  const filters = selectFilters(state);
  const { selectedRegions } = filters;
  const isSelectedRegion = selectedRegions?.find((selctedRgn) => region.id === selctedRgn.regionId);
  const isFilterNew = true;
  const filteredRegionPackages = region.packages.filter((pckg) => pckg?.id);
  const packages = amendItineraries(
    filteredRegionPackages,
    filters.packages,
    getPresentPackages(state),
    isSelectedRegion ? isSelectedRegion.packages : [],
    isFilterNew,
  );
  const ports = amendItineraries(
    region.ports,
    filters.ports,
    getPresentPorts(state),
    isSelectedRegion ? isSelectedRegion.ports : [],
    isFilterNew,
  );
  const portsOfCall = amendPortsOFCall(region.portsOfCall, filters.ports, getPresentPorts(state));
  // particular region packages and ports which are present in filters
  const appliedPackages = packages
    .reduce((acc, pkg) => (pkg.applied ? [...acc, pkg.id] : acc), [])
    .filter((item) => item);
  const appliedPorts = ports.reduce((acc, port) => (port.applied ? [...acc, port.code] : acc), []);

  return {
    ...region,
    appliedPackages,
    appliedPorts,
    // UI flags
    isApplied: appliedPackages.length || appliedPorts.length,
    isWholeApplied: appliedPackages.length === packages.length,
    isWholeDisabled: every(packages, ['present', false]) && every(packages, ['applied', false]),
    isWholePortsApplied: appliedPorts.length === ports.length,
    packages,
    ports,
    portsOfCall,
    tabApplied: appliedPorts.length ? 'ports' : 'itineraries', // which tab should be active when popover opens
    type: 'region', // explicitly mark type
  };
};

// Amend classification object with convenience UI flags and structures
const amendClassification = (state, classification) => {
  const filters = selectFilters(state);
  const packages = amendItineraries(classification.nodes, filters.nodes, getPresentNodes(state));

  // particular region packages and ports which are present in filters
  const appliedPackages = packages.reduce((acc, pkg) => (pkg.applied ? [...acc, pkg.id] : acc), []);

  return {
    ...classification,
    appliedPackages,
    // rename "nodes" to "packages" and add empty "ports" array
    // so that classification and region structures are uniform
    appliedPorts: [],
    // UI flags
    isApplied: appliedPackages.length,
    isWholeApplied: appliedPackages.length === packages.length,
    isWholeDisabled: every(packages, ['present', false]) && every(packages, ['applied', false]),
    isWholePortsApplied: false,
    // and can be used interchangeably in ItineraryFilter component
    packages,
    ports: [],
    tabApplied: 'itineraries', // there's only one tab for classifications
    type: 'classification', // explicitly mark type
  };
};
export const getResetKey = (state) => {
  const filters = selectFilters(state);
  return filters;
};

/*
 * getItineraryFilters() selector for building filters data for UI filter components
 * There are two types of itinerary filters: regions and classifications
 * We amend them with flags and utility arrays for ease of use in ItineraryFilter component
 */
export const getItineraryFilters = (state) => [
  ...getRegions(state).map((region) => amendRegion(state, region)),
  ...getClassifications(state).map((classification) => amendClassification(state, classification)),
];
export default getItineraryFilters;

import { env } from '@/environment';

export const isAuthAvailable = typeof window !== 'undefined';

const AUTH_BASE_URL = env.AUTH_BASE_URL;
const BASIC_TOKEN = btoa(`${env.CLIENT_ID}:${env.CLIENT_SECRET}`);

let AuthInstance = null;

export const getDXPAuth = () => {
  if (!isAuthAvailable) {
    return null;
  }
  if (!AuthInstance) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const Auth = require('@decurtis/dxp-authentication-sdk').default;
    Auth.init({ basicToken: `Basic ${BASIC_TOKEN}`, force: true, url: AUTH_BASE_URL });
    AuthInstance = window.__DXP_AUTHENTICATION_SDK__;
  }

  return AuthInstance;
};

export const PERSISTENT_STORE_KEYS = ['addonVoyageProtection', 'primarySailor', 'sailorSlotCount', 'secondarySailors'];

export const CITY_REQUIRED_COUNTRIES = ['AU', 'CA', 'GB', 'NZ', 'SG', 'US'] as const;

export const DEFAULT_PHONE_COUNTRY_CODE = 'US' as const;

export const FIELD_NAMES = {
  APT_NO: 'aptNo',
  CITIZENSHIP: 'citizenShip',
  CITY: 'city',
  COUNTRY: 'country',
  DOB: 'dob',
  EMAIL: 'email',
  FIRST_NAME: 'firstname',
  GENDER: 'gender',
  IS_OPT_IN_FOR_EMAIL: 'add_newsletter',
  IS_OPT_IN_FOR_SMS: 'isOptInForSMS',
  IS_SAME_AS_PRIMARY: 'isSameAsPrimary',
  LAST_NAME: 'lastname',
  OVER_18: 'over18',
  PHONE: 'contactnumber',
  PHONE_COUNTRY_CODE: 'phoneCountryCode',
  STATE: 'stateCode',
  STREET_ADDRESS: 'streetAddress',
  ZIP_CODE: 'postalCode',
} as const;

export type TFieldName = (typeof FIELD_NAMES)[keyof typeof FIELD_NAMES];

export const FIELD_NAMES_LIST: TFieldName[] = Object.values(FIELD_NAMES);

export const COMMON_REQUIRED_FIELDS = [
  FIELD_NAMES.FIRST_NAME,
  FIELD_NAMES.LAST_NAME,
  FIELD_NAMES.EMAIL,
  FIELD_NAMES.DOB,
  FIELD_NAMES.GENDER,
  FIELD_NAMES.CITIZENSHIP,
] as TFieldName[];

export const ADDRESS_FIELDS = [
  FIELD_NAMES.APT_NO,
  FIELD_NAMES.CITY,
  FIELD_NAMES.COUNTRY,
  FIELD_NAMES.STATE,
  FIELD_NAMES.STREET_ADDRESS,
  FIELD_NAMES.ZIP_CODE,
] as TFieldName[];

export const ADDRESS_REQUIRED_FIELDS = [
  FIELD_NAMES.COUNTRY,
  FIELD_NAMES.STREET_ADDRESS,
  FIELD_NAMES.ZIP_CODE,
] as TFieldName[];

export const PHONE_FIELDS = [FIELD_NAMES.PHONE, FIELD_NAMES.PHONE_COUNTRY_CODE] as TFieldName[];

import { addMonths, isValid, lastDayOfMonth } from 'date-fns';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

import { checkURLAccessKeyAction, getAccessKey } from '@/ducks/accessKeys';
import { selectDefaultCurrency, selectDefaultPackageCodes } from '@/ducks/common/lookup/selectors';
import { setCruiseCommonPackagesData } from '@/ducks/cruisesCommon/slice';
import { selectCurrencyCode, selectFilters } from '@/ducks/filters/selectors';
import { getFiltersOptions } from '@/ducks/filtersOptions';
import { getSearchParams } from '@/ducks/routes/history';
import { fetchVoyageListData } from '@/helpers/api/app';
import { format, parse } from '@/helpers/util/dateUtil';
import { checkForValueType, getBookingSource } from '@/helpers/util/misc';
import { getSessionStorageValue } from '@/helpers/util/storage';

import { NEW_CHOOSE_VOYAGE_SEARCH_API_SUCCESS, NEW_GENERIC_CATEGORY_CODE_DATA } from '../actionTypes';
import { getSailingsFromPackages } from '../getters';
import { sortPackageSailings, sortSailings } from '../utility';

const getDefaultPayload = (state) => {
  const filterOptions = getFiltersOptions(state);
  const currencyCode = selectDefaultCurrency(state);
  const defaultPackageCodes = selectDefaultPackageCodes(state);
  return {
    sailingsGroupByPackage: true,
    searchQualifier: {
      accessKeys: [],
      cabins: [
        {
          guestCounts: [
            {
              ageCategory: 'Adult',
              count: filterOptions.defaultFilters.sailors,
            },
          ],
        },
      ],
      currencyCode,
      defaultPackageCodes,
      preferences: [],
      sailingDateRange: [
        {
          // FIXME: to be kept until filter cleanup
          end: getSessionStorageValue('defaultEndDate') || filterOptions.defaultEndDate,
          start: getSessionStorageValue('defaultStartDate') || filterOptions.defaultStartDate,
        },
      ],
    },
  };
};

// Since this function can be called after some "await", then need to pass "getState" instead "state" to get the most recent state
const buildPayload = (getState, searchParams, filters) => {
  const state = getState();
  const defaultPackageCodes = selectDefaultPackageCodes(state) || [];
  const currentDate = format(new Date(), 'yyyy-MM-dd');
  const currentEndDate = format(lastDayOfMonth(addMonths(parse(currentDate), 2)));
  const lockitinrateblitz = getSessionStorageValue('lockitinrateblitz');
  const { cabinType = null, fromDate: searchParamsFromDate, toDate: searchParamsToDate } = searchParams;
  let preferencesValue = [];
  if (lockitinrateblitz && cabinType && (cabinType === 'TZ' || cabinType === 'IZ')) {
    preferencesValue = [
      {
        categoryCode: cabinType,
      },
    ];
  } else {
    preferencesValue = filters.cabinType ? [{ metaCode: filters.cabinType }] : [];
  }

  let endDate = searchParamsToDate || filters.toDate;

  if (!isValid(new Date(endDate))) {
    endDate = getSessionStorageValue('defaultEndDate');
  }

  let startDate = searchParamsFromDate || filters.fromDate;

  if (!isValid(new Date(startDate))) {
    startDate = getSessionStorageValue('defaultStartDate');
  }

  const currencyCode = selectCurrencyCode(state) || selectDefaultCurrency(state) || 'USD';

  const payload = {
    sailingsGroupByPackage: true,
    searchQualifier: {
      accessKeys: filters.accessKeys || [],
      cabins: [
        {
          guestCounts: [
            {
              ageCategory: 'Adult',
              count: parseInt(filters.sailors, 10) || 2,
            },
          ],
        },
      ],
      currencyCode,
      defaultPackageCodes,
      preferences: preferencesValue,
      sailingDateRange: [
        {
          end: endDate || currentEndDate,
          start: startDate || currentDate,
        },
      ],
    },
    ...getBookingSource(),
  };

  if (isEqual(payload, getDefaultPayload(state))) {
    payload.searchQualifier.isDefaultSearch = true;
  }
  return payload;
};

export const fetchVoyageData = async (voyageDataPayload) => fetchVoyageListData(voyageDataPayload);

export const fetchPackagesAndSailings = () => async (dispatch, getState) => {
  const filters = selectFilters(getState());
  const parsed = getSearchParams();

  const results = await fetchVoyageData(buildPayload(getState, parsed, filters));
  if (results?.defaultPackages) {
    results.defaultPackages = sortSailings(results.defaultPackages, 'date-asc');
  }

  if (results?.packages) {
    results.sailings = sortPackageSailings(getSailingsFromPackages(results.packages), 'date');
  }

  dispatch(setCruiseCommonPackagesData(results));

  return results;
};

export const fetchPackages = () => async (dispatch, getState) => {
  const state = getState();
  const parsed = getSearchParams();

  const accessKey = getAccessKey(state)?.promoCode || (await dispatch(checkPromoAndValidateAccessKey()));
  const filters = { ...selectFilters(state), accessKeys: accessKey ? [accessKey] : [] };
  const results = await fetchVoyageData(buildPayload(getState, parsed, filters));

  if (results.defaultPackages) {
    results.defaultPackages = sortSailings(results.defaultPackages, 'date-asc');
  }

  if (checkForValueType(results)) {
    const { packages } = results;
    const sailings = getSailingsFromPackages(packages);
    if (checkForValueType(sailings)) {
      results.sailings = sailings;
    }
  }

  if (filters.cabinType === null || !isEmpty(parsed.metaType)) {
    dispatch({
      payload: {
        defaultGenericCategoryCodes: results.defaultGenericCategoryCodes,
        genericCategoryCodes: results.genericCategoryCodes,
      },
      type: NEW_GENERIC_CATEGORY_CODE_DATA,
    });
  }

  if (results && results.packages) {
    const filteredResults = {
      ...results,
      sailings: results.sailings,
    };
    dispatch({
      payload: filteredResults,
      type: NEW_CHOOSE_VOYAGE_SEARCH_API_SUCCESS,
    });
  }
};

const checkPromoAndValidateAccessKey = () => async (dispatch) => {
  const data = await dispatch(checkURLAccessKeyAction());
  return data?.promoCode;
};

import every from 'lodash/every';
import map from 'lodash/map';

import { selectNodes, selectPackages, selectPorts, selectRegions } from '@/ducks/filters/selectors';
import { getClassifications, getRegions } from '@/ducks/filtersOptions';
import { checkForValueType } from '@/helpers/util';

import {
  getFilteredPackagesDownToRegions,
  getPresentPackages,
  getPresentPorts,
  getSailingsFromPackages,
} from './getters';

export const DESTINATION_TYPE = {
  ITINERARIES: 'itineraries',
  PORTS_OF_CALL: 'ports',
};

/*
 * Amend packages / ports / nodes arrays helper
 * - add price and duration info from sailingSearch for packages and nodes
 * - add 'applied' flag based on current filters
 * - add 'present' flag based on presence in current search results
 * - sort by navigationOrder
 * @param itineraries[] - array of itineraries: packages / ports / classification nodes
 * @param filters[] - current filters, array of applied IDs
 * @param searchResults - object of shape itineraryId -> { additional info }
 */
const amendItineraries = (itineraries, filters, searchResults, isSelectedRegion, isFilterNew) =>
  map(itineraries, (itinerary) => {
    const uniqField = itinerary?.id || itinerary?.code;
    return {
      ...itinerary,
      ...(searchResults[uniqField] || {}),
      applied: !isFilterNew ? filters.includes(uniqField) : isSelectedRegion && isSelectedRegion.includes(uniqField),
      present: !!searchResults[uniqField],
    };
  }).sort((a, b) => a.navigationOrder - b.navigationOrder);

const amendPortsOFCall = (itineraries, filters, searchResults) => {
  const key = Object.keys(itineraries);
  let portsOfCall = {};

  key.map((country) => {
    const result = itineraries[country].map((port) => ({
      ...port,
      ...(searchResults[port.code] || {}),
      applied: filters.includes(port.code),
      present: !!searchResults[port.code],
    }));
    portsOfCall = {
      ...portsOfCall,
      [country]: result,
    };
    return null;
  });

  return portsOfCall;
};

// Amend region object with convenience UI flags and structures
const amendRegion = (state, region) => {
  const selectedRegions = selectRegions(state);
  const selectedPackages = selectPackages(state);
  const selectedPorts = selectPorts(state);
  const isSelectedRegion = selectedRegions?.find((selctedRgn) => region.id === selctedRgn.regionId);
  const isFilterNew = true;
  const filteredRegionPackages = region.packages.filter((pckg) => pckg?.id);
  const packages = amendItineraries(
    filteredRegionPackages,
    selectedPackages,
    getPresentPackages(state),
    isSelectedRegion ? isSelectedRegion.packages : [],
    isFilterNew,
  );
  const ports = amendItineraries(
    region.ports,
    selectedPorts,
    getPresentPorts(state),
    isSelectedRegion ? isSelectedRegion.ports : [],
    isFilterNew,
  );
  const portsOfCall = amendPortsOFCall(region.portsOfCall, selectedPorts, getPresentPorts(state));
  // particular region packages and ports which are present in filters
  const appliedPackages = packages.reduce((acc, pkg) => (pkg.applied ? [...acc, pkg.id] : acc), []);
  const appliedPorts = ports.reduce((acc, port) => (port.applied ? [...acc, port.code] : acc), []);

  return {
    ...region,
    appliedPackages,
    appliedPorts,
    // UI flags
    isApplied: appliedPackages.length || appliedPorts.length,
    isWholeApplied: appliedPackages.length === packages.length,
    isWholeDisabled: every(packages, ['present', false]) && every(packages, ['applied', false]),
    isWholePortsApplied: appliedPorts.length === ports.length,
    isWholePortsDisabled: every(ports, ['present', false]) && every(ports, ['applied', false]),
    packages,
    ports,
    portsOfCall,
    tabApplied: appliedPorts.length ? DESTINATION_TYPE.PORTS_OF_CALL : DESTINATION_TYPE.ITINERARIES, // which tab should be active when popover opens
    type: 'region', // explicitly mark type
  };
};

// Get object of shape <node-id> => { lowest sailing price, duration }
export const getPresentNodes = (state) => {
  const packages = getFilteredPackagesDownToRegions(state);
  const sailings = getSailingsFromPackages(packages);
  // Build object of form node id => { lowest sailing price, duration }
  if (!checkForValueType(sailings)) {
    return [];
  }
  return sailings.reduce((acc, sailing) => {
    if ('classificationCodes' in sailing) {
      sailing.classificationCodes.forEach((id) => {
        const data = { duration: sailing.duration, startingPrice: sailing.startingPrice };
        if (!acc[id] || acc[id].startingPrice.amount > data.startingPrice.amount) {
          acc[id] = data;
        }
      });
    }
    return acc;
  }, {});
};

// Amend classification object with convenience UI flags and structures
const amendClassification = (state, classification) => {
  const filterNodes = selectNodes(state);
  const packages = amendItineraries(classification.nodes, filterNodes, getPresentNodes(state));

  // particular region packages and ports which are present in filters
  const appliedPackages = packages.reduce((acc, pkg) => (pkg.applied ? [...acc, pkg.id] : acc), []);

  return {
    ...classification,
    appliedPackages,
    // rename "nodes" to "packages" and add empty "ports" array
    // so that classification and region structures are uniform
    appliedPorts: [],
    // UI flags
    isApplied: appliedPackages.length,
    isWholeApplied: appliedPackages.length === packages.length,
    isWholeDisabled: every(packages, ['present', false]) && every(packages, ['applied', false]),
    isWholePortsApplied: false,
    // and can be used interchangeably in ItineraryFilter component
    packages,
    ports: [],
    tabApplied: DESTINATION_TYPE.ITINERARIES, // there's only one tab for classifications
    type: 'classification', // explicitly mark type
  };
};

/*
 * getItineraryFilters() selector for building filters data for UI filter components
 * There are two types of itinerary filters: regions and classifications
 * We amend them with flags and utility arrays for ease of use in ItineraryFilter component
 */
export const getFiltersForPackages = (state) => [
  ...getRegions(state).map((region) => amendRegion(state, region)),
  ...getClassifications(state).map((classification) => amendClassification(state, classification)),
];

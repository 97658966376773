import { debounce } from 'lodash';

import { selectIsCorrectAccessKey } from '@/ducks/accessKeys';
import { resetAppliedFilterLabels } from '@/ducks/appliedfilterLabels/appliedfilterlabel';
import { throwError } from '@/ducks/common';
import { getCookie } from '@/ducks/cookies';
import { initFilters as originalInitFilters, resetFilters } from '@/ducks/filters/filters';
import { selectCurrencyCode } from '@/ducks/filters/selectors';
import { getFiltersSearchQuery } from '@/ducks/filters/util/query';
import { getFiltersOptions } from '@/ducks/filtersOptions';
import { routes } from '@/ducks/routes';
import { getSearchParams } from '@/ducks/routes/history';

import {
  NEW_CHOOSE_VOYAGE_SEARCH_FAILURE,
  NEW_CHOOSE_VOYAGE_SEARCH_START,
  NEW_CHOOSE_VOYAGE_SEARCH_SUCCESS,
  NEW_CHOOSE_VOYAGE_SET_LOADING,
  SET_VOYAGE_INVALID,
} from '../actionTypes';
import { getFilteredPackages, getSailingsFromPackages } from '../getters';
import { fetchPackages } from './fetchPackages';

const APPLY_FILTERS_DEBOUNCE_TIME = 500;

const applyFiltersAction = async ({ currencyCode = 'USD', dispatch, fetch = true, getState, isCurrency = false }) => {
  dispatch({ type: NEW_CHOOSE_VOYAGE_SEARCH_START });
  try {
    const { defaultEndDate, defaultStartDate } = getFiltersOptions(getState());
    if (fetch && defaultStartDate && defaultEndDate) {
      dispatch({ payload: true, type: NEW_CHOOSE_VOYAGE_SET_LOADING });

      await applyFiltersInSearchParams({ currencyCode, isCurrency, showProgressBar: fetch, state: getState() });
      await dispatch(fetchPackages());

      dispatch({ payload: false, type: NEW_CHOOSE_VOYAGE_SET_LOADING });
    }

    const filteredPackages = getFilteredPackages(getState());
    let data = { packages: filteredPackages };
    const sailings = getSailingsFromPackages(filteredPackages);
    if (sailings.length) {
      data = { ...data, sailings };
    }
    dispatch({ payload: data, type: NEW_CHOOSE_VOYAGE_SEARCH_SUCCESS });
  } catch (e) {
    dispatch({ type: NEW_CHOOSE_VOYAGE_SEARCH_FAILURE });
    dispatch(throwError(e));
  }

  const updatedCurrency = selectCurrencyCode(getState());
  const isCurrencyFlag = updatedCurrency !== currencyCode;
  applyFiltersInSearchParams({
    currencyCode: updatedCurrency,
    isCurrency: isCurrencyFlag,
    showProgressBar: fetch,
    state: getState(),
  });
};

const debouncedApplyFilters = debounce(applyFiltersAction, APPLY_FILTERS_DEBOUNCE_TIME);
export const applyFilters =
  (applyFiltersArgs = {}) =>
  async (dispatch, getState) =>
    (applyFiltersArgs?.fetch ? debouncedApplyFilters : applyFiltersAction)({ ...applyFiltersArgs, dispatch, getState });

export const applyFiltersInSearchParams = ({
  currencyCode = 'USD',
  isCurrency = false,
  showProgressBar,
  state,
} = {}) => {
  const isAccessKeyValid = selectIsCorrectAccessKey(state);
  if (isCurrency || isAccessKeyValid) {
    return routes.search(getFiltersSearchQuery(state, { currencyCode }, { isAccessKeyValid }), {
      nprogress: { showProgressBar },
    });
  } else {
    return routes.search(getFiltersSearchQuery(state), {
      nprogress: { showProgressBar },
    });
  }
};

export const newInitFilters =
  ({ callVoaygesAPI = false, initFilterArgs = {}, isFilteredCurrency = false } = {}) =>
  async (dispatch, getState) => {
    const fetch = await dispatch(originalInitFilters(initFilterArgs));
    const searchParams = getSearchParams();
    const currency =
      (!isFilteredCurrency && searchParams.currencyCode) ||
      getCookie('currencyCode') ||
      getState()?.filters?.currencyCode;
    await dispatch(applyFilters({ currencyCode: currency, fetch: callVoaygesAPI || fetch, isCurrency: fetch }));
  };

export const resetFiltersForPackages = () => (dispatch) => {
  dispatch(resetFilters());
  dispatch(resetAppliedFilterLabels());

  dispatch(newInitFilters({ initFilterArgs: { useQueryForFilters: false } }));
};

export const setIsVoyageInvalid = (flag) => (dispatch) => {
  dispatch({
    payload: flag,
    type: SET_VOYAGE_INVALID,
  });
};

import { combineReducers } from 'redux';

import sailorData from './sailorData';
import sailorFormUpdate from './sailorFormUpdate';
import travelInsuranceFlyoutData from './travelInsuranceData';

export default combineReducers({
  sailorData,
  sailorFormUpdate,
  travelInsuranceFlyoutData,
});

import { useMemo } from 'react';

import { useAppSelector } from '@/store';

import type { TUIResourceId, TUIResourceImage, TUIResourceNode, TUIResourceValueMap } from './types';

import { makeUIResourcePoolSelector, makeUIResourceSelector } from './selectors';

export const useUIResource = <R extends TUIResourceImage | TUIResourceNode | string = string>(
  resourceId: TUIResourceId,
  values?: TUIResourceValueMap,
): R => {
  const selector = useMemo(
    () => makeUIResourceSelector<R>(resourceId, values),
    [resourceId, ...Object.entries(values || {}).flat()],
  );
  return useAppSelector(selector);
};

export const useUIResourcePool = <TKey extends string, TImageKey extends string = never>(
  resourceIds: Record<TKey, TUIResourceId>,
) => {
  const selector = useMemo(() => makeUIResourcePoolSelector(resourceIds), [resourceIds]);
  return useAppSelector(selector) as Record<Exclude<TKey, TImageKey>, string> & Record<TImageKey, TUIResourceImage>;
};

export const useUIResourceProps = ({ id, values }: { id: TUIResourceId; values?: TUIResourceValueMap }) =>
  useUIResource(id, values);

import get from 'lodash/get';

import { PromoBannerType, type TPromoBannerAny, type TPromoBannerDoubleClickDetails } from '@/ducks/promoBanners/types';

import constants from '../../constants';
import { trackClick } from './common';

const { EVENT_ACTIONS } = constants;

const MAP__BANNER_PER_ACTION = {
  [PromoBannerType.billboard]: {
    action: EVENT_ACTIONS.LINK_CLICK,
    propNamesOrder: ['type', 'cta.label', 'title'],
  },
  [PromoBannerType.double]: {
    action: EVENT_ACTIONS.LINK_CLICK,
    propNamesOrder: ['type', 'cta.label', 'title'],
  },
  [PromoBannerType.featurette]: {
    action: EVENT_ACTIONS.LINK_CLICK,
    propNamesOrder: ['type', 'cta.label', 'title'],
  },
  [PromoBannerType.leaderboard]: {
    action: EVENT_ACTIONS.LINK_CLICK,
    propNamesOrder: ['type', 'cta.label', 'title'],
  },
  [PromoBannerType.marquee]: {
    action: EVENT_ACTIONS.ICON_CLICK,
    propNamesOrder: ['type', 'cta.label', 'title'],
  },
} as Record<PromoBannerType, { action: string; propNamesOrder: string[] }>;

export const onClick = (props?: TPromoBannerAny, clickDetails?: unknown) => {
  if (!props?.type) return () => {};

  const { action, propNamesOrder } = MAP__BANNER_PER_ACTION?.[props.type] || {};
  if (!action) return () => {};
  const propValues = propNamesOrder.map((propToTake) => get(props, propToTake));
  if (props.type === PromoBannerType.double) {
    // double banner specific logic as it creates 2 separate buttons
    const { half } = (clickDetails || {}) as TPromoBannerDoubleClickDetails;
    if (half) {
      propValues[1] = get(
        (props as Record<string, unknown>)?.[half],
        MAP__BANNER_PER_ACTION[PromoBannerType.double].propNamesOrder[1]!,
      );
      propValues[2] = get(
        (props as Record<string, unknown>)?.[half],
        MAP__BANNER_PER_ACTION[PromoBannerType.double].propNamesOrder[2]!,
      );
    }
  }

  return trackClick(action)({ labels: propValues, labelsOptions: undefined });
};

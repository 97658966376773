import { useSearchParams } from 'next/navigation';
import { useCallback } from 'react';

import { createSelector } from '@reduxjs/toolkit';
import isEqual from 'lodash/isEqual';

import { FILTER_NAMES, FILTER_NAMES_TO_FORMAT, FILTER_TYPES, type FILTER_VALUE } from '@/ducks/filters/constants';
import { getSearchParam } from '@/ducks/routes/history';
import { type RootState, useAppSelector } from '@/store';
export const selectDefaultFilters = (state: RootState) => state.filtersOptions?.defaultFilters;
export const selectFilterByName = createSelector(
  [
    selectDefaultFilters,
    (_, filterName: FILTER_TYPES, searchParams?: URLSearchParams) => ({
      filterName,
      searchParams,
    }),
  ],
  (defaultFilters, { filterName, searchParams }) => {
    const searchParamValue = searchParams ? searchParams.get(filterName) : getSearchParam(filterName);
    const convertedValue = parseFilterNames({ filterName, value: searchParamValue });

    const value = isValidValue(searchParamValue) ? convertedValue : defaultFilters?.[filterName];
    return value as FILTER_VALUE;
  },
  { memoizeOptions: { maxSize: Object.keys(FILTER_NAMES).length } },
);

export const useSelectFiltersByName = <T extends FILTER_VALUE[]>(...filterNameList: FILTER_TYPES[]) => {
  const searchParams = useSearchParams();
  const selector = useCallback(
    (state: RootState) => filterNameList.map((filterName) => selectFilterByName(state, filterName, searchParams)),
    [filterNameList, searchParams],
  );
  return useAppSelector(selector, isEqual) as T;
};

const parseFilterNames = <T>({ filterName, value }: { filterName: FILTER_TYPES; value: string }) => {
  const parser = FILTER_NAMES_TO_FORMAT[filterName];
  if (!(typeof parser === 'function')) return value;

  return FILTER_NAMES_TO_FORMAT[filterName]!(value) as T;
};

// add more strict rules
const isValidValue = (value: unknown) => !(value === undefined || value === null || value === '');

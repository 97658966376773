import identity from 'lodash/identity';

import type { RootState } from '@/store';

import { AppAuthenticator, TokenType } from '@/helpers/api/tokens';
import { getAuthFromStorage } from '@/helpers/auth/core';

export const getToken = (type: 'Basic' | 'BearerUser') => (state: RootState) => {
  switch (type) {
    case 'Basic':
    case 'BearerUser':
      return state.auth?.tokens?.[type];
    default:
      return undefined;
  }
};

export const selectIsLoggedIn = (state: RootState) => Boolean(getToken('BearerUser')(state) || getAuthFromStorage());

export const getBestToken = (state: RootState) =>
  Promise.all([
    Promise.resolve(getAuthFromStorage()?.accessToken),
    Promise.resolve(getToken('BearerUser')(state)),
    AppAuthenticator.getInstance().getFreshAccessToken({ tokenType: TokenType.guest }),
    Promise.resolve(getToken('Basic')(state)),
  ]).then((tokens) => tokens.find(identity));

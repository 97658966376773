import identity from 'lodash/identity';

import type { TOptional } from '@/types/common';

import switchListItem from '@/helpers/switchListItem';
import { isPrimaryGuestIsAdultBySailingDate } from '@/helpers/util/dateUtil';

import type { TFieldName, TValidationOptions } from './types';

import { FIELD_NAMES, FIELD_NAMES_LIST } from './constants';
import extractEmailTLD from './helpers/extractEmailTLD';

export const RE_DATE = /^(?:19|20)[0-9]{2}-(?:0[1-9]|1[012])-(?:0[1-9]|[12][0-9]|3[01])$/;

export type TFieldPattern = {
  key: string;
  value: Record<string, string> | string;
};
export type TPatternResolver = (pattern: Record<string, string>, options: TValidationOptions) => string;
export type TSpecialChecker = (value: unknown, options: TValidationOptions) => boolean;

const commonSpecialValidators: Record<string, TSpecialChecker> = {
  [FIELD_NAMES.DOB]: (value, { embarkDate }) =>
    RE_DATE.test(value as string) && isPrimaryGuestIsAdultBySailingDate(embarkDate, value),
  [FIELD_NAMES.EMAIL]: (value, { invalidTLDs, sailorNumber, usedEmails }) => {
    const entries = Object.entries(usedEmails || {});
    // The email value being checked must not match any other email from "usedEmails" collection (except itself)
    if (entries.some(([key, email]) => `${sailorNumber || 0}` !== key && value === email)) return false;
    if (invalidTLDs?.length) {
      const emailTLD = extractEmailTLD(value as string);
      if (emailTLD && invalidTLDs.includes(emailTLD)) return false;
    }
    return true;
  },
};

const primarySailorSpecialValidators: Record<string, TSpecialChecker> = {
  ...commonSpecialValidators,
  [FIELD_NAMES.OVER_18]: identity,
};

const additionalSailorsSpecialValidators: Record<string, TSpecialChecker> = {
  ...commonSpecialValidators,
};

const getSpecialChecker = (name: string, { sailorNumber }: TValidationOptions): TOptional<TSpecialChecker> => {
  const checkers = (sailorNumber || 0) > 0 ? additionalSailorsSpecialValidators : primarySailorSpecialValidators;
  return checkers[name];
};

const patternResolvers: Record<string, TPatternResolver> = {
  [FIELD_NAMES.ZIP_CODE]: (pattern: Record<string, string>, { country }: TValidationOptions) =>
    (pattern[country as string] || pattern.Others) as string,
};

export const ensurePrimarySailorFieldError = ({
  excludedFields,
  fieldPatterns,
  invalidFields,
  name,
  options,
  requiredFields,
  value,
}: TValidateFieldArg): TFieldName[] =>
  validateField({ excludedFields, fieldPatterns, invalidFields, name, options, requiredFields, value });

export const getAllPrimarySailorErrors = ({
  excludedFields,
  fieldPatterns,
  options,
  requiredFields,
  values,
}: TValidateAllFieldsArg): TFieldName[] =>
  validateAllFields({ excludedFields, fieldPatterns, options, requiredFields, values });

export type TValidateFieldArg = {
  excludedFields: TFieldName[];
  fieldPatterns: TFieldPattern[];
  invalidFields: TFieldName[];
  name: TFieldName;
  options: TValidationOptions;
  requiredFields: TFieldName[];
  value: unknown;
};

export const validateField = ({
  excludedFields,
  fieldPatterns,
  invalidFields,
  name,
  options,
  requiredFields,
  value,
}: TValidateFieldArg): TFieldName[] => {
  const isInvalid = isInvalidField({
    excludedFields,
    fieldPatterns,
    name,
    options,
    requiredFields,
    value,
  });
  return switchListItem(invalidFields || [], name, isInvalid);
};

export type TValidateAllFieldsArg = {
  excludedFields: TFieldName[];
  fieldPatterns: TFieldPattern[];
  options: TValidationOptions;
  requiredFields: TFieldName[];
  values: Partial<Record<TFieldName, unknown>>;
};

export const validateAllFields = ({
  excludedFields,
  fieldPatterns,
  options,
  requiredFields,
  values,
}: TValidateAllFieldsArg): TFieldName[] =>
  FIELD_NAMES_LIST.filter((key) => !excludedFields.includes(key)).reduce(
    (invalidFields, name) =>
      validateField({
        excludedFields,
        fieldPatterns,
        invalidFields,
        name,
        options,
        requiredFields,
        value: values?.[name],
      }),
    [] as TFieldName[],
  );

export type TIsInvalidFieldArg = {
  excludedFields: TFieldName[];
  fieldPatterns: TFieldPattern[];
  name: TFieldName;
  options: TValidationOptions;
  requiredFields: TFieldName[];
  value: unknown;
};

export const isInvalidField = ({
  excludedFields,
  fieldPatterns,
  name,
  options,
  requiredFields,
  value,
}: TIsInvalidFieldArg): boolean => {
  if (excludedFields?.includes(name)) {
    return false;
  }
  const isRequired = requiredFields?.includes(name) ?? false;
  if (!value) {
    return isRequired;
  }
  const pattern = findFieldPattern(fieldPatterns, name, options);
  if (pattern && !new RegExp(pattern).test((value as string) || '')) {
    return true;
  }
  const specialChecker = getSpecialChecker(name, options);
  return !!specialChecker && !specialChecker(value, options);
};

export const findFieldPattern = (
  fieldPatterns: TFieldPattern[],
  name: TFieldName,
  options: TValidationOptions,
): string | undefined => {
  const { value: pattern } = fieldPatterns?.find(({ key }) => key === name) || ({} as TFieldPattern);
  return pattern ? getPattern(pattern, name, options) : undefined;
};

export const getPattern = (
  pattern: TOptional<TFieldPattern['value']>,
  name: TFieldName,
  options: TValidationOptions,
): string | undefined => {
  if (pattern) return typeof pattern === 'string' ? pattern : patternResolvers[name]?.(pattern, options);
};

import { createSelector } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';

import { selectFilters } from '@/ducks/filters/selectors';
import { getDefaultFilters } from '@/ducks/filtersOptions/selectors';

const parseNumberOrZero = (value: null | number | string) => (value && Number.isFinite(+value) ? +value : 0);

export const selectAppliedFilterLabelsCount = createSelector(
  selectFilters,
  getDefaultFilters,
  (filters, defaultFilters) => {
    const filtersChanged = [
      filters.accessible === true,
      filters.cabinType,
      !isEmpty(filters.durations),
      !isEmpty(filters.homePorts),
      !isEmpty(filters.shipList),
      filters.cabins !== defaultFilters.cabins,
      filters.sailors !== defaultFilters.sailors,
      filters.weekend === true,
      parseNumberOrZero(filters.minPrice) !== 0,
      parseNumberOrZero(filters.maxPrice) !== 0,
      filters.priceType !== defaultFilters.priceType,
    ];
    return filtersChanged.filter(Boolean).length;
  },
);

import {
  SAILOR_DETAILS_UPDATE_BOOKING_PRICE_SUCCESS,
  SUMMARY_CALCULATE_INVOICE_CLEAR,
  SUMMARY_CALCULATE_INVOICE_ERROR,
  SUMMARY_CALCULATE_INVOICE_START,
  SUMMARY_CALCULATE_INVOICE_SUCCESS,
} from '@/constants/actionTypes';

const defaultState = {
  data: {},
  error: {},
  isLoaded: false,
  isLoading: false,
};

export default function calculateInvoiceReducer(store = defaultState, action) {
  switch (action.type) {
    case SUMMARY_CALCULATE_INVOICE_CLEAR:
      return defaultState;
    case SUMMARY_CALCULATE_INVOICE_START:
      return { ...store.data, isLoading: true };
    case SUMMARY_CALCULATE_INVOICE_SUCCESS:
      return { data: action.payload, error: {}, isLoaded: true, isLoading: false };
    case SUMMARY_CALCULATE_INVOICE_ERROR:
      return { data: {}, error: action.payload, isLoading: false };
    case SAILOR_DETAILS_UPDATE_BOOKING_PRICE_SUCCESS: {
      const { payload } = action;
      return {
        data: {
          ...store.data,
          cabinInvoices: [
            {
              ...store.data.cabinInvoices[0],
              bookingPriceDetails: {
                ...store.data.cabinInvoices[0].bookingPriceDetails,
                amount: payload,
              },
            },
          ],
        },
        error: {},
        isLoading: false,
      };
    }
    default:
      return store;
  }
}

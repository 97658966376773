// Constants
export const CHOOSE_VOYAGE_SEARCH_START = '@@chooseVoyage/CHOOSE_VOYAGE_SEARCH_START';
export const CHOOSE_VOYAGE_SEARCH_SUCCESS = '@@chooseVoyage/CHOOSE_VOYAGE_SEARCH_SUCCESS';
export const CHOOSE_VOYAGE_SEARCH_FAILURE = '@@chooseVoyage/CHOOSE_VOYAGE_SEARCH_FAILURE';
export const CHOOSE_VOYAGE_SEARCH_API_SUCCESS = '@@chooseVoyage/CHOOSE_VOYAGE_SEARCH_API_SUCCESS';

export const CHOOSE_VOYAGE_ADD_TRACKED_SAILINGS = '@@chooseVoyage/CHOOSE_VOYAGE_ADD_TRACKED_SAILINGS';
export const CHOOSE_VOYAGE_CLEAR_TRACKED_SAILINGS = '@@chooseVoyage/CHOOSE_VOYAGE_CLEAR_TRACKED_SAILINGS';

export const GENERIC_CATEGORY_CODE_DATA = '@@GENERIC_CATEGORY_CODE_DATA';

const defaultState = {
  error: null,
  genericCategoryCode: [],
  loading: false,
  rawSailings: {
    // raw sailings and packages from API search request; set by sailingSearch action
    packages: [],
    sailings: [],
  },
  sailings: [], // filtered sailings array after applying frontend filters
  trackedSailings: [], // list of voyages which have been tracked by trackVoyageView() action
};

// Reducers
export default function chooseVoyageReducer(state = defaultState, action) {
  switch (action.type) {
    case CHOOSE_VOYAGE_SEARCH_START:
      return { ...state, error: false, loading: true };
    case CHOOSE_VOYAGE_SEARCH_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
        sailings: action.payload,
        trackedSailings: [], // Clear tracked sailings upon search results update
      };
    case CHOOSE_VOYAGE_SEARCH_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
        sailings: [],
      };
    case CHOOSE_VOYAGE_ADD_TRACKED_SAILINGS:
      return {
        ...state,
        trackedSailings: [...state.trackedSailings, ...action.payload],
      };
    case CHOOSE_VOYAGE_CLEAR_TRACKED_SAILINGS:
      return {
        ...state,
        trackedSailings: [],
      };
    case CHOOSE_VOYAGE_SEARCH_API_SUCCESS:
      return { ...state, rawSailings: action.payload };
    case GENERIC_CATEGORY_CODE_DATA:
      return { ...state, genericCategoryCode: action.payload };
    default:
      return state;
  }
}

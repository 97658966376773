/* eslint-disable import/no-cycle */
import kebabCase from 'lodash/kebabCase';

import { resetAppliedFilterLabels } from '@/ducks/appliedfilterLabels';
import * as appliedSetter from '@/ducks/appliedfilterLabels/setter';
import { resetFilters as originalResetFilters, setResetAll } from '@/ducks/filters';
import * as setters from '@/ducks/filters/setters';
import { getCabinTypeName, getDurationDays } from '@/ducks/filtersOptions';
import { applyFilters } from '@/ducks/pages/chooseVoyage/actions';
import { DESTINATION_TYPE } from '@/ducks/pages/chooseVoyage/destinationSelectors';
import tagmanager from '@/tagmanager';
import getPage from '@/tagmanager/routesMapping';

import { CHOOSE_VOYAGE_SEARCH_SUCCESS } from '../reducer';
import { getFilteredSailings } from '../sailingSearch';
import { getItineraryFilters } from '../selectors';

export const resetFilters = () => (dispatch) => {
  dispatch(setResetAll(true));
  dispatch(originalResetFilters());
  dispatch(resetAppliedFilterLabels());
  dispatch(applyFilters());
};

export const setSailors = (sailors) => (dispatch) => {
  dispatch(setters.setSailors(sailors));
  dispatch(applyFilters({ trackLabel: `sailors-number|${sailors}` }));
};

export const setCabinType =
  (cabinType, isFromAdvFilter = false) =>
  (dispatch, getState) => {
    let trackLabel;
    if (cabinType) {
      const cabinTypeName = getCabinTypeName(getState(), cabinType);
      trackLabel = `cabin-type|${kebabCase(cabinTypeName)}`;
    }

    dispatch(setters.setCabinType(cabinType));
    dispatch(
      applyFilters({
        isFromAdvFilter,
        trackLabel,
      }),
    );
  };

export const setDurations =
  (durations, weekend, isFromAdvFilter = false) =>
  (dispatch) => {
    const durationDays = getDurationDays(durations);
    dispatch(setters.setDurations(durations, weekend));
    dispatch(
      applyFilters({
        fetch: false,
        isFromAdvFilter,
        trackLabel: `number-of-nights|${durationDays.join('-')}_weekend-voyages-only|${weekend}`,
      }),
    );
  };

export const setPriceRange =
  (priceRange, shouldApplyFilters = true) =>
  (dispatch) => {
    dispatch(setters.setPriceRange(priceRange));
    if (shouldApplyFilters) {
      dispatch(
        applyFilters({
          fetch: false,
        }),
      );
    }
  };

export const setSelectedShips = (shipList) => (dispatch) => {
  const trackLable = [];
  // eslint-disable-next-line
  for (let i = 0; i <= shipList.length; i++) {
    if (shipList[i]) {
      trackLable.push(kebabCase(shipList[i].name));
    }
  }
  dispatch(setters.setSelectedShips({ shipList }));
  dispatch(
    applyFilters({
      fetch: false,
      isFromAdvFilter: true,
      trackLabel: `ship|${trackLable.join('-')}`,
    }),
  );
};

export const setDeparturePorts = (homePorts) => (dispatch) => {
  const trackLable = [];
  // eslint-disable-next-line
  for (let i = 0; i <= homePorts.length; i++) {
    if (homePorts[i]) {
      trackLable.push(kebabCase(homePorts[i].name));
    }
  }
  dispatch(setters.setDeparturePorts({ homePorts }));
  dispatch(
    applyFilters({
      fetch: false,
      isFromAdvFilter: true,
      trackLabel: `departure-port|${trackLable.join('-')}`,
    }),
  );
};

export const setAccessible = (accessible) => (dispatch) => {
  dispatch(setters.setAccessible(accessible));
  dispatch(
    applyFilters({
      fetch: false,
      trackLabel: `ADA|${accessible}`,
    }),
  );
};

export const setCurrencyCode =
  (currencyCode, shouldApplyFilters = true) =>
  async (dispatch) => {
    dispatch(setters.setCurrencyCode(currencyCode, shouldApplyFilters));
  };

export const setSortType = (sortType) => (dispatch) => {
  dispatch(setters.setSortType(sortType));
  dispatch(
    applyFilters({
      fetch: false,
      trackLabel: `sort-by|${sortType}`,
    }),
  );
};

export const setPriceType = (priceType, isFirstLoad) => (dispatch) => {
  dispatch(setters.setPriceType(priceType));
  dispatch(
    applyFilters({
      fetch: false,
      isFirstTime: isFirstLoad,
      trackLabel: `price-by|${priceType.replace('per', '').toLowerCase()}`,
    }),
  );
};

export const applyFilterForPromotion = (promotionId) => (dispatch) => {
  dispatch(applyFilters({ promotionId }));
};

export const setAppliedFilterLabels = (labels) => (dispatch) => {
  dispatch(appliedSetter.setFilterLabel(labels));
};

export const resetAppliedFilterLabel = (label) => (dispatch) => {
  dispatch(appliedSetter.resetFilterLabel(label));
};

export const sortRegions = (itineraryFilters) => itineraryFilters.sort((a, b) => (a.name < b.name ? -1 : 1));

export const setTrackingDestinationFilter = (isWholeSelected) => (dispatch, getState) => {
  const page = getPage();
  const sailings = getFilteredSailings(getState());
  dispatch({ payload: sailings, type: CHOOSE_VOYAGE_SEARCH_SUCCESS });

  const itineraryFilters = getItineraryFilters(getState());
  const trackingLabel = [];

  if (itineraryFilters) {
    sortRegions(itineraryFilters).map((data) => {
      const { appliedPackages, appliedPorts, id, name } = data;
      if (appliedPackages.length > 0 && appliedPorts.length <= 0) {
        trackingLabel.push(
          `${kebabCase(name)}(${isWholeSelected(id, DESTINATION_TYPE.ITINERARIES) ? 'all' : appliedPackages.length})`,
        );
      } else if (appliedPorts.length > 0 && appliedPackages.length <= 0) {
        trackingLabel.push(
          `${kebabCase(name)}(${isWholeSelected(id, DESTINATION_TYPE.PORTS_OF_CALL) ? 'all' : appliedPorts.length})`,
        );
      }
      return null;
    });
  }

  if (trackingLabel.length > 0) {
    tagmanager.trackEvent({
      event: 'GTMevent',
      eventAction: 'filter-applied',
      eventCategory: `VV-${page}`,
      eventLabel: `region|${trackingLabel.join('-')}`,
      number_of_filtering_results: sailings.length,
    });
  } else {
    tagmanager.trackEvent({
      event: 'GTMevent',
      eventAction: 'filter-applied',
      eventCategory: `VV-${page}`,
      eventLabel: 'region',
      number_of_filtering_results: sailings.length,
    });
  }
};

export const setSelectedRegionIds = (selectedRegionIds) => (dispatch) => {
  dispatch(setters.setSelectedRegionIds(selectedRegionIds));
};

export const setPackgesInFilter = (packages) => (dispatch) => {
  dispatch(setters.setFilterPackges(packages));
};

export const setPortsInFilter = (ports) => (dispatch) => {
  dispatch(setters.setFilterPorts(ports));
};

import {
  trackButtonClick,
  trackCheckboxToggle,
  trackIconClick,
  trackLinkClick,
  trackPopupState,
} from '@/tagmanager/event/types/common';

import constants from '../../constants';
import { createTracker } from '../../tracker';
import builders from '../builders';
import * as voyagesFilter from './voyagesFilter';

const trackEvent = createTracker({
  event: constants.EVENTS.GTMevent,
});

export const trackModifyCabinClick = (label) => {
  trackButtonClick({
    labels: [label],
  });
};

export const trackAddonPlusClick = (label) =>
  trackIconClick({
    labels: ['plus', label],
  });

export const trackAddonFlyoutClose = () =>
  trackPopupState({
    isOpen: false,
    module: constants.FLYOUTS.VOYAGE_PROTECTION,
  });

export const trackApplyFilterTravelParty = (sailorsCount) => {
  voyagesFilter.trackApplyFilter({
    name: constants.EVENT_LABELS.VOYAGE_FILTERS.FILTER_NAMES.TRAVEL_PARTY,
    values: [sailorsCount],
  });
};

export const trackApplyDiscountClick = (label) => {
  trackButtonClick({
    labels: [`${label}-discount`],
  });
};

export const trackToSailorDetailsClick = (label) => {
  trackButtonClick({
    labels: [label],
  });
};

export const trackOpenFlyoutFacilityBlock = (label) => {
  trackIconClick({
    labels: ['plus', label],
    module: constants.FLYOUTS.VOYAGE_PROTECTION,
  });
};

export const trackAuthSignInUpClick = (label) => {
  trackButtonClick({
    labels: [label],
  });
};

export const trackAuthSignInUp = ({ loginType, socialType, userId }) => {
  const transformedLoginType = loginType === 'signup' ? 'create' : 'sign-in';
  const transformedSocialType = socialType || 'email';

  trackEvent({
    action: transformedLoginType,
    label: transformedSocialType,
    module: constants.FLYOUTS.ACCOUNTS_WITH_BENEFITS,
    sailor_id: userId,
  });
};

export const trackAuthManualModeClick = (label) => {
  trackLinkClick({
    labels: [label],
  });
};

export const trackMobileOptInToggle = (state) => {
  trackCheckboxToggle({
    labels: [constants.EVENT_LABELS.MOBILE_OPT_IN],
    state,
  });
};

export const trackOpenFareBreakdown = (label) => {
  trackButtonClick({
    labels: [label],
  });
};

export const trackPayTypeSelecting = (label) => {
  trackEvent({
    action: constants.EVENT_ACTIONS.PAY_TYPE_SELECTION,
    label: label?.trim(),
  });
};

export const trackOnCheckoutClick = (label) => {
  trackButtonClick({
    labels: [label],
  });
};

export const trackAuthSignInUpPopupStateToggle = (state) => {
  if (!state) {
    return;
  }

  trackPopupState({
    isOpen: state,
    module: constants.FLYOUTS.ACCOUNTS_WITH_BENEFITS,
  });
};

export const trackAccessKeyAutoApplied = (accessKey) => {
  const buildedLabel = builders.buildLabel([constants.EVENT_LABELS.ACCESS_KEY, accessKey]);

  trackEvent({
    action: constants.EVENT_ACTIONS.AUTO_APPLIED,
    label: buildedLabel,
  });
};

export const trackAccessKeyFieldClick = () => {
  trackEvent({
    action: constants.EVENT_ACTIONS.FIELD_CLICK,
    label: constants.EVENT_LABELS.ACCESS_KEY,
  });
};

export const trackAccessKeyValidateClick = (label) => {
  trackLinkClick({
    labels: [label],
  });
};

export const trackAccessKeyError = (errorLabel) => {
  const buildedLabel = builders.buildLabel([constants.EVENT_LABELS.ACCESS_KEY, errorLabel]);
  trackEvent({
    action: constants.EVENT_ACTIONS.ERROR,
    label: buildedLabel,
  });
};

export const trackAccessKeyApplied = () => {
  trackEvent({
    action: constants.EVENT_ACTIONS.FORM_COMPLETION,
    label: constants.EVENT_LABELS.ACCESS_KEY_APPLIED,
  });
};

export const trackAccessKeyClear = (label) => {
  trackIconClick({
    labels: [label],
  });
};

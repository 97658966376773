/**
 * sailingSearch duck is responsible for
 * - requesting list of sailings from server - removed as part of MSH-82010
 * - storing results of API request
 * - filtering sailings with frontend filters
 * - finding present properties of sailings in list: ports, nodes, durations, etc.
 */
/* eslint-disable import/no-cycle */
export * from './filterSailings';
export * from './selectors';

import toString from 'lodash/toString';

/* eslint-disable perfectionist/sort-objects */
export enum FILTER_TYPES {
  accessible = 'accessible',
  cabinType = 'cabinType',
  cabins = 'cabins',
  currencyCode = 'currencyCode',
  durations = 'durations',
  fromDate = 'fromDate',
  fromPorts = 'fromPorts',
  maxPrice = 'maxPrice',
  minPrice = 'minPrice',
  priceType = 'priceType',
  sailors = 'sailors',
  ships = 'ships',
  sortType = 'sortType',
  toDate = 'toDate',
  toPorts = 'toPorts',
  weekend = 'weekend',
}

export type FILTER_VALUE = boolean | null | number | string | string[];
export type FILTER_NAMES_KEYS = keyof typeof FILTER_NAMES;

export const FILTER_NAMES = {
  ACCESSIBILITY: FILTER_TYPES.accessible,
  CABIN_TYPE: FILTER_TYPES.cabinType,
  DURATIONS: FILTER_TYPES.durations,
  FROM_PORTS: FILTER_TYPES.fromPorts,
  TO_PORTS: FILTER_TYPES.toPorts,
  MAX_PRICE: FILTER_TYPES.maxPrice,
  MIN_PRICE: FILTER_TYPES.minPrice,
  SHIPS: FILTER_TYPES.ships,
  SAILORS: FILTER_TYPES.sailors,
  CABINS: FILTER_TYPES.cabins,
  CURRENCY_CODE: FILTER_TYPES.currencyCode,
  WEEKEND: FILTER_TYPES.weekend,
  TO_DATE: FILTER_TYPES.toDate,
  FROM_DATE: FILTER_TYPES.fromDate,
  PRICE_TYPE: FILTER_TYPES.priceType,
};

export const FILTER_NAMES_TO_FORMAT = {
  // use lib
  [FILTER_TYPES.accessible]: Boolean,
  [FILTER_TYPES.cabinType]: toString,
  [FILTER_TYPES.durations]: null, //'number[]',
  [FILTER_TYPES.fromPorts]: null, //'string[]',
  [FILTER_TYPES.toPorts]: null, //'string[]',
  [FILTER_TYPES.maxPrice]: parseInt,
  [FILTER_TYPES.minPrice]: parseInt,
  [FILTER_TYPES.ships]: null, //'string[]',
  [FILTER_TYPES.sailors]: parseInt,
  [FILTER_TYPES.cabins]: parseInt,
  [FILTER_TYPES.currencyCode]: toString,
  [FILTER_TYPES.weekend]: Boolean,
  [FILTER_TYPES.toDate]: null, //'string',
  [FILTER_TYPES.fromDate]: null, //'string',
  [FILTER_TYPES.priceType]: toString,
  [FILTER_TYPES.sortType]: toString,
};

export const TITLE_FILTER_TYPE_MAPPING = {
  [FILTER_NAMES.SAILORS]: 'AdvancedFilter.TravelParty.heading',
  [FILTER_NAMES.CABIN_TYPE]: 'AdvancedFilter.CabinType',
  [FILTER_NAMES.DURATIONS]: 'AdvancedFilter.Duration',
  [FILTER_NAMES.MIN_PRICE]: 'AdvancedFilter.PriceRange',
  [FILTER_NAMES.FROM_PORTS]: 'AdvancedFilter.DeparturePort',
  [FILTER_NAMES.SHIPS]: 'AdvancedFilter.Ship',
};

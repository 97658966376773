import {
  CLEAR_SUMMARY_SAILING_DATA,
  SUMMARY_SAILING_DATA_ERROR,
  SUMMARY_SAILING_DATA_SUCCESS,
} from '@/constants/actionTypes';

const defaultState = {
  data: {},
  error: {},
  isLoaded: false,
};

export default function sailingDataReducer(store = defaultState, action) {
  switch (action.type) {
    case SUMMARY_SAILING_DATA_SUCCESS:
      return { ...store, data: action.payload, error: {}, isLoaded: true };
    case CLEAR_SUMMARY_SAILING_DATA:
      return defaultState;
    case SUMMARY_SAILING_DATA_ERROR:
      return { ...store, data: {}, error: action.payload };
    default:
      return store;
  }
}

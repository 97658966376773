import set from 'lodash/set';

const getAddress = (address) => {
  if (!address) {
    return {};
  }

  const { aptNo, city, country, countryCode, lineOne, lineTwo, postalCode, stateCode, streetAddress, zipCode } =
    address;
  const addressObject = {
    city,
    countryCode: countryCode || country,
    lineOne: lineOne || streetAddress,
    lineTwo: lineTwo || aptNo,
    stateCode,
    zipCode: zipCode || postalCode,
  };
  return Object.keys(addressObject).reduce((acc, cur) => {
    const value = addressObject[cur];
    if (!value) {
      return acc;
    }

    return { ...acc, [cur]: value };
  }, {});
};

export default (bookNowParams, sailorAddressFVC, reservationNumber) => {
  const booknowBody = {};
  const addressObj = getAddress(sailorAddressFVC);
  Object.assign(booknowBody, bookNowParams);
  booknowBody.bookingNumber = reservationNumber;
  set(booknowBody.cabins[0].sailors[0], 'address', addressObj);
  return booknowBody;
};

import { env } from '@/environment';
import { AppAuthenticator, TokenType } from '@/helpers/api/tokens';
import sharedCall from '@/helpers/sharedCall';

import { getStoredValue } from '../util/storage';
import { api } from './core';
import buildBookNowBody from './mappers/req/BookNow';
import buildCabinCategoriesReq from './mappers/req/CabinCategories';
import buildFVCReservationBody from './mappers/req/FVCBookNow';
import buildInvoiceDetailBody from './mappers/req/InvoiceDetail';
import buildNewCabinByAttributesBody from './mappers/req/NewCabinByAttributes';
import buildUpdateReservationBody from './mappers/req/UpdateReservation';
import addOtherGuestDetailsBody from './mappers/req/addOtherGuestDetailsBody';
import isValidCalculateInvoice from './validators/calculateInvoice';
import isValidSearch from './validators/search';

export const fetchCMSComponent = (url) => api.get(url, { baseURL: env.CMS_COMPONENT_BASE_URL });

export const fetchCMSComponentForBook = (url) => api.get(url, { baseURL: env.CMS_COMPONENT_BASE_URL_BOOK });

export const fetchVVCampaignContentData = (token, id) => {
  const content = api.get(`/vv-campaigns?externalId=${id}`, {
    baseURL: env.CMS_BASE_URL,
    headers: {
      Authorization: `bearer ${token}`,
    },
  });
  return content;
};

export const fetchLegalContentData = (token, name) => {
  const content = api.get(`/legalcontents?name=${name}`, {
    baseURL: env.CMS_BASE_URL,
    headers: {
      Authorization: `bearer ${token}`,
    },
  });
  return content;
};

export const fetchReferralCode = (reservationNumber) =>
  api.get(`/referral/booking/${reservationNumber}`, {
    baseURL: env.SEAWARE_EXTENSION_BASE_URL,
  });

export const checkReferralEligibility = sharedCall((params) =>
  api.post('/referral/eligibility', params, {
    baseURL: env.SEAWARE_EXTENSION_BASE_URL,
  }),
);

export const linkReferralData = (params) =>
  api.post('/referral', params, {
    baseURL: env.SEAWARE_EXTENSION_BASE_URL,
  });

export const fetchSignedFields = (params) =>
  AppAuthenticator.getInstance()
    .getFreshAccessToken({ tokenType: TokenType.guest })
    .then((clientAccessToken) =>
      api.post('/signature', params, {
        headers: {
          Authorization: `bearer ${clientAccessToken}`,
        },
      }),
    );

export const initiateUpliftPayment = (params) =>
  AppAuthenticator.getInstance()
    .getFreshAccessToken({ tokenType: TokenType.guest })
    .then((clientAccessToken) =>
      api.post('/initiatepayment', params, {
        baseURL: env.PAYMENT_URL,
        headers: {
          Authorization: `bearer ${clientAccessToken}`,
        },
      }),
    );

export const postPaymentResponse = (params) =>
  AppAuthenticator.getInstance()
    .getFreshAccessToken({ tokenType: TokenType.guest })
    .then((clientAccessToken) =>
      api.post('/reservation/pay', params, {
        headers: {
          Authorization: `bearer ${clientAccessToken}`,
        },
      }),
    );

export const fetchUserProfileData = () => {
  const auth = getStoredValue('Authorization');
  if (auth == null) {
    return Promise.reject('No account data');
  }
  return api.get('/userprofile', {
    headers: {
      Authorization: `bearer ${auth.accessToken}`,
    },
  });
};

export const postSocialMediaPhoto = (photoUrl, clientToken) => {
  const content = api.post(
    `/mediaitems/url?mediaGroupId=f67c581d-4a9b-e611-80c2-00155df80332&fileurl=${photoUrl}`,
    {},
    {
      baseURL: env.DXP_CORE_BASE_URL,
      headers: {
        Authorization: `bearer ${clientToken}`,
      },
    },
    { shouldReturnEntireResponse: true },
  );
  return content;
};

export const validateAccessKey = sharedCall((accessKey) => api.get(`/fetchPromoCode/${accessKey}`));

// TODO: In all api.get call here need to pass the parameters as the config.params field, and not form the url manually!

export const fetchDashboardData = (reservationNumber, processingId) => {
  const params = { reservationNumber, ...(processingId ? { processingId } : undefined) };
  return api.get('/voyagedashboard', { params });
};

export const fetchVoyageListData = async (searchQualifier, config) =>
  (await api.post('/voyages', searchQualifier, config)) || { packages: [] };

export const fetchMetaCatgoryData = async (parsedParameters, supportedCurrencies) => {
  const response = await api.post(
    '/metacategories/search',
    ...buildCabinCategoriesReq(parsedParameters, supportedCurrencies, 'fetchMetaCatgoryData'),
  );
  return response;
};

export const fetchDashboardItineraryDetailsData = async (parsedParameters) => {
  const { packageCode, voyageId } = parsedParameters;
  return api.get(`/sailings?voyageId=${voyageId}&packageCode=${packageCode}`);
};

export const fetchCabinDetailsData = (cabinType, cabinMetaCode, isCabinAccessible) =>
  api.get(
    `/cabins/${cabinType}/details?cabinMetaCode=${cabinMetaCode}${
      isCabinAccessible ? `&isAccessible=${isCabinAccessible}` : ''
    }`,
  );

export const fetchCaluclateInvoiceData = sharedCall(
  ({ cabinType, cabins, currencyCode, externalRefId, isCabinAccessible, metaType, promoCode, sailors, voyageId }) =>
    api.post(
      '/calculateinvoice',
      buildInvoiceDetailBody(
        voyageId,
        cabinType,
        sailors,
        cabins,
        currencyCode,
        isCabinAccessible,
        metaType,
        promoCode,
        externalRefId,
      ),
      { validator: isValidCalculateInvoice },
    ),
  90000,
);

export const cabinHold = (cabinDetails) => api.post('/cabin/hold', cabinDetails);

export const cabinUnhold = (cabinDetails) => api.post('/cabin/unhold', cabinDetails);

export const fetchWhatsIncludedData = () => api.get('/whatsIncluded');

export const fetchItinerarySummaryData = (parsedParameters) => {
  const { packageCode, voyageId } = parsedParameters;
  return api.get(`/sailings?voyageId=${voyageId}&packageCode=${packageCode}`);
};

export const fetchCabinCategoriesData = (parsedParameters, supportedCurrencies) => {
  const [data, config] = buildCabinCategoriesReq(parsedParameters, supportedCurrencies, 'fetchCabinCategoriesData');
  return api.post('/cabincategories/search', data, { ...config, validator: isValidSearch });
};

export const validateAgency = (agentId, includeAgentDetails = false) =>
  api.get(`/reservations/agentdetail/validation?agentId=${agentId}&includeAgentDetails=${includeAgentDetails}`, {
    baseURL: env.DXP_CORE_BASE_URL,
  });

export const generateReservation = (sailorDetails) => api.post('/booknow', buildBookNowBody(sailorDetails));

export const updateReservationFVC = (bookNowParams, sailorAddressFVC, reservationNumber) =>
  api.post('/booknow', buildFVCReservationBody(bookNowParams, sailorAddressFVC, reservationNumber));

export const updateReservation = sharedCall((dashboardData, shouldIgnoreSailorObject) =>
  api.post('/booknow', buildUpdateReservationBody(dashboardData, shouldIgnoreSailorObject)),
);

export const updateReservationForSailorDetails = (dashboardData, sailorParams) =>
  api.post('/booknow', buildUpdateReservationBody(dashboardData, { sailorParams }));

export const addOtherGuestDetails = (dashboardData, guestsailor) =>
  api.post('/booknow', addOtherGuestDetailsBody(dashboardData, guestsailor));

export const optInForNews = (subscribeData) => api.post('/subscribe', subscribeData);

export const fetchTravelInsuranceFlyoutData = () => api.get('/travelInsurance');

export const fetchAvailableZones = (availableZoneRequest) => api.post('/availablezones', availableZoneRequest);

export const getNewCabinByAttributes = (zone, parsedParameters) => {
  const { cabinType, cabins, currencyCode, isCabinAccessible, sailors, shipCode, voyageId } = parsedParameters;
  return api.post(
    '/cabin/allotbyzone',
    buildNewCabinByAttributesBody(
      voyageId,
      cabinType,
      sailors,
      cabins,
      shipCode,
      currencyCode,
      isCabinAccessible,
      zone,
    ),
  );
};

export const shortenUrl = sharedCall((shareUrl) => api.get(`/api/shorten?url=${encodeURIComponent(shareUrl)}`));

export const fetchAccessKeyDetailsData = (campaignKey) => {
  const accessKeyDetails = api.get(`/accesskeys/${campaignKey}`).then((data) => {
    const details = {};
    Object.assign(details, { ...data, campaignKey: data.accessKey });
    delete details.accessKey;
    return details;
  });

  return accessKeyDetails;
};

export const validateReferralCode = (referralCode) => {
  if (!referralCode) throw new TypeError(`Empty referralCode: ${referralCode}`);
  return api.get(`/referral/${referralCode}`, {
    baseURL: env.SEAWARE_EXTENSION_BASE_URL,
  });
};

export const fetchConfirmationTodoList = (reservationNumber, isVip, clientTransactionId) =>
  api.get('/confirmationPage/landing', { params: { clientTransactionId, isVip, reservationNumber } });

export const fetchFVCDetails = (params) =>
  api.post('/sailors/voyageCredit', params, {
    baseURL: env.SEAWARE_EXTENSION_BASE_URL,
  });

export const applyFVC = (params) =>
  api.post('/sailors/applyVoyageCredit', params, {
    baseURL: env.SEAWARE_EXTENSION_BASE_URL,
  });

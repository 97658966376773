import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import {
  getBookingSource,
  getCountryDialCode,
  getMnvvReservation,
  getSessionStorageValue,
  setSessionStorageValue,
} from '@/helpers/util';

const BookNow = (sailorDetails) => {
  const {
    add_flights: addFlights,
    cabinNumber,
    guestId,
    holdBooking,
    lookup,
    parsedParameters,
    payInFullPromo,
    primarySailor,
  } = sailorDetails;

  const {
    aptNo,
    citizenShip,
    city,
    contactnumber,
    country,
    countryCode,
    dob: dateOfBirth,
    email,
    firstname: givenName,
    gender,
    insurance,
    lastname: lastName,
    lineOne,
    lineTwo,
    paidVia,
    phoneCountryCode,
    postalCode,
    stateCode,
    streetAddress,
    zipCode,
  } = primarySailor;

  const { isMNVV, reservationNumber } = getMnvvReservation();

  let booknow = { holdBooking };
  const promoCodes = [];
  // voyage Id
  const voyageId = get(parsedParameters, 'voyageId');
  booknow.voyageId = voyageId;

  // accessKeys:  shifted inside cabin
  const accessKey = get(parsedParameters, 'accessKey');
  const promoCode = get(parsedParameters, 'promoCode', '');
  let accessKeys = [];
  if (promoCode) {
    accessKeys = accessKey ? [accessKey] : [];
    promoCodes.push(promoCode);
  }

  // ship code
  const shipCode = get(parsedParameters, 'shipCode');
  booknow.shipCode = shipCode;

  // cabin category
  const cabinType = get(sailorDetails, 'parsedParameters.cabinType');

  // guest count
  // eslint-disable-next-line radix
  const guestCount = parseInt(get(sailorDetails, 'parsedParameters.sailors'), 0);

  if (!isEmpty(payInFullPromo)) {
    promoCodes.push(get(payInFullPromo, 'promoCode', ''));
  }

  const hasOptedForContactForFlights = addFlights || false;

  // sailor obj
  const sailors = [];

  const guestRefNumber = '1';
  let primarySailorAddress = {};

  if (insurance === null) {
    sailors.push({
      address: primarySailorAddress,
      citizenShip,
      dateOfBirth,
      email,
      gender,
      givenName,
      guestId,
      guestRefNumber,
      hasOptedForContactForFlights,
      hasOptedForTripInsurance: false,
      lastName,
      ...(paidVia ? { paidVia } : undefined),
    });
  } else if (!insurance) {
    sailors.push({
      address: primarySailorAddress,
      citizenShip,
      dateOfBirth,
      email,
      gender,
      givenName,
      guestId,
      guestRefNumber,
      hasOptedForContactForFlights,
      hasOptedForTripInsurance: false,
      lastName,
      ...(paidVia ? { paidVia } : undefined),
    });
  } else {
    primarySailorAddress = {
      city: !isEmpty(city) ? city : undefined,
      countryCode: countryCode || country,
      lineOne: lineOne || streetAddress,
      lineTwo: lineTwo || !isEmpty(aptNo) ? aptNo : undefined,
      stateCode: !isEmpty(stateCode) ? stateCode : undefined,
      zipCode: zipCode || postalCode,
    };
    sailors.push({
      address: primarySailorAddress,
      citizenShip,
      dateOfBirth,
      email,
      gender,
      givenName,
      guestId,
      guestRefNumber,
      hasOptedForContactForFlights,
      hasOptedForTripInsurance: insurance,
      lastName,
      ...(paidVia ? { paidVia } : undefined),
    });
  }

  if (!isEmpty(contactnumber)) {
    const countryDialCode = getCountryDialCode(lookup, phoneCountryCode);
    sailors[0] = {
      ...sailors[0],
      contactNumber: contactnumber,
      mobileCountryCode: countryDialCode,
    };
  }

  const additionalSailors = sailorDetails.additionalSailors || getSessionStorageValue('additionalSailors') || [];
  const transformOptions = { hasOptedForContactForFlights, insurance, lookup, paidVia, primarySailorAddress };
  for (let i = 1; i < guestCount; i += 1) {
    const item = additionalSailors.find((sailor) => sailor.guestId === i + 1 || sailor.sailorNumber === i);
    sailors.push(transformAdditionalSailor(item, i + 1, transformOptions));
  }

  const cabins = [];
  if (cabinType) {
    cabins.push({
      accessKeys,
      accessible: get(parsedParameters, 'isCabinAccessible', false) ? parsedParameters.isCabinAccessible : false,
      cabinCategoryCode: cabinType,
      cabinNumber,
      guestCount,
      promoCode,
      promoCodes,
      sailors,
    });
  }
  booknow.cabins = cabins;
  if (isMNVV) {
    booknow.bookingNumber = reservationNumber;
  }
  booknow = { ...booknow, ...getBookingSource(parsedParameters?.currencyCode, false, false) };
  setSessionStorageValue('bookNowParams', booknow);
  return booknow;
};

/* type TTransformOptions = {
  hasOptedForContactForFlights: boolean
  insurance: boolean
  lookup: Record<string, unknown>
  paidVia: string
  primarySailorAddress: Record<string, unknown>
} */
export const transformAdditionalSailor = (
  sailor /* :Record<string, unknown> */,
  guestNumber /* :number */,
  options /* :TTransformOptions */,
) /* :Record<string, unknown> */ => {
  const {
    aptNo,
    citizenShip,
    city,
    contactnumber,
    country,
    dob,
    email,
    firstname,
    gender,
    isSameAsPrimary,
    lastname,
    phoneCountryCode,
    postalCode,
    stateCode,
    streetAddress,
  } = sailor || {};
  const { hasOptedForContactForFlights, insurance, lookup, paidVia, primarySailorAddress } = options || {};
  return {
    ...(!firstname
      ? undefined
      : {
          citizenShip: citizenShip || '',
          contactNumber: contactnumber || '',
          dateOfBirth: dob || '',
          email: email || '',
          gender: gender || '',
          givenName: firstname,
          lastName: lastname || '',
          mobileCountryCode: getCountryDialCode(lookup, phoneCountryCode || ''),
        }),
    address:
      !firstname || !insurance
        ? {}
        : isSameAsPrimary
          ? primarySailorAddress
          : {
              city: city || undefined,
              countryCode: country || '',
              lineOne: streetAddress || undefined,
              lineTwo: aptNo || undefined,
              stateCode: stateCode || undefined,
              zipCode: postalCode || '',
            },
    guestRefNumber: guestNumber.toString(),
    hasOptedForContactForFlights,
    hasOptedForTripInsurance: insurance || false,
    ...(paidVia ? { paidVia } : undefined),
  };
};

export default BookNow;

import flatMap from 'lodash/flatMap';
import keyBy from 'lodash/keyBy';
import uniqBy from 'lodash/uniqBy';

import { getFiltersOptions } from '@/ducks/filtersOptions';

import {
  getFilteredSailings,
  getFilteredSailingsDownToPackages,
  getFilteredSailingsDownToRegions,
  getRawSailingSearch,
} from './filterSailings';

// Get object of shape <package-id> -> { sailing price, duration, package info }
export const getPresentPackages = (state) => {
  const { packages } = getRawSailingSearch(state) ?? {};
  return keyBy(packages, 'packageCode');
};

// Get object of shape <port-id> => { port info }
export const getPresentPorts = (state) => {
  const { sailings } = getRawSailingSearch(state) ?? {};
  const allPorts = uniqBy(
    flatMap(sailings, (sail) => sail.ports),
    'code',
  );
  // Add id equal to 'code', return byKey object
  return keyBy(
    allPorts.map((port) => ({ ...port, id: port.code })),
    'code',
  );
};

// Get object of shape <node-id> => { lowest sailing price, duration }
export const getPresentNodes = (state) => {
  const sailings = getFilteredSailingsDownToRegions(state);
  // Build object of form node id => { lowest sailing price, duration }
  return sailings?.reduce((acc, sailing) => {
    if ('classificationCodes' in sailing) {
      sailing.classificationCodes.forEach((id) => {
        const data = { duration: sailing.duration, startingPrice: sailing.startingPrice };
        if (!acc[id] || acc[id].startingPrice.amount > data.startingPrice.amount) {
          acc[id] = data;
        }
      });
    }
    return acc;
  }, {});
};

// eslint-disable-next-line consistent-return
export const getPresentDurations = (state) => {
  const sailings = getFilteredSailingsDownToPackages(state);
  const { durationOptions } = getFiltersOptions(state);

  try {
    return durationOptions.map((duration) => ({
      ...duration,
      isDisabled: !sailings.some((s) => {
        if (duration.max && duration.min) {
          return s.duration >= duration.min && s.duration <= duration.max;
        }
        return s.duration >= duration.min;
      }),
    }));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('error while reading duration options key::: ', e);
  }
};

export const isWeekendAvailable = (state) => getFilteredSailings(state).some((obj) => obj.weekend);

export const isAccessibleAvailable = (state) => getFilteredSailings(state).some((sailing) => sailing.isAccessible);

import { createSelector } from '@reduxjs/toolkit';
import compact from 'lodash/compact';

import { RESET_FILTER_LABEL, SET_FILTER_LABEL, defaultState } from './reducer';

const FILTER_LABELS_ORDER = ['travelParty', 'accessible', 'duration', 'weekend', 'cabinType', 'price', 'ship', 'port'];

// selectors

export const getAppliedFilterLabels = (state) => state.appliedfilterLabels;

export const getAppliedOrderedFilterLabels = createSelector(getAppliedFilterLabels, (labels) =>
  compact(FILTER_LABELS_ORDER.map((key) => labels?.[key] && [key, labels[key]])),
);

// actions

export const resetAppliedFilterLabel = (label) => (dispatch) => {
  const refinement = {};
  refinement[`${label}`] = defaultState[label];
  dispatch({ payload: refinement, type: SET_FILTER_LABEL });
};

export const setAppliedFilterLabels = (labels) => (dispatch) => {
  dispatch({ payload: labels, type: SET_FILTER_LABEL });
};

export const resetAppliedFilterLabels = () => (dispatch) => {
  dispatch({ payload: defaultState, type: RESET_FILTER_LABEL });
};

/*
 * Refine search results from API request with frontend filters
 */
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';
import some from 'lodash/some';
import uniq from 'lodash/uniq';

import { selectFilters } from '@/ducks/filters/selectors';
import { getAmountPerItem, getAmountPerVoyage, getAmountTaxAware } from '@/helpers/data/mappers/Summary';
import { parse } from '@/helpers/util/dateUtil';

export const sailingFilterSteps = {
  ATTRIBUTES: 'attributes',
  PACKAGES: 'packages',
  PRICERANGE: 'priceRange',
  PROPERTIES: 'properties',
  REGIONS: 'regions',
};

export const getRawSailingSearch = (state) => state.chooseVoyage?.rawSailings;

const filterPackages = (sailings, packages) => sailings.filter((s) => packages.includes(s.packageCode));

const filterPorts = (sailings, ports) =>
  sailings.filter((sailing) => some(sailing.ports, (port) => ports.includes(port.code)));

const filterNodes = (sailings, nodes) => {
  if (!nodes.length) {
    return sailings;
  }
  return sailings.filter((sailing) => some(sailing.classificationCodes, (code) => nodes.includes(code)));
};

const filterDurations = (sailings, durations) => {
  if (!durations.length) {
    return sailings;
  }
  return sailings.filter((s) =>
    durations.some((duration) => {
      if (duration.max && duration.min) {
        return s.duration >= duration.min && s.duration <= duration.max;
      }
      return s.duration >= duration.min;
    }),
  );
};

const filterWeekend = (sailings, weekend) => (weekend ? filter(sailings, 'weekend') : sailings);

const filterAccessible = (sailings, accessible) => (accessible ? filter(sailings, 'isAccessible') : sailings);

export const filterPriceRange = (state, sailings) => {
  const filters = selectFilters(state);
  const { cabins, maxPrice, minPrice, priceType, sailors } = filters;
  const minPriceNum = parseInt(minPrice, 10);
  const maxPriceNum = parseInt(maxPrice, 10);
  if (minPriceNum === null && maxPriceNum === null) {
    return sailings;
  }
  const filteredSailings = sailings.filter((sail) => {
    let price = getAmountPerItem(sail.startingPrice, { cabins, priceType, sailors });
    if (priceType === 'SailorPerNight') {
      price = getAmountPerVoyage(sail.startingPrice, sail.duration, { sailors });
    }

    if (Number.isNaN(minPriceNum) && Number.isNaN(maxPriceNum)) {
      return true;
    }
    if (Number.isNaN(minPriceNum)) {
      return price <= maxPriceNum;
    }
    if (Number.isNaN(maxPriceNum)) {
      return price >= minPriceNum;
    }
    return price >= minPriceNum && price <= maxPriceNum;
  });
  let sailList = [];
  if (priceType === 'SailorPerNight') {
    sailList = orderBy(filteredSailings, (s) => getAmountPerVoyage(s.startingPrice, s.duration, { sailors }), 'asc');
  } else {
    sailList = orderBy(
      filteredSailings,
      (s) => getAmountPerItem(s.startingPrice, { cabins, priceType, sailors }),
      'asc',
    );
  }

  return sailList;
};

export const filterShips = (sailings, shipList) => {
  if (isEmpty(shipList)) {
    return sailings;
  }
  return sailings.filter((s) => shipList.some((ship) => s.ship.code === ship.id));
};

export const filterDeparturePorts = (sailings, homePorts) => {
  if (isEmpty(homePorts)) {
    return sailings;
  }
  const finalsailings = sailings.filter((s) => homePorts.some((port) => s.ports[0].code === port.code));
  return finalsailings;
};

const sortSailings = (sailings, sort) => {
  const [sortType, sortOrder = 'asc'] = sort.split('-');
  switch (sortType) {
    case 'price':
      return orderBy(sailings, (s) => getAmountTaxAware(s.startingPrice), sortOrder);
    case 'duration':
      return orderBy(sailings, 'duration', sortOrder);
    case 'date':
    default:
      return orderBy(sailings, (s) => parse(s.startDate), sortOrder);
  }
};

/**
 * Filter raw sailings list from API request with frontend filters.
 *
 * filterType param is a step on which we should exit filtering process;
 * it is used in selectors for UI filters available choices.
 */
export const getFilteredSailings = (state, filterType) => {
  const filters = selectFilters(state);
  const { sailings } = getRawSailingSearch(state) ?? {};
  let result = sailings;
  if (filters.packages.length || filters.ports.length) {
    result = uniq([...filterPackages(result, filters.packages), ...filterPorts(result, filters.ports)]);
  }
  if (filterType === sailingFilterSteps.REGIONS) {
    return result;
  }

  result = filterNodes(result, filters.nodes);
  if (filterType === sailingFilterSteps.PACKAGES) {
    return result;
  }

  result = filterDurations(result, filters.durations);
  result = filterWeekend(result, filters.weekend);
  result = filterAccessible(result, filters.accessible);
  result = filterPriceRange(state, result);
  result = filterShips(result, filters.shipList);
  result = filterDeparturePorts(result, filters.homePorts);
  return sortSailings(result, filters.sortType);
};

// Shortcuts for filterSailings()
export const getFilteredSailingsDownToRegions = (state) => getFilteredSailings(state, sailingFilterSteps.REGIONS);

export const getFilteredSailingsDownToPackages = (state) => getFilteredSailings(state, sailingFilterSteps.PACKAGES);

export default getFilteredSailings;

import axios from 'axios';
import { JL } from 'jsnlog';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { v4 as generateUUID } from 'uuid';

import { getBestToken } from '@/ducks/auth/selectors';
import { selectSettings } from '@/ducks/common/settings/selectors';
import { routes } from '@/ducks/routes';
import { env } from '@/environment';
import { removeStoredValue } from '@/helpers/util';
import { rootStore } from '@/store';

import { log } from '../util/logger';
import { parseJwt } from '../util/misc';
import { AppAuthenticator, TokenType } from './tokens';

const axiosInstance = axios.create();
const maxConsecutive401Errors = 3;
let consecutive401Errors = 0;

export const setRefreshTokenCallBack = (resetUserToken) => {
  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      const originalRequest = error.config;
      if (
        error &&
        error.response &&
        error.response.status === 401 &&
        originalRequest &&
        !originalRequest.retry &&
        error.response.data.message === 'jwt expired'
      ) {
        originalRequest.retry = true;
        const { userid } = parseJwt(originalRequest.headers.Authorization);
        return resetUserToken(userid)
          .then(() => AppAuthenticator.getInstance().getFreshAccessToken({ tokenType: TokenType.guest }))
          .then((guestAccessToken) => {
            if (isEmpty(guestAccessToken)) {
              log(`setRefreshTokenCallBack1:' ${guestAccessToken}, config - ${JSON.stringify(error?.config)}`);
              return Promise.reject(error);
            }
            originalRequest.headers.Authorization = `bearer ${guestAccessToken}`;
            return axiosInstance(originalRequest);
          })
          .catch((err) => {
            log(
              `setRefreshTokenCallBack2:' ${JSON.stringify(err?.response)}, config - ${JSON.stringify(error?.config)}`,
            );
            removeStoredValue('Authorization');
            routes.accounts.signin.go();
            throw err;
          });
      }
      return Promise.reject(error);
    },
  );
};

// axios defaults
export const initApiDefaults = (resetUserToken) => {
  axiosInstance.defaults.baseURL = env.BASE_URL;
  axiosInstance.defaults.headers.common['Content-Type'] = 'application/json';
  axiosInstance.defaults.headers.common['accept-language'] = selectSettings(rootStore.getState())?.language;
  axiosInstance.defaults.headers.common['Cache-Control'] =
    'no-store, no-cache, must-revalidate, post-check=0, pre-check=0';
  axiosInstance.defaults.headers.common.Pragma = 'no-cache';
  setRefreshTokenCallBack(resetUserToken);
};

export const getDefaultHeaders = async (correlationId) => {
  const state = rootStore.getState();

  const token = await getBestToken(state);
  if (!token) {
    log(`getDefaultHeaders:' ${token}, correlationId - ${correlationId}`);
  }

  return {
    Accept: 'application/json',
    Authorization: `bearer ${token}`,
    CorrelationId: correlationId,
    'X-Requested-With': 'XMLHttpRequest',
  };
};

const getCurrentHeaders = (config) => {
  const { headers = {} } = config;
  return headers;
};

const handleUnauthorizedException = (url, error) => {
  const userTokenUrls = [
    '/users/accesskey',
    '/myvoyages',
    '/userprofile',
    '/users/rockstarAgent',
    '/rtsdetails',
    '/users/credits',
    '/voyagedashboard',
  ];

  if (get(error, 'response.status') === 401 && userTokenUrls.some((userTokenUrl) => url.endsWith(userTokenUrl))) {
    log(`handleUnauthorizedException:' ${JSON.stringify(error?.response)}, url - ${url}`);
    removeStoredValue('Authorization');
    routes.accounts.signin.go();
  }
};

const getConfigDetails = (param) => {
  const state = rootStore.getState();
  const baseURL = selectSettings(state)?.baseURL ?? false;
  return {
    baseURL,
    headers: {
      common: {
        Accept: get(param, 'config.headers.Accept'),
        CorrelationId: get(param, 'config.headers.CorrelationId'),
      },
      maxContentLength: get(param, 'config.maxContentLength'),
      method: get(param, 'config.method'),
      timeout: get(param, 'config.timeout'),
    },
    maxContentLength: get(param, 'config.maxContentLength'),
    method: get(param, 'config.method'),
    timeout: get(param, 'config.timeout'),
    url: get(param, 'config.url'),
    xsrfCookieName: get(param, 'config.xsrfCookieName'),
    xsrfHeaderName: get(param, 'config.xsrfHeaderName'),
  };
};

export const getResponseDetails = (param) => ({
  config: {
    baseURL: get(param, 'config.baseURL'),
    headers: {
      Accept: get(param, 'config.headers.Accept'),
      CorrelationId: get(param, 'config.headers.CorrelationId'),
    },
    maxContentLength: get(param, 'maxContentLength'),
    method: get(param, 'method'),
    timeout: get(param, 'timeout'),
    url: get(param, 'url'),
    xsrfCookieName: get(param, 'xsrfCookieName'),
    xsrfHeaderName: get(param, 'xsrfHeaderName'),
  },
  data: get(param, 'data'),
  headers: get(param, 'headers'),
  request: get(param, 'request'),
  status: get(param, 'status'),
  statusText: get(param, 'statusText'),
});

export const api = {
  delete(url, config) {
    return this.request({
      ...config,
      method: 'delete',
      url,
    });
  },
  get(url, config) {
    return this.request({
      ...config,
      method: 'get',
      url,
    });
  },
  patch(url, data, config) {
    return this.request({
      ...config,
      data,
      method: 'patch',
      url,
    });
  },
  post(url, data, config, shouldReturnEntireResponse = false) {
    return this.request(
      {
        ...config,
        data,
        method: 'post',
        url,
      },
      shouldReturnEntireResponse,
    );
  },
  put(url, data, config) {
    return this.request({
      ...config,
      data,
      method: 'put',
      url,
    });
  },
  async request(configuration, options = {}) {
    const { shouldReturnEntireResponse = false } = options;
    const { validationError, validator, ...config } = configuration;
    // TODO: BFF HTTP -> HTTPS

    let messageDetail;
    // req logging
    const correlationId = generateUUID();

    // headers
    config.headers = {
      ...(await getDefaultHeaders(correlationId)),
      ...getCurrentHeaders(config),
    };
    // if defaults will be overriden
    config.baseURL = config.baseURL ?? env.BASE_URL;

    const isValidated = validator?.({ args: configuration?.data });
    if (validator && !isValidated) {
      // eslint-disable-next-line no-console
      console.warn(messageDetail.message, configuration?.data);
      messageDetail = {
        message: 'invalid arguments when calling endpoint',
        messageCode: '9001',
        severity: 'WARN',
      };
      JL('BV').warn({
        correlationId,
        message: getConfigDetails({ config }),
        messageDetail,
      });
      return null;
    }

    // req start
    messageDetail = {
      message: 'common service request message',
      messageCode: '5001',
      severity: 'INFO',
    };
    JL('BV').info({
      correlationId,
      message: getConfigDetails({ config }),
      messageDetail,
    });

    let response;
    try {
      if (validationError) {
        throw validationError;
      }

      response = await axiosInstance.request(config);

      // res success
      messageDetail = {
        message: 'common service response message',
        messageCode: '5002',
        severity: 'INFO',
      };
      JL('BV').info({
        correlationId,
        message: getResponseDetails(response),
        messageDetail,
      });
    } catch (err) {
      if (get(err, ['response.status']) === 401 && get(err, ['config.url']) === '/signature') {
        consecutive401Errors += 1;
        const state = rootStore.getState();
        const token = await getBestToken(state);
        if (consecutive401Errors >= maxConsecutive401Errors) {
          removeStoredValue('Authorization');
          consecutive401Errors = 0;
        }
        config.headers = {
          Authorization: `bearer ${token}`,
        };
        response = await axiosInstance.request(config);
        return shouldReturnEntireResponse ? response : response.data;
      }
      // TODO: res error logging
      messageDetail = {
        message: 'common service request error message',
        messageCode: '9001',
        severity: 'ERROR',
      };
      JL('BV').error({ correlationId, message: err, messageDetail });

      handleUnauthorizedException(config.url, err);
      return Promise.reject(err);
    }
    // TODO: res logging
    return shouldReturnEntireResponse ? response : response.data;
  },
};

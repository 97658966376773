import { createReducer } from '@reduxjs/toolkit';

import { fetchProfileDataAction } from '@/actions/accounts/dashboard';
import { CLEAR_LOGGED_IN_SESSION_DATA } from '@/constants/actionTypes';

const initialState = { data: {}, error: {}, isLoading: false };
const profileReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(CLEAR_LOGGED_IN_SESSION_DATA, () => initialState)
    .addCase(fetchProfileDataAction.pending, () => ({
      ...initialState,
      isLoading: true,
    }))
    .addCase(fetchProfileDataAction.fulfilled, (_, { payload }) => ({
      ...initialState,
      data: payload,
    }))
    .addCase(fetchProfileDataAction.rejected, () => ({
      ...initialState,
      error: true,
    })),
);

export default profileReducer;

import isEmpty from 'lodash/isEmpty';

export const ACCESS_KEY_STATES = {
  DETAIL: 'DETAIL',
  EMPTY: 'EMPTY',
  INIT: 'INIT',
  VALID: 'VALID',
};

export const ACCESS_KEY_INITED = 'ACCESS_KEY_INITED';

export const ACCESS_KEY_VALIDATE_SUCCESS = 'ACCESS_KEY_VALIDATE_SUCCESS';
export const ACCESS_KEY_VALIDATE_ERROR = 'ACCESS_KEY_VALIDATE_ERROR';

export const ACCESS_KEY_DETAILS_SUCCESS = 'ACCESS_KEY_DETAILS_SUCCESS';
export const ACCESS_KEY_DETAILS_ERROR = 'ACCESS_KEY_DETAILS_ERROR';

export const ACCESS_KEY_CLEAR_ACTIVE = 'ACCESS_KEY_CLEAR_ACTIVE';

const defaultState = {
  accessKeys: {
    error: null,
    // list of all available access keys (for access keys page)
    list: [],
  },
  activeAccessKey: {
    accessKey: null,
    error: null,
    filterSailing: false,
    promoCode: null,
    state: ACCESS_KEY_STATES.EMPTY,
  }, // key which is currently active in booking flow
};

// Selectors
export const getAccessKey = (state) => state.accessKeys.activeAccessKey;
export const selectAccessKey = (state) => getAccessKey(state)?.accessKey;
export const selectAccessKeyPromoCode = (state) => getAccessKey(state)?.promoCode;
export const selectAccessKeyState = (state) => getAccessKey(state)?.state;
export const selectIsCorrectAccessKey = (state) => selectAccessKeyPromoCode(state) && selectAccessKey(state);

export const hasAccessOrPromoKey = (accessKeyDetails, key) =>
  !isEmpty(accessKeyDetails) || !Object.values(accessKeyDetails).some((details) => !isEmpty(details?.[key]));

export default function accessKeys(state = defaultState, action) {
  switch (action.type) {
    case ACCESS_KEY_INITED: {
      return {
        ...state,
        activeAccessKey: {
          ...defaultState.activeAccessKey,
          accessKey: action.payload,
          error: null,
          state: ACCESS_KEY_STATES.INIT,
        },
      };
    }
    case ACCESS_KEY_VALIDATE_SUCCESS:
      return { ...state, activeAccessKey: { ...action.payload, error: null, state: ACCESS_KEY_STATES.VALID } };
    case ACCESS_KEY_DETAILS_SUCCESS:
      return {
        ...state,
        activeAccessKey: { ...state.activeAccessKey, ...action.payload, error: null, state: ACCESS_KEY_STATES.DETAIL },
      };
    case ACCESS_KEY_VALIDATE_ERROR:
    case ACCESS_KEY_DETAILS_ERROR:
      return {
        ...state,
        activeAccessKey: { ...state.activeAccessKey, error: action.payload, state: ACCESS_KEY_STATES.INIT },
      };
    case ACCESS_KEY_CLEAR_ACTIVE:
      return { ...state, activeAccessKey: defaultState.activeAccessKey };
    default:
      return state;
  }
}

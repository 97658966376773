import { jwtDecode } from 'jwt-decode';

import failAnalyzer, { IAM_WATCHER } from '@/app/failAnalyzer';
import { env } from '@/environment';
import {
  type AppTokens,
  type FetchTokenArgs,
  type Token,
  type TokenReqOptions,
  type TokenResponse,
  TokenType,
} from '@/helpers/api/tokens/types';
import { isServerSide } from '@/helpers/isServerSide';

const TOKEN_TYPE_TO_GRANT_TYPES = {
  [TokenType.cms]: 'password',
  [TokenType.guest]: 'client_credentials',
} as const;

class AppAuthenticator {
  private static instance: AppAuthenticator = new AppAuthenticator();
  static getInstance() {
    return AppAuthenticator.instance;
  }

  private tokens: AppTokens = {
    [TokenType.cms]: null,
    [TokenType.guest]: null,
  };

  getRawToken = (tokenType: TokenType) => {
    return this.tokens[tokenType];
  };

  getRawTokens = () => {
    return this.tokens;
  };

  setRawTokens = (tokens: AppTokens) => {
    this.tokens = tokens;
  };

  setRawToken = (tokenType: TokenType, token: Token) => {
    this.tokens = { ...this.tokens, [tokenType]: token };
  };

  public isTokenValid = ({ token, tokenType }: { token?: Token | null; tokenType?: TokenType }) => {
    if (!token && !tokenType) {
      return false;
    }

    const selectedToken = token ?? this.getRawToken(tokenType!);
    if (!selectedToken?.access_token) {
      return false;
    }
    const decodedToken = jwtDecode(selectedToken?.access_token);
    const dateNow = Date.now() / 1000;

    return (decodedToken?.exp || 0) > dateNow;
  };

  private fetchToken = async ({ options, tokenType }: FetchTokenArgs) => {
    const basicToken = btoa(`${env.CLIENT_ID}:${env.CLIENT_SECRET}`);

    const credentials = {
      password: env.CMS_REST_PASSWORD,
      username: env.CMS_REST_USERNAME,
    };

    const url = new URL(`${env.CMS_AUTH_BASE_URL}oauth/token`);
    const params = {
      grant_type: TOKEN_TYPE_TO_GRANT_TYPES[tokenType],
      ...(tokenType === TokenType.cms && credentials),
    };
    url.search = new URLSearchParams(params).toString();

    const result = await fetch(url.toString(), {
      cache: 'no-store',
      headers: {
        authorization: `Basic ${basicToken}`,
      },
      method: 'POST',
      ...options,
    });

    if (!result.ok) {
      throw new Error(`Got code '${result.status}' for token: '${tokenType}'.\nDetails: ${result.statusText}`);
    }

    failAnalyzer.analyze({ success: result.ok, type: IAM_WATCHER });

    const jsonResult = (await result.json()) as TokenResponse;

    return jsonResult;
  };

  private fetchTokens = async ({ options }: Pick<FetchTokenArgs, 'options'> = {}) =>
    this.loopOverAllTokens({ fn: this.fetchToken, options });

  public getFreshToken = async ({ options, tokenType }: FetchTokenArgs) => {
    const token = this.getRawToken(tokenType);
    if (this.isTokenValid({ token, tokenType })) {
      return token;
    }

    const result = isServerSide()
      ? await this.fetchToken({ options, tokenType })
      : await fetch(`${env.CONTEXT}/api/auth?tokenType=${tokenType}`).then((res) => res.json());

    if (result) {
      this.setRawToken(tokenType, result);
    }
    return result as Token;
  };

  public getFreshAccessToken = async (arg: Parameters<typeof this.getFreshToken>[0]) =>
    this.getFreshToken(arg).then((token) => token?.access_token);

  public getAllFreshTokens = async ({ options }: { options?: TokenReqOptions } = {}) =>
    this.loopOverAllTokens({ fn: this.getFreshToken, options });

  private loopOverAllTokens = async ({
    fn,
    options,
    ...args
  }: {
    fn: (arg: FetchTokenArgs) => Promise<Token | null>;
    options?: TokenReqOptions;
  } & Record<string, unknown>) => {
    const tokenTypes = Object.values(TokenType);
    const tokens = await Promise.all(tokenTypes.map((tokenType) => fn({ options, tokenType, ...args })));

    return tokenTypes.reduce((acc, tokenType, i) => {
      acc[tokenType] = tokens[i]!;
      return acc;
    }, {} as AppTokens);
  };
}

export default AppAuthenticator;

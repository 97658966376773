import isEqual from 'lodash/isEqual';

import type { TOptional } from '@/types/common';

import limitTime from '@/helpers/limitTime';

type TCall<A extends unknown[], R> = {
  args: A;
  promise: Promise<R>;
};

const sharedCall = <A extends unknown[], R>(
  fn: (...args: A) => Promise<R>,
  timeout?: number,
): ((...args: A) => Promise<TOptional<R>>) => {
  const calls: Array<TCall<A, R>> = [];
  return (...args: A): Promise<TOptional<R>> => {
    let { promise } = calls.find((call) => isEqual(args, call.args)) || {};
    if (!promise) {
      promise = fn(...args).finally(() => {
        const i = calls.findIndex((call) => promise === call.promise);
        if (i !== -1) calls.splice(i, 1);
      });
      calls.push({ args, promise });
    }
    return (timeout ?? 0) > 0 ? limitTime(promise, timeout!, undefined) : promise;
  };
};

export default sharedCall;

import {
  FILTERS_APPLY,
  META_CABIN_CATEGORY_DATA_ERROR,
  META_CABIN_CATEGORY_DATA_LOADING,
  META_CABIN_CATEGORY_DATA_RESET,
  META_CABIN_CATEGORY_DATA_SUCCESS,
  META_CABIN_CATEGORY_SET_UNAVAILABLE,
} from '@/constants/actionTypes';

const sortLowestPriceFirst = (metaCabinCategories) =>
  [...metaCabinCategories].sort(
    (a, b) => b.isAvailable - a.isAvailable || a.bookingPriceDetails?.amount - b.bookingPriceDetails?.amount,
  );

const sortHighestPriceFirst = (metaCabinCategories) =>
  [...metaCabinCategories].sort(
    (a, b) => b.isAvailable - a.isAvailable || b.bookingPriceDetails?.amount - a.bookingPriceDetails?.amount,
  );

const sortByDefault = (metaCabinCategories) =>
  [...metaCabinCategories].sort(
    (a, b) => b.isAvailable - a.isAvailable || a.recommendedSortPriority - b.recommendedSortPriority,
  );

const sortMetaCategoriesByType = (metaCabinCategories, sortType) => {
  switch (sortType) {
    case 'recommended':
      return sortByDefault(metaCabinCategories);
    case 'priceAsc':
      return sortLowestPriceFirst(metaCabinCategories);
    case 'priceDesc':
      return sortHighestPriceFirst(metaCabinCategories);
    default:
      return sortByDefault(metaCabinCategories);
  }
};

const updateMetaCabinCategoryAvailability = (metas, code) =>
  [...metas].map((meta) => (meta.code === code ? { ...meta, isAvailable: false } : { ...meta }));

const defaultState = {
  data: [],
  error: {},
  isLoaded: false,
  isLoading: false,
};

export default function metaCabinCategoriesReducer(store = defaultState, action) {
  switch (action.type) {
    case FILTERS_APPLY:
      return {
        ...store,
        data: sortMetaCategoriesByType(store.data, action.payload.chooseCabinSort),
      };
    case META_CABIN_CATEGORY_DATA_SUCCESS:
      return {
        ...store,
        data: sortMetaCategoriesByType(action.payload.metaCabinCategories, action.payload.chooseCabinSort),
        error: action.payload.metaCabinCategories?.length ? {} : { isFetchMetaCabinCategoriesResponseEmpty: true },
        isLoaded: true,
        isLoading: false,
      };
    case META_CABIN_CATEGORY_SET_UNAVAILABLE:
      return {
        ...store,
        data: sortMetaCategoriesByType(
          updateMetaCabinCategoryAvailability(store.data, action.payload.unavailableMetaCabinCategoryCode),
          action.payload.chooseCabinSort,
        ),
      };
    case META_CABIN_CATEGORY_DATA_ERROR:
      return { ...store, data: defaultState.data, error: action.payload, isLoading: false };
    case META_CABIN_CATEGORY_DATA_LOADING:
      return { ...store, data: defaultState.data, isLoading: true };
    case META_CABIN_CATEGORY_DATA_RESET:
      return defaultState;
    default:
      return store;
  }
}

import { createAsyncThunk } from '@reduxjs/toolkit';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import {
  ACCOUNTS_DASHBOARD_DATA_ERROR,
  ACCOUNTS_DASHBOARD_DATA_SUCCESS,
  CLEAR_LOGGED_IN_SESSION_DATA,
} from '@/constants/actionTypes';
import { throwError } from '@/ducks/common';
import { routes } from '@/ducks/routes';
import { getSearchParams } from '@/ducks/routes/history';
import { fetchDashboardData, fetchUserProfileData } from '@/helpers/api/app';
import { getDXPAuth } from '@/helpers/getDXPAuth';
import { getSessionStorageValue } from '@/helpers/util/storage';

export const selectPaymentEncReservationNumber = (state) => state?.voyagePlanner?.payment?.encReservationNumber?.data;

export const clearLoggedInSessionData = () => (dispatch) => {
  dispatch({
    payload: {},
    type: CLEAR_LOGGED_IN_SESSION_DATA,
  });
};

export const fetchProfileDataAction = createAsyncThunk('userProfile', async (_, { dispatch }) => {
  try {
    const data = await fetchUserProfileData();
    return data;
  } catch (err) {
    dispatch(throwError(err));
  }
});

export function fetchDashboardDataAction(reservationNumber, processingId) {
  return async (dispatch, getState) => {
    let data = [];
    try {
      const state = getState();
      const isLoggedIn = await getDXPAuth().isAuth();
      const finalReservationNumber =
        (!isLoggedIn && (selectPaymentEncReservationNumber(state) || getSessionStorageValue('encResId'))) ||
        getSearchParams()?.reservationNumber ||
        reservationNumber;
      data = await fetchDashboardData(finalReservationNumber, processingId);
      if (isEmpty(get(data, '0.reservationNumber'))) {
        routes.planner.chooseVoyage.go();
      }
      dispatch({
        payload: data,
        type: ACCOUNTS_DASHBOARD_DATA_SUCCESS,
      });
    } catch (err) {
      dispatch({
        payload: null,
        type: ACCOUNTS_DASHBOARD_DATA_ERROR,
      });
      routes.page404.go();
    }
    return data;
  };
}

import orderBy from 'lodash/orderBy';

import { getAmountPerVoyage, getAmountTaxAware } from '@/helpers/data/mappers/Summary';
import { parse } from '@/helpers/util/dateUtil';

export const sortPackageSailings = (sailings, sort) => {
  const [sortType, sortOrder = 'asc'] = sort.split('-');
  const sortedSailings = orderBy(sailings, (s) => parse(s.startDate), 'asc');

  switch (sortType) {
    case 'price':
      return orderBy(sortedSailings, (s) => getAmountTaxAware(s.startingPrice), sortOrder);
    case 'duration':
      return orderBy(sortedSailings, 'duration', sortOrder);
    case 'date':
    default:
      return sortedSailings;
  }
};

export const getBestPriceSailing = (sailingList) => {
  const sailings = orderBy(
    orderBy(sailingList, (s) => parse(s.startDate), 'asc'),
    (s) => getAmountTaxAware(s.startingPrice),
    'asc',
  );

  return sailings.length > 0 ? sailings[0] : [];
};

/* @Three stages of sorting
--> sort by selected sortType, if packages share the same values then sort by date then sort by name.
--> For datesortType, if packages share the same values, then sort by name.
*/

export const sortSailings = (packages, sort, fromDate, priceType, sailors) => {
  if (!packages) {
    return;
  }
  const newPackges = [...packages];
  const [sortType, sortOrder = 'asc'] = sort.split('-');

  // sort packages by packageName
  const sortByName = (pack) =>
    pack.sort((a, b) => {
      if (a.packageName < b.packageName) {
        return -1;
      }
      if (a.packageName > b.packageName) {
        return 1;
      }
      return 0;
    });

  // sort sailings to nearest Date .
  const sortsailingsByDates = (packw) =>
    packw.map((data) => {
      if (data.sailingList && data.sailingList.length > 0) {
        data.sailingList.sort(
          (a, b) =>
            Math.abs(new Date(fromDate) - new Date(a.startDate)) - Math.abs(new Date(fromDate) - new Date(b.startDate)),
        );
        data = {
          // eslint-disable-line no-param-reassign
          ...data,
          bestPriceSailing: getBestPriceSailing(data.sailingList),
          sortedSailings: sortPackageSailings(data.sailingList, sort),
        };
      }
      return data;
    });

  const sortByDate = () => {
    /* initially sort by name, if dates falls to same,
    then automaticaly it sorts by name for respective packages */
    const sortNamepackages = sortByName(newPackges);
    // get the sortedSailings to get nearest to DateRange
    const sortDatePackages = sortsailingsByDates(sortNamepackages);
    if (sortDatePackages.length > 0) {
      return orderBy(
        sortDatePackages,
        (s) => parse(s && s?.sailingList?.length > 0 ? s.sailingList[0]?.startDate : ''),
        sortOrder,
      );
    }
    return [];
  };

  const sortName = (a, b) => {
    if (a < b) {
      return -1;
    }
    if (a > b) {
      return 1;
    }
    return 0;
  };

  const sortByDuration = () => {
    // get the sortedPackges
    const sortedPackages = sortByDate();
    if (sortedPackages.length > 0) {
      const sortedDuration = sortedPackages.sort((a, b) => {
        // multiple level of sorting logic.
        if (sortOrder === 'asc') {
          return (
            a.duration - b.duration ||
            Math.abs(new Date(fromDate) - new Date(a?.sortedSailings?.[0]?.startDate)) -
              Math.abs(new Date(fromDate) - new Date(b?.sortedSailings?.[0]?.startDate)) ||
            sortName(a.packageName, b.packageName)
          );
        }
        return (
          b.duration - a.duration ||
          Math.abs(new Date(fromDate) - new Date(a?.sortedSailings?.[0]?.startDate)) -
            Math.abs(new Date(fromDate) - new Date(b?.sortedSailings?.[0]?.startDate)) ||
          sortName(a.packageName, b.packageName)
        );
      });
      return sortedDuration || [];
    }
    return [];
  };

  const sortByPriceType = () => {
    // get the sortedPackges
    const sortedPackages = sortByDate() || [];
    if (sortedPackages.length > 0) {
      const sortedPriceType = sortedPackages.sort((a, b) => {
        const aStartingPrice =
          priceType === 'SailorPerNight'
            ? getAmountPerVoyage(a.startingPrice, a.duration, { sailors })
            : getAmountTaxAware(a.startingPrice);
        const bstartingPrice =
          priceType === 'SailorPerNight'
            ? getAmountPerVoyage(b.startingPrice, b.duration, { sailors })
            : getAmountTaxAware(b.startingPrice);
        // multiple level of sorting logic.
        if (sortOrder === 'asc') {
          return (
            aStartingPrice - bstartingPrice ||
            Math.abs(new Date(fromDate) - new Date(a?.sortedSailings?.[0]?.startDate)) -
              Math.abs(new Date(fromDate) - new Date(b?.sortedSailings?.[0]?.startDate)) ||
            sortName(a.packageName, b.packageName)
          );
        }
        return (
          bstartingPrice - aStartingPrice ||
          Math.abs(new Date(fromDate) - new Date(a?.sortedSailings?.[0]?.startDate)) -
            Math.abs(new Date(fromDate) - new Date(b?.sortedSailings?.[0]?.startDate)) ||
          sortName(a.packageName, b.packageName)
        );
      });
      return sortedPriceType || [];
    }
    return [];
  };

  switch (sortType) {
    case 'price':
      return sortByPriceType();
    case 'duration':
      return sortByDuration();
    case 'date':
    default:
      return sortByDate();
  }
};

export default sortSailings;

import { type DeepPartial, combineReducers, configureStore } from '@reduxjs/toolkit';
import { type TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import type { TOptional } from '@/types';

import accessKeys from '@/ducks/accessKeys/accessKeys';
import appliedfilterLabels from '@/ducks/appliedfilterLabels/reducer';
import auth from '@/ducks/auth';
import common from '@/ducks/common';
import cruisesCommon from '@/ducks/cruisesCommon/slice';
import filters from '@/ducks/filters';
import filtersOptions from '@/ducks/filtersOptions';
import flyout from '@/ducks/flyout/reducers';
import agentData from '@/ducks/fm/slice';
import mnvvData from '@/ducks/mnvv/reducer';
import page from '@/ducks/page';
import chooseVoyageNew from '@/ducks/pages/chooseVoyage/chooseVoyageNew';
import { reducer as chooseVoyage } from '@/ducks/pages/chooseVoyage1.0';
import celebrations from '@/ducks/programs/celebrations/reducers';
import mgm from '@/ducks/programs/mgm/reducers';
import promoBanners from '@/ducks/promoBanners/slice';
import quickSearchItems from '@/ducks/quickSearchItems/reducer';
import travelParty from '@/ducks/travelParty/slice';
import uplift from '@/ducks/uplift/reducer';
import voyageInfo from '@/ducks/voyageInfo';
import { isProdEnv } from '@/helpers/util/env';
import accounts from '@/reducers/accounts';
import httpApiErrors from '@/reducers/httpApiErrors';
import promotions from '@/reducers/promotions';
import voyagePlanner from '@/reducers/voyagePlanner';

const rootReducer = combineReducers({
  accessKeys,
  accounts,
  agentData,
  appliedfilterLabels,
  auth, // auth flow
  celebrations,
  chooseVoyage,
  chooseVoyageNew,
  common,
  cruisesCommon,
  filters,
  filtersOptions,
  flyout,
  httpApiErrors,
  mgm,
  mnvvData,
  page,
  promoBanners,
  promotions,
  quickSearchItems,
  travelParty,
  uplift,
  voyageInfo,
  voyagePlanner,
});
export const setupStore = (preloadedState?: DeepPartial<RootState>, options: { reconfigure?: boolean } = {}) => {
  if (rootStore == null || options.reconfigure) {
    rootStore = configureStore({
      devTools: !isProdEnv() && { name: 'VV Book', trace: true, traceLimit: 25 },
      // @ts-expect-error add proper typings
      middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
          immutableCheck: {
            // TODO: MSH-97146 Remove after filters refactoring
            ignoredPaths: ['filters.packages'],
          },
        }),
      // @ts-expect-error add proper typings
      preloadedState,
      reducer: rootReducer,
    });
  }
  return rootStore;
};

export let rootStore: TOptional<AppStore> = undefined;

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof configureStore<RootState>>;
export type AppDispatch = AppStore['dispatch'];
export type AppGetState = AppStore['getState'];

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

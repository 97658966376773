import defaults from 'lodash/defaults';

import { FILTERS_APPLY, FILTERS_APPLY_SELECTION, FILTERS_RESET_KEY } from '@/constants/actionTypes';
import { selectDefaultCurrency, selectSupportedCurrencies } from '@/ducks/common/lookup/selectors';
import { selectLookup } from '@/ducks/common/selectors';
import { getCookie, setCookie } from '@/ducks/cookies';
import { populateDefaultFilterWithState } from '@/ducks/filtersOptions/helpers';
import { getFilterAgencyCurrencyCode, getFilterAgencyIsAvailable } from '@/ducks/fm/getters';
import { setAgencyCurrencyCode } from '@/ducks/fm/setters';
import { selectMnvvCurrencyCode } from '@/ducks/mnvv/selectors';
import { applyFilters } from '@/ducks/pages/chooseVoyage/actions/applyFilters';
import { getSearchParams } from '@/ducks/routes/history';
import { getTaxInclusive as getTaxInclusiveHelper } from '@/helpers/data/mappers/Common';
import { getMnvvReservation } from '@/helpers/util/misc';
import { setSessionStorageValue, setStoredValue } from '@/helpers/util/storage';
import { isVWOFeatureEnabled } from '@/helpers/vwo/isFeatureEnabled';
import { VWOFlag } from '@/types/vwo';

import { getFiltersOptions } from '../filtersOptions';
import { defaultState } from './defaultState';
import { selectFilters } from './selectors';
import { parseQuery } from './util/query';

export const ADVANCED_FILTER_KEY = {
  ACCESSIBLE: 'accessible',
  CABIN_TYPE: 'cabinType',
  DURATION: 'duration',
  PORT: 'port',
  PRICE: 'price',
  SHIP: 'ship',
  TRAVEL_PARTY: 'travelParty',
  WEEKEND: 'weekend',
};

// Selectors
export const getFilters = (state) => state.filters;
export const getCurrencyCode = (state) => getFilters(state)?.currencyCode;

// Helpers
export const setTaxInclusive = async (currencyCode) => {
  const isTaxesAndFeesFeatureEnabled = await isVWOFeatureEnabled(VWOFlag.TAXES_AND_FEES);
  setSessionStorageValue('taxInclusive', getTaxInclusiveHelper(currencyCode, isTaxesAndFeesFeatureEnabled));
};

// Actions
export const setFilters = (filtersPatch, isCurrency) => async (dispatch) => {
  const { currencyCode } = filtersPatch || {};
  const isFMLink = getFilterAgencyIsAvailable();
  if (currencyCode) {
    setStoredValue('cmsCurrencyBroadcast', currencyCode);
    if (getCookie('currencyCode') !== currencyCode) setCookie('currencyCode', currencyCode, { path: '/' });
  }
  if (isFMLink) {
    if (getCookie('agencyCurrencyCode') !== currencyCode) {
      setAgencyCurrencyCode(currencyCode);
    }
  }
  dispatch({ payload: filtersPatch, type: FILTERS_APPLY });

  if (isCurrency) {
    await dispatch(
      applyFilters({
        currencyCode,
        isCurrency,
      }),
    );
  }
};

export const setSlectionFilter = (filtersPatch) => (dispatch) => {
  dispatch({ payload: filtersPatch.selectedRegions, type: FILTERS_APPLY_SELECTION });
};

export const setResetAll = (value) => (dispatch) => {
  dispatch({ payload: { resetAll: value }, type: FILTERS_RESET_KEY });
};

export const initFilters =
  ({ useQueryForFilters = true }) =>
  async (dispatch, getState) => {
    const state = getState();

    const { agencyId, agentId, isMNVV, mnvvData } = getMnvvReservation();

    const filterOptions = getFiltersOptions(state);
    const currenciesFromLookup = selectSupportedCurrencies(state);
    const lookup = { ...selectLookup(state) };
    lookup.currenciesFromLookup = currenciesFromLookup;
    const parsedQuery = parseQuery(filterOptions, mnvvData, lookup);
    const filters = defaults(useQueryForFilters ? parsedQuery : {}, selectFilters(state));

    if (isMNVV) {
      if (!agentId) {
        filters.agentId = agentId;
      }
      if (!agencyId) {
        filters.agencyId = agencyId;
      }
    }

    const defaultCurrencyCodeFromLookup = selectDefaultCurrency(state);
    const agencyCurrencyCode = getFilterAgencyCurrencyCode();
    const mnvvCurrencyCode = isMNVV && selectMnvvCurrencyCode(state);
    const newCurrency =
      mnvvCurrencyCode ||
      agencyCurrencyCode ||
      getSearchParams()?.currencyCode ||
      getCookie('currencyCode') ||
      defaultCurrencyCodeFromLookup;

    const useNewCurrency = Boolean(newCurrency);
    if (useNewCurrency) {
      const selectedCurrency = (state?.filtersOptions?.allowedCurrencies || []).find(
        (currencyItem) => currencyItem.toLowerCase() === newCurrency?.toLowerCase(),
      );
      filters.currencyCode = selectedCurrency ? selectedCurrency.toUpperCase() : defaultCurrencyCodeFromLookup;
    }

    setTaxInclusive(filters.currencyCode);
    await dispatch(setFilters(filters));

    return newCurrency;
  };

export const resetFilters = () => (dispatch, getState) => {
  const defaultFilters = populateDefaultFilterWithState(getState())?.defaultFilters;
  defaultFilters.resetAll = true;
  dispatch(setFilters({ ...defaultFilters, durations: [] }, false));
};

export const resetFilter = (key) => (dispatch) => {
  const filterValue = defaultState[key];
  const filter = {};
  if (key === 'price') {
    filter.minPrice = null;
    filter.maxPrice = null;
  } else {
    filter[`${key}`] = filterValue;
  }

  return dispatch(setFilters(filter));
};

export const applySelectionFilter = (state, selectedRegionsIds = []) => {
  let updatePackages = false;
  let updatePorts = false;
  return {
    ...state,
    selectedRegions: state.selectedRegions.map((data) => {
      if (selectedRegionsIds.length === 0) {
        return { ...data, packages: [], ports: [] };
      }
      const selctedRgn = selectedRegionsIds.find((rgn) => rgn.regionId === data.regionId);
      if (!selctedRgn) {
        return { ...data, packages: [], ports: [] };
      }
      if (selctedRgn && selctedRgn.ports.length > 0) {
        updatePorts = true;
        return { ...data, packages: [], ports: selctedRgn.ports };
      }
      if (selctedRgn && selctedRgn.packages.length > 0) {
        updatePackages = true;
        return { ...data, packages: selctedRgn.packages, ports: [] };
      }
      if (selctedRgn && selctedRgn.ports.length <= 0 && selctedRgn.packages.length <= 0) {
        updatePackages = false;
        updatePorts = false;
        return { ...selctedRgn };
      }
      return { ...data, packages: updatePorts ? [] : data.packages, ports: updatePackages ? [] : data.ports };
    }),
    selectedRegionsIds: selectedRegionsIds.filter((region) => region.packages.length > 0 || region.ports.length > 0),
  };
};

// Reducers
// TOBE removed after old choose voyage code eliminated
export default function filtersReducer(state = defaultState, action = {}) {
  switch (action.type) {
    case FILTERS_APPLY:
      return { ...state, ...action.payload };
    case FILTERS_APPLY_SELECTION:
      return applySelectionFilter(state, action.payload);
    case FILTERS_RESET_KEY:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

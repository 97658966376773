import { selectLookup } from '@/ducks/common/selectors';
import { generateCabinNumber } from '@/helpers/selectors';
import { getCountryDialCode, getSessionStorageValue, setSessionStorageValue } from '@/helpers/util';
import { getBookingSource } from '@/helpers/util/misc';
import { rootStore } from '@/store';

const GUEST_REF_COUNT_INCREMENT = 1;

const getAddress = ({ address }) => {
  if (!address) {
    return {};
  }
  const { aptNo, city, country, countryCode, lineOne, lineTwo, postalCode, stateCode, streetAddress, zipCode } =
    address;
  const addressObject = {
    city,
    countryCode: countryCode || country,
    lineOne: lineOne || streetAddress,
    lineTwo: lineTwo || aptNo,
    stateCode,
    zipCode: zipCode || postalCode,
  };
  return Object.keys(addressObject).reduce((acc, cur) => {
    const value = addressObject[cur];
    if (!value) {
      return acc;
    }

    return { ...acc, [cur]: value };
  }, {});
};

const UpdateReservation = (dashboardData, updateReservationParams = {}) => {
  const state = rootStore.getState();
  const cabinNumber =
    dashboardData?.[0]?.cabins?.[0]?.cabinNumber || updateReservationParams?.sailorParams?.cabinNumber || '';
  const cabinSide = dashboardData?.[0]?.cabins?.[0]?.cabinSide || '';
  const deck = dashboardData?.[0]?.cabins?.[0]?.deck || '';
  const reservationNumber = dashboardData?.[0].reservationNumber || '';
  const shipCode =
    dashboardData?.[0]?.shipCode || updateReservationParams?.sailorParams?.parsedParameters?.shipCode || '';
  const voyageId =
    dashboardData?.[0]?.voyageId || updateReservationParams?.sailorParams?.parsedParameters?.voyageId || '';
  const generatedCabinNumber = generateCabinNumber(deck, cabinNumber, cabinSide);
  const accessible = dashboardData?.[0]?.cabins?.[0]?.accessible || false;
  const primaryPaidVia = dashboardData?.[0]?.sailorDetails?.[0]?.paidVia;

  const booknow = {};
  booknow.bookingNumber = reservationNumber;
  booknow.voyageId = voyageId;
  booknow.shipCode = shipCode;
  const cabinType =
    dashboardData?.[0].cabins?.[0].cabinCategoryCode ||
    updateReservationParams?.sailorParams?.parsedParameters?.cabinType;
  const accessKeys =
    dashboardData?.[0]?.accessKeys || updateReservationParams?.sailorParams?.parsedParameters?.accessKeys || [];
  const promoCode =
    dashboardData?.[0]?.promoCode || updateReservationParams?.sailorParams?.parsedParameters?.promoCode || '';
  const guestCount = parseInt(dashboardData?.[0]?.sailorCount) || parseInt(dashboardData?.[0]?.guestCount);

  const primarySailor = getSessionStorageValue('primarySailor');
  const additionalSailors = getSessionStorageValue('additionalSailors') ?? [];
  const sailorDetails = [primarySailor, ...additionalSailors];
  const sailors = [];
  for (let i = sailorDetails.length; i < guestCount; i += 1) {
    sailorDetails.push(additionalSailors.find((sailor) => sailor.guestId === i + 1 || sailor.sailorNumber === i));
  }

  const countryDialCode = primarySailor?.mobileCountryCode || primarySailor?.phoneCountryCode;

  const generateSailorObject = (sailor, address) => {
    const sailorObject = {
      address,
      citizenShip: sailor?.citizenShip || undefined,
      dateOfBirth: sailor?.dateOfBirth || sailor?.dob || undefined,
      email: sailor?.email || undefined,
      gender: sailor?.gender || undefined,
      givenName: sailor?.firstname || sailor?.firstName || undefined,
      guestId: sailor?.id || undefined,
      guestRefNumber: String(sailor?.guestRefNumber) || '',
      hasOptedForContactForFlights:
        sailor?.hasOptedForContactForFlights || updateReservationParams?.sailorParams?.add_flights || false,
      hasOptedForTripInsurance: primarySailor?.insurance || false,
      lastName: sailor?.lastname || sailor?.lastName || undefined,
      paidVia: sailor?.paidVia || primaryPaidVia || undefined,
    };
    if (String(sailor?.guestRefNumber) === '1') {
      sailorObject.contactNumber = primarySailor?.contactnumber;
      sailorObject.mobileCountryCode = getCountryDialCode(selectLookup(state), countryDialCode);
    }
    return sailorObject;
  };

  const getMappedSailor = (sailor) => {
    const address = getAddress({ address: sailor });
    return generateSailorObject(sailor, address);
  };

  sailorDetails.forEach((sailor, index) => {
    const guestRefNo = index + GUEST_REF_COUNT_INCREMENT;
    const sailorObject = {
      guestRefNumber: sailor?.guestRefNumber || guestRefNo,
      ...sailor,
    };
    const address = getAddress(sailorObject);
    sailors.push(getMappedSailor(sailorObject, address));
  });
  const cabins = [];
  if (cabinType) {
    cabins.push({
      accessKeys,
      accessible,
      cabinCategoryCode: cabinType,
      cabinNumber: generatedCabinNumber,
      guestCount: guestCount || 1,
      promoCode: promoCode || '',
      sailors,
    });
  }
  booknow.cabins = cabins;
  setSessionStorageValue('bookNowParams', booknow);
  // currencyCode
  return {
    ...booknow,
    ...getBookingSource(dashboardData?.[0]?.paymentDetails?.[0]?.currencyCode || 'USD', false, false),
  };
};

export default UpdateReservation;

import { selectSailingData } from '@/ducks/pages/summary/selectors';

export const selectCelebrations = (state) => state?.celebrations;
export const selectCelebrationsParams = (state) => selectCelebrations(state)?.params;
export const selectCelebrationsProducts = (state) => selectCelebrations(state)?.products;
export const selectCelebrationsSailings = (state) => selectCelebrationsProducts(state)?.sailings;
export const selectCelebrationsPackages = (state) => selectCelebrationsProducts(state)?.packages;
export const selectCelebrationsIsFlowActive = (state) =>
  selectCelebrationsParams(state)?.newReferral === false && Boolean(selectCelebrationsSailings(state)?.length);

export const selectIsNeedFetchCelebrationSailing = (state) => {
  const isCelebrationActive = selectCelebrationsIsFlowActive(state);

  const celebrationSailingVoyageCode = selectCelebrationsSailings(state)?.[0]?.id;
  const sailingDataVoyageCode = selectSailingData(state)?.id;

  const isBothExists = celebrationSailingVoyageCode && sailingDataVoyageCode;
  const isNeeded = isBothExists && celebrationSailingVoyageCode !== sailingDataVoyageCode;
  return isCelebrationActive ? isNeeded : false;
};

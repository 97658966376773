import { resetFilter, setFilters, setSlectionFilter } from '@/ducks/filters';
import * as setters from '@/ducks/filters/setters';
import { buildQuery } from '@/ducks/filters/util/query';
import { selectSailingData } from '@/ducks/pages/summary/selectors';
import { routes } from '@/ducks/routes';
import { replaceSearchParam } from '@/ducks/routes/history';

import { applyFilters } from './actions';
import { getFilteredPackages, getPackagesForFilteredSailings, getSailingsFromPackages } from './getters';
import tracking from './tracking';

export const setSortTypeForPackages = (sortType) => async (dispatch, getState) => {
  await dispatch(setFilters({ sortType }));
  dispatch(
    applyFilters({
      fetch: false,
    }),
  );

  tracking.trackSortChange(sortType)(getState());
};

export const setPriceTypeForPackages = (priceType) => async (dispatch) => {
  await dispatch(setFilters({ priceType }));
  await dispatch(
    applyFilters({
      fetch: false,
    }),
  );
};

export const setDurationsForPackages = (durations, weekend) => async (dispatch, getState) => {
  await dispatch(setFilters({ durations, weekend }));
  await dispatch(
    applyFilters({
      fetch: false,
    }),
  );

  tracking.trackDurations(durations, weekend)(getState());
};

export const setCabinType = (cabinType) => async (dispatch) => {
  await dispatch(setFilters({ cabinType }));
  await dispatch(applyFilters({ fetch: true }));
};

export const setDeparturePortsForPackages = (homePorts) => async (dispatch, getState) => {
  await dispatch(setFilters({ homePorts }));
  await dispatch(
    applyFilters({
      fetch: false,
    }),
  );

  tracking.trackDeparturePorts(homePorts)(getState());
};

export const setPriceRangeForPackages =
  (priceRange, shouldApplyFilters = true) =>
  async (dispatch) => {
    const { maxPrice, minPrice } = priceRange;
    await dispatch(setFilters({ maxPrice, minPrice }));
    if (shouldApplyFilters) {
      await dispatch(
        applyFilters({
          fetch: false,
        }),
      );
    }
  };

export const setSelectedShipsForPackages = (shipList) => async (dispatch, getState) => {
  await dispatch(setFilters({ shipList }));
  await dispatch(
    applyFilters({
      fetch: false,
    }),
  );

  tracking.trackShips(shipList)(getState());
};

export const setSelectedRegionIdsForPackages = (selectedRegionIds) => {
  setSlectionFilter({ selectedRegions: selectedRegionIds });
};

export const setPackgesInFilterForPackages = (packages) => async (dispatch) => {
  await dispatch(setFilters({ packages }));
  dispatch(
    applyFilters({
      fetch: false,
    }),
  );
};

export const setPortsInFilterForPackages = (ports) => async (dispatch) => {
  await dispatch(setFilters({ ports }));
  dispatch(
    applyFilters({
      fetch: false,
    }),
  );
};

export const setRegionForPackages =
  (regionId, { packages, ports }) =>
  (dispatch) => {
    dispatch(setters.setRegionItineraries(regionId, { packages, ports }));
    dispatch(applyFilters({ fetch: false }));
  };

// Apply classification nodes filter
export const setClassificationForPackages =
  (id, { packages: nodes }) =>
  async (dispatch) => {
    await dispatch(setters.setClassificationItineraries(id, { packages: nodes }));
    dispatch(
      applyFilters({
        fetch: false,
      }),
    );
  };

export const resetFilterKeyForPackages =
  (value, shLoad = false) =>
  (dispatch) => {
    dispatch(resetFilter(value, true));
    dispatch(
      applyFilters({
        fetch: shLoad,
      }),
    );
  };

export const setSailorsForPackages =
  (sailors, fetch = true) =>
  async (dispatch, getState) => {
    await dispatch(setFilters({ sailors }));
    await dispatch(
      applyFilters({
        fetch,
      }),
    );

    tracking.trackSailors(sailors)(getState());
  };

export const setSailorsCount = (sailors) => async (dispatch, getState) => {
  await dispatch(setFilters({ sailors }));
  const voyageId = selectSailingData(getState())?.voyageId;
  routes.search(buildQuery(getState(), { voyageId }));
};

export const setAccessible = (accessible) => (dispatch) => {
  dispatch(setters.setAccessible(accessible));
  dispatch(
    applyFilters({
      fetch: false,
    }),
  );
};

export const setAccessibleCabinForPackages = (accessible) => (dispatch) => {
  dispatch(setters.setAccessible(accessible));
  replaceSearchParam('isCabinAccessible', accessible, true);
};

export const isAccessibleCabinAvailable = (state) => {
  const packages = getFilteredPackages(state);
  const sailings = getSailingsFromPackages(packages);
  const newSailings = sailings.some((sailing) => sailing.isAccessible);
  return getPackagesForFilteredSailings(newSailings, packages);
};

import helpers from './helpers';

const { isNotEmptyFields, isRequiredFieldsExist } = helpers;

const required = ['voyageId', 'searchQualifier'];

const isValidSearch = ({ args }) => {
  const withoutEmpty = isNotEmptyFields({ args });
  const withRequired = isRequiredFieldsExist({ args, required });
  return withoutEmpty && withRequired;
};

export default isValidSearch;

/*
 * apply packages, ports and classifications filters
 */
import find from 'lodash/find';
import kebabCase from 'lodash/kebabCase';
import map from 'lodash/map';

import * as setters from '@/ducks/filters/setters';
import { getClassificationById, getRegionById } from '@/ducks/filtersOptions';
import { applyFilters } from '@/ducks/pages/chooseVoyage/actions';

const FILTERS_RESET_KEY = 'FILTERS_RESET_KEY';

const getItineraryTrackingLabel = ({ itineraries, regionId, regionName, selected, type = 'itinerary' }) => {
  if (!selected.length) {
    return null;
  }
  const labels = map(selected, (id) => {
    let itinerary = { name: '' };
    if (id) {
      itinerary = find(itineraries, { id });
    }
    return kebabCase(itinerary.name);
  }).join('_');

  const id = kebabCase(regionId || regionName);
  const label = type === 'itinerary' && itineraries.length === selected.length ? `all_${id}` : labels;

  return `${type}|${id}|${label}`;
};

// Apply region packages and ports filter
export const setRegionItineraries =
  (regionId, { packages, ports }, isFromAdvFilter = false) =>
  async (dispatch, getState) => {
    const region = getRegionById(getState(), regionId);
    dispatch(setters.setRegionItineraries(regionId, { packages, ports }));
    let applyFilterData = {
      fetch: false,
    };
    if (!isFromAdvFilter) {
      const trackLabel = packages
        ? getItineraryTrackingLabel({
            itineraries: region.packages,
            regionName: region.name,
            selected: packages,
          })
        : getItineraryTrackingLabel({
            itineraries: region.ports,
            regionName: region.name,
            selected: ports,
            type: 'ports',
          });
      applyFilterData = {
        ...applyFilterData,
        trackLabel,
      };
    }

    dispatch(applyFilters(applyFilterData));
  };

// Apply classification nodes filter
export const setClassificationItineraries =
  (id, { packages: nodes }) =>
  async (dispatch, getState) => {
    const state = getState();
    const classification = getClassificationById(state, id);
    dispatch(setters.setClassificationItineraries(id, { packages: nodes }));
    dispatch(
      applyFilters({
        fetch: false,
        trackLabel: getItineraryTrackingLabel({
          itineraries: classification.nodes,
          regionId: classification.id,
          selected: nodes,
        }),
      }),
    );
  };

export const setResetKeyAction = (value) => (dispatch) => {
  dispatch({ payload: { resetAll: value }, type: FILTERS_RESET_KEY });
};

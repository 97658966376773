import { createSelector } from '@reduxjs/toolkit';

import type { TSailingData } from '@/infra/types/voyageInfo/sailing';

import { selectCruisesCommonPackagesDataPackages } from '@/ducks/cruisesCommon/selectors';
import { getPackagesForFilteredSailings } from '@/ducks/pages/chooseVoyage';
import {
  isMultipleVoyageFilterActive,
  selectExtendedPackages,
  selectPackages,
} from '@/ducks/pages/chooseVoyage/selectorDetails';
import { selectSailingData } from '@/ducks/pages/summary/selectors';
import { getSearchParams } from '@/ducks/routes/history';

type TPackageType = {
  packageCode: string;
  packageName: string;
};

export const selectSelectedPackage = createSelector(
  [
    selectCruisesCommonPackagesDataPackages,
    selectSailingData,
    (_, packageCode?: TSailingData['packageCode']) => packageCode,
  ],
  (packages, sailingData, packageCode) => {
    const selectedPackageCode = packageCode ?? sailingData?.packageCode;

    return packages.find((p) => p.packageCode === selectedPackageCode) || {};
  },
);

export const selectSelectedPackageFromMainPackages = createSelector(
  [
    selectPackages,
    selectExtendedPackages,
    selectSailingData,
    isMultipleVoyageFilterActive,
    (_, packageCode?: TSailingData['packageCode']) => packageCode,
  ],
  (packages, filteredPackages, sailingData, isMultipleVoyageFilterActive, packageCode) => {
    const selectedPackageCode = packageCode ?? sailingData?.packageCode;
    const currentPackage = packages?.find((p: TPackageType) => p.packageCode === selectedPackageCode) || {};
    const selectedPackage =
      filteredPackages?.find((pack: TPackageType) => pack.packageName === currentPackage?.packageName) || {};
    if (isMultipleVoyageFilterActive && selectedPackage) {
      const { voyageId } = getSearchParams();
      const filteredSailings = selectedPackage?.sailingList?.filter(
        (sailing: TSailingData) => voyageId.indexOf(sailing.id) > -1,
      );
      return getPackagesForFilteredSailings(filteredSailings, packages)?.[0];
    }
    return selectedPackage;
  },
);

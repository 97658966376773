// Remove this default state , its reducers and actions after old choose voyage code is deleted
export const defaultState = {
  accessible: false,
  agencyId: null,
  agentId: null,
  cabinType: null,
  cabins: 1,
  chooseCabinSort: 'recommended',
  currencyCode: 'USD',
  durations: [],
  fromDate: undefined,
  homePorts: [],
  maxPrice: null,
  minPrice: null,
  nodes: [],
  packages: [],
  ports: [],
  priceType: 'perCabin',
  promotionalVoyages: [],
  resetAll: false,
  sailors: 2,
  selectedRegions: [
    {
      name: 'Caribbean',
      packages: [],
      ports: [],
      regionId: 'CARIBBEAN',
    },
    {
      name: 'EUROPE',
      packages: [],
      ports: [],
      regionId: 'EUROPE',
    },
    {
      name: 'South Pacific',
      packages: [],
      ports: [],
      regionId: 'SOUTH PACIFIC',
    },
    {
      name: 'Transatlantic and More',
      packages: [],
      ports: [],
      regionId: 'TRANSATLANTIC',
    },
  ],
  selectedRegionsIds: [],
  shipList: [],
  sortType: 'date',
  toDate: undefined,
  weekend: false,
};

import { createSelector } from '@reduxjs/toolkit';

import type { RootState } from '@/store';

import { selectLookup } from '@/ducks/common/selectors';
import { selectMulticurrencies } from '@/ducks/filtersOptions/selectors';

export const selectLookupData = (state: RootState) => selectLookup(state);

export const selectLookupIpCountryCode = (state: RootState) => selectLookupData(state)?.ipCountryCode;

export const selectServerISOtime = (state: RootState) => selectLookupData(state)?.serverISOtime;

export const selectLookupShips = (state: RootState) => selectLookupData(state)?.ships;

export const selectLookupStates = (state: RootState) => selectLookupData(state)?.states;

export const selectSupportedCurrencies = (state: RootState) =>
  selectLookupData(state).currencies?.map((currency) => currency.code) ?? [];

export const selectDefaultCurrency = (state: RootState) => selectLookupData(state)?.defaultCurrencyCode;

export const selectDefaultPackageCodes = (state: RootState) => selectLookupData(state)?.defaultPackageCodes;

export const selectLookupPromotionData = (state: RootState) => selectLookupData(state)?.promotionBanner;

export const supportedCurrencies = (state: RootState) => selectLookupData(state)?.supportedCurrencies;

export const selectMultiCurrencyOptions = createSelector(
  supportedCurrencies,
  selectMulticurrencies,
  (currencies, multiCurrencies) => {
    return currencies.filter(({ code }) => multiCurrencies.includes(code));
  },
);

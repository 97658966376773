import { combineReducers } from 'redux';

import confirmationTodoListReducer from './confirmationTodoList';
import dashboardReducer from './dashboard/dashboard';
import profileReducer from './dashboard/profile';

export default combineReducers({
  confirmationTodoList: confirmationTodoListReducer,
  dashboard: dashboardReducer,
  profile: profileReducer,
});

import qs from 'query-string';

import { selectSettings } from '@/ducks/common/settings/selectors';
import { routes } from '@/ducks/routes';
import { getSearchParams, replaceSearchParam } from '@/ducks/routes/history';
import { fetchAccessKeyDetailsData, validateAccessKey } from '@/helpers/api/app';
import tagmanager from '@/tagmanager';

import {
  ACCESS_KEY_CLEAR_ACTIVE,
  ACCESS_KEY_DETAILS_ERROR,
  ACCESS_KEY_DETAILS_SUCCESS,
  ACCESS_KEY_INITED,
  ACCESS_KEY_VALIDATE_ERROR,
  ACCESS_KEY_VALIDATE_SUCCESS,
  getAccessKey,
} from './accessKeys';
import { mapAccessKeysErrorCodes } from './errorCodes';

export const fetchAccessKeyDetailsAction = (accessKey) => async (dispatch) => {
  try {
    const data = await fetchAccessKeyDetailsData(accessKey);
    dispatch({ payload: data, type: ACCESS_KEY_DETAILS_SUCCESS });
  } catch (err) {
    dispatch({ payload: err, type: ACCESS_KEY_DETAILS_ERROR });
  }
};

export const setAccessKeyErrorAction = (statusCode) => (dispatch) => {
  dispatch({
    payload: mapAccessKeysErrorCodes(statusCode).defaultMessage,
    type: ACCESS_KEY_VALIDATE_ERROR,
  });
};

export const validateAccessKeyAction =
  (accessKey, isAccessKeyUpdate = false) =>
  async (dispatch, getState) => {
    const state = getState();
    const { accessKeyEnabled } = selectSettings(state);
    if (!accessKeyEnabled || !accessKey) {
      console.warn('access key feature not enabled');
      return {};
    }

    try {
      dispatch({ payload: accessKey, type: ACCESS_KEY_INITED });

      const data = await validateAccessKey(accessKey);
      dispatch({ payload: data, type: ACCESS_KEY_VALIDATE_SUCCESS });

      await dispatch(fetchAccessKeyDetailsAction(data.campaignKey));
      if (isAccessKeyUpdate) replaceSearchParam('accessKey', data?.promoCode, true);
      return data;
    } catch (err) {
      dispatch(setAccessKeyErrorAction(err?.response ? err.response.status : null));
      return { error: 'invalid access key' };
    }
  };

export const checkAccessKeyAction = () => async (dispatch) => {
  const queryString = getSearchParams();
  const { accessKey } = queryString;
  if (!accessKey) {
    return {};
  }

  const data = await dispatch(validateAccessKeyAction(accessKey));
  return data;
};

export const checkURLAccessKeyAction = () => async (dispatch) => {
  const queryString = qs.parse(window.location.search);
  const { accessKey } = queryString;
  if (!accessKey) {
    return {};
  }

  const data = await dispatch(validateAccessKeyAction(accessKey));
  return data;
};

export const removeActiveAccessKeyAction =
  (removeFromSearchParams = true) =>
  (dispatch) => {
    dispatch({ type: ACCESS_KEY_CLEAR_ACTIVE });
    if (removeFromSearchParams) routes.searchWithout(['accessKey']);
  };

export const removeActiveAccessKeyErrorAction = () => (dispatch) => {
  dispatch({
    payload: null,
    type: ACCESS_KEY_DETAILS_ERROR,
  });
};
export const checkAutoAppliedAccessKeyAction = () => (dispatch, getState) => {
  const isAccessKeyApplied = getAccessKey(getState()).accessKey;
  if (isAccessKeyApplied) {
    tagmanager.trackData({
      access_key_auto_applied: 'true',
    });
  }
  return isAccessKeyApplied;
};

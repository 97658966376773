export const getTaxInclusive = (currencyCode, defaultValue = false) => {
  // eslint-disable-line
  switch (currencyCode) {
    case 'GBP':
      return true;
    case 'AUD':
      return true;
    case 'NZD':
      return true;
    default:
      return defaultValue;
  }
};

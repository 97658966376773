import find from 'lodash/find';

export const getFiltersOptions = (state) => state?.filtersOptions;
export const getDefaultFilters = (state) => state.filtersOptions.defaultFilters;
export const getSelectedFilters = (state) => state.filters.selectedRegions;
export const getMaxSailors = (state) => state.filtersOptions.maxCabinOccupancy * state.filtersOptions.maxCabinCount;

export const selectMulticurrencies = (state) => state.filtersOptions?.multiCurrencies;

export const selectDefaultFilterPriceType = (state) => getDefaultFilters(state)?.priceType;

export const getRegions = (state) => state.filtersOptions.regions;
export const getRegionById = (state, id) => find(getRegions(state), { id });
export const getRegionByPkgCode = (state, pkgCode) =>
  find(getRegions(state), (rgn) => !!find(rgn.packages, { id: pkgCode }));
export const getRegionName = (state, id) => {
  const region = getRegionById(state, id) || getRegionByPkgCode(state, id);
  return region ? region.name : '';
};

export const getPackages = (state) => state.filtersOptions.packages;
export const getPackageById = (state, id) => getPackages(state)[id];

export const getClassifications = (state) => state.filtersOptions.classifications;
export const getClassificationById = (state, id) => find(getClassifications(state), { id });

export const getClassificationByNodes = (state, id) =>
  find(getClassifications(state), (classification) => find(classification.nodes, { id }));
export const getClassificationNodesByName = (state, id) => find(getClassificationByNodes(state, id).nodes, { id }).name;

export const getPorts = (state, id) => find(getRegions(state), (rgn) => find(rgn.ports, { id }));
export const getPortsByName = (state, id) => find(getPorts(state, id).ports, { id }).name;

export const getCabinTypeName = (state, code) => find(getFiltersOptions(state).cabinTypes, { code })?.name;

/**
 * Get day numbers list from durations list
 * Example: getDurationDays([1-4, 5, 6-8, 9-12, 13]) ==> [1, 2, 3, 5, 7];
 * @param durations
 * @returns {*}
 */
export const getDurationDays = (durations) =>
  durations.reduce((acc, duration) => {
    for (let i = duration.min; i < (duration.max || duration.min) + 1; i += 1) {
      acc.push(i);
    }
    return acc;
  }, []);
